.root {
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 320px;
  overflow: hidden;
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding-top: 65px;
  &.close {
    width: 80px;
  }
}
.drawer {
  padding-top: 65px;
}
.list {
  position: relative;
  overflow-x: auto;
  height: calc(100vh - 65px);
  @media only screen and (max-width: 768px) {
    height: calc(100vh - 65px);
  }
  @media only screen and (max-width: 600px) {
    height: calc(100vh - 122px);
  }
  @media only screen and (min-width: 768px) {
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: rgba(99, 115, 129, 0.48);
        opacity: 0;
      }
    }
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      display: block;
      background-color: transparent;
      border-radius: 7px;
      opacity: 0;
      transition: background-color 1000ms linear;
      &::before {
        content: '';
        position: absolute;
        top: 2px;
        bottom: 2px;
        left: 2px;
        right: 2px;
        background-color: rgba(3, 39, 71, 0.48);
      }
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(99, 115, 129, 0.68);
    }
    .item {
      margin-right: 0px;
    }
  }
}
.item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid var(--border-color);
  padding: 12px 16px;
  user-select: none;
  overflow: hidden;
  cursor: pointer;
  &.mobile {
    padding: 16px 24px;
  }
  &.active {
    background-color: var(--color-background-alt);
  }
  &:hover {
    background-color: var(--color-background-alt);
  }
}
.itemActive {
  &::before {
    content: '.';
    background: var(--color-primary);
    color: var(--color-primary);
    position: absolute;
    text-align: center;
    width: 6px;
    left: 0px;
    height: 100%;
    top: 0px;
  }
}
.avatar {
  flex-shrink: 0;
  min-width: auto;
  margin-right: 16px;
  :global {
    .MuiAvatar-root {
      width: 48px;
      height: 48px;
    }
  }
}
.content {
  flex: 1 1 auto;
  min-width: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  .name {
    display: block;
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-normal);
    line-height: 1.57143;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.active {
      color: var(--color-primary-darker);
      font-weight: var(--font-weight-bold);
    }
  }
  .msg {
    color: var(--color-text-alt-2);
    line-height: 1.57143;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.active {
      color: var(--color-text-darker);
      font-weight: var(--font-weight-semibold);
    }
    .sufix {
      font-weight: var(--font-weight-semibold);
      color: var(--color-primary-light);
    }
  }
}
.extra {
  margin-left: 16px;
  height: 44px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  .date {
    font-size: var(--font-size-7);
    font-weight: var(--font-weight-normal);
    color: var(--color-text-helper);
    margin-bottom: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .state {
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    height: 10px;
    width: 10px;
    background-color: var(--color-primary);
  }
}
.toolbar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  min-height: 64px;
  background-color: var(--color-background-base);
  border-bottom: 1px solid var(--border-color);
  box-shadow: var(--shadow-elevation-default);
  .wrap {
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 64px;
    padding: 0 16px;
    :global(.MuiIconButton-root) {
      padding: 8px;
    }
  }
  .title {
    flex: 1 1 auto;
    font-size: var(--font-size-2);
  }
  .toolsPop {
    background-color: var(--color-background-base);
    border-top: 1px solid var(--border-color);
  }
}
.backdrop {
  inset: 0px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
  opacity: 1;
  // transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.toolsHint {
  color: var(--color-text-alt-2);
  font-size: var(--font-size-5);
}
.empty {
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-normal);
  color: var(--color-text-alt-2);
  line-height: 1.57143;
  padding: 24px 16px;
}
