.root {
  background: var(--color-background-alt-1);
  border-top: 1px solid var(--border-color-2);
  border-bottom: 1px solid var(--border-color-2);
  margin: 0 0 0;
}
.metric {
  color: #827ffb;
}
.accordion::before {
  height: 0px !important;
}
.item {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  border-top: 1px solid var(--border-color-2);
  &.active {
    .label {
      color: var(--color-primary);
      font-weight: var(--font-weight-bold);
    }
    .total {
      color: var(--color-primary);
      font-weight: var(--font-weight-bold);
    }
  }
  &.active::before {
    content: '▶︎';
    color: var(--color-main);
    margin-right: 4px;
  }
  .total {
    color: var(--color-text-alt-2);
  }
}

.item2 {
  padding: 16px 24px;
  border-top: 1px solid var(--border-color-2);
}
.pointsBox {
  background: var(--color-background-alt);
  border-radius: var(--border-radius-medium);
  padding: 10px 10px;
}
