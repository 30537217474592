.root {
  --my: 24px;
  @media only screen and (max-width: 768px) {
    --my: 16px;
  }
}
.header {
  display: flex;
  align-items: center;
  padding: 16px var(--my);
  .headerContent {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
  }
  .headerTitle {
    display: inline-flex;
    font-size: var(--font-size-4);
    font-weight: var(--font-weight-semibold);
    line-height: 1.334;
  }
}
.divider {
  margin: 0px;
  flex-shrink: 0;
  background: rgba(34, 51, 84, 0.1);
  border: 0px;
  height: 1px;
}
.content {
  padding: 16px var(--my);
}
.footer {
  display: flex;
  justify-content: flex-end;
  padding: 16px var(--my);
  background-color: var(--color-background-alt);
  border-top: 1px solid rgba(135, 153, 160, 0.25);
}
