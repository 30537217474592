.header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-background-alt);
  border-bottom: 1px solid rgba(135, 153, 160, 0.25);
  padding: 16px 24px;
  @media only screen and (max-width: 768px) {
    justify-content: space-around;
    gap: 8px;
  }
}
.state {
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: var(--border-radius-small);
  &.CREADO {
    color: #1d39c4;
    background: #f0f5ff;
    border-color: #adc6ff;
  }
  &.FINALIZADO {
    color: #d4380d;
    background: #fff2e8;
    border-color: #ffbb96;
  }
  &.SELECCIONADO {
    color: #fff;
    background: #06c867;
    border-color: #00ab55;
  }
}
.content {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 16px 24px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    flex-wrap: wrap;
    .divider {
      width: auto;
      border-top: 1px solid rgba(145, 158, 171, 0.24);
      flex-grow: 1;
    }
  }
}
.left {
  width: 50%;
  max-width: 240px;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
}
.right {
  position: relative;
  width: 100%;
}
.group {
  margin-bottom: 40px;
}
.divider {
  flex-shrink: 0;
  border-width: 0px thin 0px 0px;
  border-style: solid;
  border-color: rgba(145, 158, 171, 0.24);
  height: auto;
  align-self: stretch;
}
.detail {
  margin-bottom: 4px;
  font-weight: var(--font-weight-normal);
  .detailTitle {
    font-weight: var(--font-weight-thin);
    color: var(--color-text-alt-2);
  }
}
.badg {
  position: absolute;
  right: 0;
  bottom: 0;
  @media only screen and (max-width: 768px) {
    position: initial;
  }
}
.travelItem {
  position: relative;
  display: flex;
  align-items: center;
  .icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    width: 32px;
    height: 32px;
    font-size: 24px;
    border-radius: 50%;
    svg {
      font-size: inherit;
      color: var(--color-dark-gray);
      &.rotate {
        transform: rotate(90deg) !important;
      }
    }
  }
  .info {
    flex-grow: 1;
    margin-left: 16px;
    p {
      color: var(--color-text-helper);
      font-weight: var(--font-weight-normal);
      font-size: var(--font-size-7);
    }
    h6 {
      max-width: 190px;
      font-weight: var(--font-weight-semibold);
      line-height: 18px;
    }
  }
  &:not(:last-child) {
    margin-bottom: 20px;
    .icon {
      &:after {
        content: '';
        position: absolute;
        top: 100%;
        height: 100%;
        width: 1px;
        border-left: 2px dashed var(--color-background-alt-2);
        z-index: -1;
      }
    }
  }
}
.badge {
  display: flex;
  align-items: center;
  background-color: var(--color-background-alt);
  border-radius: var(--border-radius-small);
  padding: 5px 10px;
  .icon {
    flex-shrink: 0;
    width: 22px;
    height: 22px;
    color: var(--color-dark-gray);
    svg {
      width: inherit;
      height: inherit;
    }
  }
  .body {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 10px;
    p {
      color: var(--color-text-alt-2);
      font-weight: var(--font-weight-normal);
      padding-right: 24px;
    }
    h6 {
      font-weight: var(--font-weight-semibold);
    }
  }
  &.boxBlue {
    background-color: rgba(80, 94, 253, 0.1);
    .icon {
      color: var(--color-blue);
    }
  }
  &.boxOrange {
    background-color: rgba(243, 156, 18, 0.1);
    .icon {
      color: var(--color-orange);
    }
  }
  &.boxGreen {
    background-color: rgba(71, 194, 122, 0.1);
    .icon {
      color: var(--color-green);
    }
  }
}
