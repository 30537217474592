.content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 64px);
}
.list {
  padding: 16px 0;
}
.space {
  flex-grow: 1;
}
.bottom {
  padding: 24px 16px;
}
