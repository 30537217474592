.root {
  display: flex;
  align-items: center;
  height: 22px;
  color: #eaeaf0;
}
.active {
  color: var(--color-primary);
}
.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: currentColor;
}
.completed {
  font-size: 22px;
  color: var(--color-primary);
  z-index: 1;
}
