.user-address {
  .add-address-card {
    display: flex;
    flex-direction: column;
    height: 176px;
    color: var(--color-gray);
    box-sizing: border-box;
    border: 2px dashed var(--border-color);
    border-radius: var(--border-radius-medium);
    transition: 0.2s ease;
    .MuiButton-label {
      flex-direction: inherit;
    }
    svg {
      width: 28px;
      height: 28px;
    }
    &:hover {
      border-color: var(--color-gray);
      color: var(--color-text-alt-2);
    }
  }
  .address-card {
    height: 176px;
    &__header {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      border-bottom: 1px solid rgba(135, 153, 160, 0.251);
      color: var(--color-text-alt-2);
      font-weight: var(--font-weight-bold);
      .guvery-logo {
        margin-left: 4px;
        margin-bottom: -4px;
        height: 18px;
        width: 62px;
        background-size: contain !important;
      }
    }
    &__content {
      display: flex;
      align-items: flex-start;
      padding: 10px 20px;
      &:first-child {
        padding: 20px;
      }
      .content-icon {
        margin-top: 4px;
      }
      .content-body {
        flex-grow: 1;
        padding-left: 8px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .add-address-card,
    .address-card {
      height: 144px;
    }
  }
}

.input-label {
  display: block;
  color: var(--color-text-alt-2);
  font-weight: var(--font-weight-semibold);
  margin-bottom: 8px;
}
