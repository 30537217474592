.root {
  position: absolute;
  left: 0;
  bottom: 0;
  // bottom: 38px;
  display: flex;
  align-items: center;
  width: 100%;
  // padding-left: 16px;
  min-height: 56px;
  background: #f9fafb;
  border-top: 1px solid var(--border-color-2);
}
.content {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 16px;
}
.action {
  margin: 0 8px;
}
.divider {
  flex-shrink: 0;
  height: auto;
  align-self: stretch;
  border-width: 0px thin 0px 0px;
  border-style: solid;
  border-color: var(--border-color-2);
  margin: 0;
}
.emojiPicker {
  position: relative;
  .picker {
    position: absolute;
    bottom: 40px;
    left: -16px;
    background: var(--color-background-base);
    border-radius: 16px;
    box-shadow: rgb(145 158 171 / 24%) 0px 0px 2px 0px,
      rgb(145 158 171 / 24%) 0px 20px 40px -4px;
    z-index: 99;
  }
  :global {
    .emoji-mart-category-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}
