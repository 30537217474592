.root {
    padding: 10px;
}
.wrap {
  & > :not(style) + :not(style) {
    margin-top: 10px;
  }
  p {
    font-size: var(--font-size-5);
    line-height: 1.45;
  }
  ul {
    opacity: 0.7;
    padding-left: 24px;
  }
  ul > li {
    line-height: 1.2;
    margin-top: 4px;
  }
  .benefitsMore {
    li  {
      list-style-type: "✅";
      padding-inline-start: 6px;
    }
  }
  .benefitsLess {
    li  {
      list-style-type: "⏱️";
      padding-inline-start: 6px;
    }
  }
}

  