.guvery-nav-item {
  display: flex !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  &__link {
    position: relative;
    justify-content: flex-start !important;
    height: 42px !important;
    width: 100%;
    padding: 10px 18px !important;
    color: var(--color-text-base) !important;
    font-weight: 400 !important;
    text-transform: none !important;
    border-radius: 0 !important;
    svg {
      color: #627884;
      width: 20px;
      height: 20px;
      margin-right: 14px;
    }
    &.link-active {
      font-weight: 800 !important;
      background-color: var(--color-background-base);
      // background-color: rgba(60, 219, 193, 0.1);
      // border-right: 1px solid #d7a244;
      svg {
        color: var(--color-text-base);
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 3px;
        border-radius: var(--border-radius-medium);
        // background-color: var(--color-main);
      }
      &:hover {
        text-decoration: none;
        background-color: var(--color-background-base);
      }
    }
    .dot {
      display: block;
      position: absolute;
      top: 18px;
      right: 16px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: rgb(255, 72, 66);
      box-shadow: 0 0 0 rgba(255, 72, 66, 0.4);
      animation: pulse-dot 2s ease-out;
      animation-iteration-count: infinite;
      animation-delay: 1.1s;
    }
    .done {
      display: block;
      position: absolute;
      top: 12px;
      right: 14px;
      width: 20px;
      height: 20px;
      svg {
        width: 100%;
        height: 100%;
        margin-right: 0;
        color: var(--color-green);
      }
    }
  }
  @-webkit-keyframes pulse-dot {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 72, 66, 0.4);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(255, 72, 66, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 72, 66, 0);
    }
  }
  @keyframes pulse-dot {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 72, 66, 0.4);
      box-shadow: 0 0 0 0 rgba(255, 72, 66, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(255, 72, 66, 0);
      box-shadow: 0 0 0 10px rgba(255, 72, 66, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 72, 66, 0);
      box-shadow: 0 0 0 0 rgba(255, 72, 66, 0);
    }
  }
}
