.user-wallet {
  .card-wallet {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    h6 {
      font-size: var(--font-size-4);
      span {
        font-weight: var(--font-weight-semibold);
        font-size: var(--font-size-2);
      }
    }
    p.balanceEvent {
      font-size: var(--font-size-7);
      margin-left: 1rem;
      color: var(--color-status-error);
    }
    p.balanceMessage {
      margin-top: 0.5rem;
      text-align: center;
    }
    p.balanceSubMessage {
      margin-top: 0.25rem;
      text-align: center;
      font-size: var(--font-size-7);
    }
    p.positive {
      color: var(--color-status-success);
    }
  }
  .card-movements {
    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      h4 {
        margin-bottom: 16px;
      }
      @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        h4 {
          margin-bottom: 0px;
        }
      }
    }
    .no-content {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
    }
  }
  .desc-wrap {
    display: flex;
    align-items: center;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      &--success {
        color: rgb(0, 171, 85);
        background-color: rgba(0, 171, 85, 0.16);
      }
      &--error {
        color: rgb(255, 72, 66);
        background-color: rgba(255, 72, 66, 0.16);
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .name {
      margin-left: 8px;
      span {
        font-weight: 700;
      }
    }
  }
  .amount-text {
    font-weight: 600;
    &--success {
      color: rgb(0, 171, 85);
    }
    &--error {
      color: rgb(255, 72, 66);
    }
    .currency {
      font-size: var(--font-size-7);
      font-weight: 400;
    }
  }
}

.dialog-content-recharge {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  min-height: 240px;
  text-align: center;
  .actions {
    margin-top: 8px;
    p {
      margin-bottom: 16px;
      font-weight: var(--font-weight-normal);
    }
    a {
      margin-top: 16px;
    }
  }
}

.dialog-content-retire {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 240px;
  .actions {
    text-align: center;
    margin-top: 24px;
    p,
    a {
      margin-bottom: 8px;
      font-weight: var(--font-weight-normal);
    }
    a {
      color: var(--color-primary);
      font-weight: var(--font-weight-semibold);
      &:hover {
        color: var(--color-primary-darker);
      }
    }
    button {
      margin-top: 16px;
    }
  }
}
