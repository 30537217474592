.root {
  display: flex;
  align-items: flex-start;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.cover {
  position: relative;
  flex-shrink: 0;
  width: 208px;
  height: 208px;
  overflow: hidden;
  border-right: 1px dashed var(--border-color-2);
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    user-select: none;
    margin: 0 auto;
  }
  &.holder {
    background-color: var(--color-background-alt);
    img {
      width: 75%;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    border-bottom: 1px dashed var(--border-color-2);
    border-right: none;
    &.holder {
      img {
        width: 50%;
      }
    }
  }
}
.body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 208px;
  height: auto;
  width: 100%;
  @media screen and (max-width: 768px) {
    height: auto;
    width: 100%;
  }
}
.info {
  position: relative;
  flex: 1 1 auto;
  min-width: 0px;
  padding: 16px 16px 0;
}
.bottom {
  padding: 0 16px 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: flex-end;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    padding: 16px 16px;
  }
}
.tools {
  position: absolute;
  top: 10px;
  right: 10px;
}
.toolsButton {
  @media screen and (max-width: 768px) {
    background-color: var(--color-light-gray);
    box-shadow: 0px 0px 4px var(--color-primary);
    &:hover {
      background-color: inherit;
      color: var(--color-primary);
    }
  }
}
.header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
}
.title {
  font-weight: var(--font-weight-bold);
  color: var(--color-text-primary);
  margin-right: 4px;
  &:hover {
    text-decoration: underline;
  }
}
.state {
  position: absolute;
  top: 12px;
  left: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 27px;
  padding: 0 10px;
  background-color: #cefaf3;
  box-shadow: inset 0px 0px 0px 1px var(--color-main);
  color: var(--color-primary);
  border-radius: var(--border-radius-small);
  .text {
    font-weight: var(--font-weight-semibold);
    text-transform: capitalize;
  }
  &.created {
    background-color: #faecca;
    box-shadow: inset 0px 0px 0px 1px var(--color-status-alert);
    color: var(--color-status-alert);
  }
  &.buyed {
    background-color: #d6ffe8;
    box-shadow: inset 0px 0px 0px 1px #39af6c;
    color: #39af6c;
  }
}
.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 27px;
  padding: 0 10px;
  margin-left: 6px;
  border-radius: var(--border-radius-small);
  .text {
    font-weight: var(--font-weight-semibold);
    text-transform: capitalize;
  }
  &.badgeOne {
    background-color: #ffbe4b;
    img {
      width: 38px;
      display: block;
      filter: brightness(0) invert(1);
      user-select: none;
    }
  }
  &.office {
    // background-color: #fffde7;
    box-shadow: inset 0px 0px 0px 1px #fdd835;
    color: #fbc02d;
    cursor: pointer;
  }
}
.product {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 8px;
  border-radius: var(--border-radius-small);
  border: 1px solid var(--border-color);
  cursor: pointer;
  .text {
    display: flex;
    align-items: center;
    font-weight: var(--font-weight-semibold);
    overflow: hidden;
    .amount {
      margin-right: 4px;
      font-weight: var(--font-weight-bold);
    }
  }
  .link {
    display: inline-block;
    max-width: 196px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      color: var(--color-main-800);
      font-weight: var(--font-weight-bold);
      text-decoration: underline;
    }
  }
}
.alert {
  display: inline-flex;
  // align-items: center;
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-semibold);
  padding: 4px 4px;
  color: var(--color-text-alt-2);
  // border: 1px dashed var(--border-color);
  // border-radius: var(--border-radius-small);
  .text {
    margin-left: 4px;
  }
}
.pricing {
  // min-width: 180px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  background-color: rgba(237, 246, 245, 0.7);
  border-radius: var(--border-radius-medium);
  cursor: pointer;
  .text {
    margin-right: 4px;
    & > :not(style) + :not(style) {
      margin: 0 0 0 4px;
    }
  }
  .matchFee {
    margin-left: 8px !important;
    padding: 2px 4px;
    background-color: #2b353b;
    border-radius: 5px;
    color: #fff;
    font-weight: var(--font-weight-normal);
    font-size: 0.9em;
    .currency {
      color: var(--color-text-alt);
      font-size: var(--font-size-6);
    }
    svg {
      font-size: 1em !important;
      margin: 0px !important;
    }
  }
  .currency {
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-5);
    color: var(--color-text-alt-2);
    margin-right: 2px;
    padding-bottom: 4px;
  }
  .priceNum {
    font-weight: var(--font-weight-bold);
  }
  .priceHint {
    display: inline-block;
    font-size: var(--font-size-7);
    font-weight: var(--font-weight-semibold);
    color: var(--color-text-alt-2);
    font-style: italic;
  }
  .btn {
    color: #fff;
    background-color: var(--color-text-alt-2) !important;
    margin-right: 8px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.agreedDate {
  min-height: 44px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 10px;
  border-radius: var(--border-radius-small);
  border: 1px solid var(--color-dark-gray);
  color: var(--color-text-alt-2);
  .textHelper {
    
    color: var(--color-graphite);
    &::first-letter {
      text-transform: capitalize;
    }
  }
  .text {
    margin-top: 2px;
    color: var(--color-graphite);
    font-weight: var(--font-weight-semibold);
  }
}
.actionAlert {
  min-height: 44px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 10px;
  border-radius: var(--border-radius-small);
  border: 1px dashed var(--border-color-2);
  color: var(--color-text-alt-2);
  .textHelper {
    font-weight: var(--font-weight-semibold);
    &::first-letter {
      text-transform: capitalize;
    }
  }
  .text {
    margin-top: 2px;
  }
}
.hintText {
  @media screen and (max-width: 768px) {
    width: 100%;
    .actionAlert {
      flex-direction: row;
      align-items: center;
      min-height: 34px;
      .text {
        margin-top: 0;
        margin-left: 4px;
      }
    }
    .agreedDate {
      flex-direction: row;
      align-items: center;
      min-height: 34px;
      .text {
        margin-top: 0;
        margin-left: 4px;
      }
    }
  }
}
.actions {
  flex-wrap: wrap;
}
.actionsWrap {
  @media screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
.rating {
  display: flex;
  align-items: center;
  color: var(--color-text-alt-2);
  padding: 6px 10px;
  border-radius: var(--border-radius-small);
  border: 1px dashed var(--color-text-alt-2);
  .score {
    display: inline-flex;
    align-items: center;
    gap: 3px;
    color: #ffa000;
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-semibold);
    margin-left: 5px;
    svg {
      width: 18px;
      height: 18px;
      fill: #f3a43d;
    }
  }
  @media screen and (max-width: 768px) {
    flex-grow: 1;
  }
}
.partner {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: var(--border-radius-small);
  border: 1px dashed var(--color-main);
  margin-bottom: 8px;
  color: var(--color-primary);
  .partnerWrap {
    padding: 6px 10px;
  }
  .partnerText {
    display: inline-block;
  }
  h6 {
    font-weight: var(--font-weight-semibold);
  }
  @media screen and (max-width: 768px) {
    // width: 100%;
    max-width: none;
    margin-bottom: 16px;
  }
}
.obs {
  display: block;
  color: var(--color-status-info);
  font-weight: var(--font-weight-semibold);
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 400px;
}
.anchorActionSuggestionText {
  font-size: var(--font-size-6);
  margin-bottom: 10px;
  text-align: center;
  color: var(--color-primary);
  text-decoration: underline;
  cursor: pointer;
}
