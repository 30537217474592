:root {
  hr {
    border-bottom: 1px solid #ccc;
    margin: 10px auto;
  }

  .addItemCard {
    display: flex;
    flex-direction: column;
    height: 270px;
    color: var(--color-gray) !important;
    box-sizing: border-box;
    border: 2px dashed var(--border-color) !important;
    border-radius: var(--border-radius-medium) !important;
    transition: 0.2s ease;
    .MuiButton-label {
      flex-direction: inherit;
    }
    svg {
      width: 28px;
      height: 28px;
    }
    &:hover {
      border-color: var(--color-gray) !important;
      color: var(--color-text-alt-2) !important;
    }
  }

  .itemCard {
    box-sizing: border-box;
    min-height: 270px;
    display: flex !important;
    flex-direction: column;
    align-items: stretch;
    & .header {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px 20px;
      border-bottom: 1px solid rgba(135, 153, 160, 0.251);
      color: #fff;
      font-weight: var(--font-weight-bold);
      background-color: var(--color-text-alt);
    }
    & .content {
      padding: 10px 20px;
      color: var(--color-text-alt-2);
      & .message {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 10px;
      }
      & .body {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        & .col {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          width: 100%;
        }
      }
      & .details {
        display: -webkit-box;
        margin-top: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      & p {
        display: flex;
        align-items: flex-start;
        gap: 6px;
        & svg {
          margin-top: 2px;
          overflow: visible;
          font-weight: bold;
          color: var(--color-blue);
        }
      }
    }
    & .actions {
      display: grid !important;
      width: auto;
      padding: 10px 20px;
      margin-top: auto;
      margin-bottom: 0px !important;
    }
  }
}
