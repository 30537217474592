.content {
  flex-grow: 1;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    background: rgba(43, 53, 59, 0.5);
    transition: color 0.2s ease 0s;
    border-radius: 7px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(43, 53, 59, 0.7);
  }
}
.subTitle {
  display: flex;
  align-items: center;
  padding: 0 16px 8px 16px;
  .text {
    font-weight: var(--font-weight-bold);
    // color: var(--color-text-alt-2);
  }
}
.badge {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  height: 20px;
  margin-left: 6px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;
  border: 1px dashed var(--color-purple);
  background-color: inherit;
  font-size: 14px;
  font-weight: var(--font-weight-semibold);
  color: var(--color-purple);
  text-transform: capitalize;
  span {
    display: inline-block;
    margin-top: -1px;
  }
}
.statusDescriptionPrimary {
  margin-top: 8px;
  color: var(--color-text-white);
  font-weight: var(--font-weight-semibold);
  border-radius: var(--border-radius-small);
  background-color: var(--color-background-primary);
  padding: 4px 8px;
  p {
    svg {
      width: 16px;
      height: 16px;
      display: inline-flex;
      vertical-align: middle;
      margin-bottom: 2px;
    }
  }
}
.statusDescriptionSecondary {
  margin-top: 8px;
  color: var(--color-text-primary);
  font-weight: var(--font-weight-semibold);
  border-radius: var(--border-radius-small);
  background-color: var(--color-background-alt);
  padding: 4px 8px;
  p {
    svg {
      width: 16px;
      height: 16px;
      display: inline-flex;
      vertical-align: middle;
      margin-bottom: 2px;
    }
  }
}
