.panel {
  width: 100%;
  margin: 24px 0 0;
}
.emptyHint {
  color: var(--color-text-helper);
}
.gridCont {
  &:global(.MuiGrid-spacing-xs-4 > .MuiGrid-item) {
    padding: 18px 16px;
  }
}
