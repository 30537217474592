.root {
  margin: 0 16px;
  padding: 24px;
  background: var(--color-background-alt);
  border: 1px solid var(--border-color-2);
  border-radius: var(--border-radius-medium);
}
.title {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  color: var(--color-text-primary);
  h4 {
    font-weight: var(--font-weight-bold);
    margin-left: 6px;
  }
}
.content {
  min-height: 180px;
  margin-bottom: 16px;
}
.input {
  margin-bottom: 24px;
}
.actions {
  text-align: center;
}
.confirm {
  &:global(.MuiButton-root) {
    width: 50%;
  }
}
