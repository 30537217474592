.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.header {
  .headerIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    margin-bottom: -4px;
    cursor: pointer;
  }
}
.container {
  flex-grow: 1;
  height: auto;
  overflow-x: auto;
  background: var(--color-background-alt);
  border-top-right-radius: var(--border-radius-small);
  border-top-left-radius: var(--border-radius-small);
  // border: 1px solid var(--border-color-2);
  // border-radius: var(--border-radius-small);
}
.stepper {
  padding: 0px 0px 24px !important;
  background-color: inherit !important;
}
.stepLabel {
  display: flex;
  :global {
    .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
      margin-top: 8px;
    }
  }
}
.body {
  flex-grow: 1;
  height: auto;
  min-height: 288px;
  padding: 24px;
}
.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 24px;
  background: var(--color-background-alt);
  & > :not(style) + :not(style) {
    margin-left: 10px;
  }
  button {
    padding: 10px 20px;
  }
}
.responsibilities,
.questions {
  margin-left: 16px;
  margin-bottom: 16px;
  ol,
  li {
    list-style: decimal;
    & > :not(style) + :not(style) {
      margin-top: 4px;
    }
  }
  p {
    font-size: 16px;
    line-height: 1.56;
  }
  & > :not(style) + :not(style) {
    margin-top: 24px;
  }
}
.form {
  .formItem {
    .formText {
      margin-bottom: 16px;
      h5 {
        font-weight: var(--font-weight-semibold);
        margin-bottom: 2px;
      }
    }
    :global {
      .MuiOutlinedInput-input {
        padding: 16px 14px;
      }
    }
  }
  .fullWidth {
    width: 100%;
  }
  .radioInput {
    position: absolute;
    height: 1px;
    width: 1px;
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    white-space: nowrap;
    overflow: hidden;
    &[type='radio']:checked + .radioTile {
      border-color: var(--color-green);
      box-shadow: 0 5px 10px rgba(#000, 0.1);
      color: var(--color-green);
      &:before {
        transform: scale(1);
        opacity: 1;
        background-color: var(--color-green);
        border-color: var(--color-green);
      }
      .radioIcon,
      .radioLabel {
        color: var(--color-green);
      }
    }
    &[type='radio']:focus + .radioTile {
      border-color: var(--color-green);
      box-shadow: 0 5px 10px rgba(#000, 0.1), 0 0 0 4px #bcf6d4;
      &:before {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
  .radioTile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 8rem;
    min-height: 3rem;
    border-radius: var(--border-radius-medium);
    border: 2px solid var(--color-text-alt);
    background-color: #fff;
    padding: 0 32px;
    /*
    box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px,
      rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;
    */
    transition: 0.15s ease;
    cursor: pointer;
    position: relative;
    @media (max-width: 768px) {
      padding: 0 16px;
    }
    &.center {
      align-items: center;
    }
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 1.25rem;
      height: 1.25rem;
      border: 2px solid #b5bfd9;
      background-color: #fff;
      border-radius: 50%;
      top: 0.25rem;
      left: 0.25rem;
      opacity: 0;
      transform: scale(0);
      transition: 0.25s ease;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
      background-size: 12px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
    &:hover {
      border-color: var(--color-green);
      &:before {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
  .radioLabel {
    width: 100%;
    color: var(--color-text-base);
    font-weight: var(--font-weight-normal);
    transition: 0.375s ease;
    text-align: center;
    overflow: hidden;
  }
  .checkboxInput {
    position: absolute;
    height: 1px;
    width: 1px;
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    white-space: nowrap;
    overflow: hidden;
    &:checked + .checkboxTile {
      border-color: var(--color-green);
      box-shadow: 0 5px 10px rgba(#000, 0.1);
      color: var(--color-green);
      &:before {
        transform: scale(1);
        opacity: 1;
        background-color: var(--color-green);
        border-color: var(--color-green);
      }
      .checkboxIcon,
      .checkboxLabel {
        color: var(--color-green);
      }
    }
    &:focus + .checkboxTile {
      border-color: var(--color-green);
      box-shadow: 0 5px 10px rgba(#000, 0.1), 0 0 0 4px #bcf6d4;
      &:before {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
  .checkboxTile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 8rem;
    min-height: 3rem;
    border-radius: var(--border-radius-medium);
    border: 2px solid var(--color-text-alt);
    background-color: #fff;
    padding: 0 32px;
    /*
    box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px,
      rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;
    */
    transition: 0.15s ease;
    cursor: pointer;
    position: relative;
    @media (max-width: 768px) {
      padding: 0 16px;
    }
    &.center {
      align-items: center;
    }
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 1.25rem;
      height: 1.25rem;
      border: 2px solid #b5bfd9;
      background-color: #fff;
      border-radius: 50%;
      top: 0.25rem;
      left: 0.25rem;
      opacity: 0;
      transform: scale(0);
      transition: 0.25s ease;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
      background-size: 12px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
    &:hover {
      border-color: var(--color-green);
      &:before {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
  .checkboxLabel {
    width: 100%;
    color: var(--color-text-base);
    font-weight: var(--font-weight-normal);
    transition: 0.375s ease;
    text-align: center;
    overflow: hidden;
  }
}
.endIcon {
  text-align: center;
  h3 {
    margin-top: 24px;
    margin-bottom: 8px;
    font-weight: var(--font-weight-bold);
  }
  p {
    color: var(--color-text-alt-2);
    font-weight: var(--font-weight-normal);
    margin-bottom: 20px;
  }
  svg {
    width: 80px;
    height: 80px;
    opacity: 0.4;
    filter: grayscale(1);
  }
}
.endButton {
  max-width: 240px;
  margin: 0 auto;
}
