:root {
  .main {
    padding: 10px 20px;
    
    hr {
      border-bottom: 1px solid var(--color-text-alt);
      margin: 20px auto;
    }
    .dealDetails {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .deliveryDate {
        min-height: 44px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 0 10px;
        border-radius: var(--border-radius-small);
        border: 1px solid var(--color-dark-gray);
        color: var(--color-text-alt-2);
        .textHelper {
          color: var(--color-graphite);
          &::first-letter {
            text-transform: capitalize;
          }
        }
        .text {
          margin-top: 2px;
          color: var(--color-graphite);
          font-weight: var(--font-weight-semibold);
        }
      }
      .user {
        flex: 1 1 auto;
        display: inline-flex;
        align-items: center;
        .avatar {
          width: 38px;
          height: 38px;
        }
        .userInfo {
          flex: 1 1 auto;
          margin-left: 8px;
        }
        .name {
          max-width: 120px;
          font-size: var(--font-size-5);
          font-weight: var(--font-weight-semibold);
        }
        .userScore {
          display: inline-flex;
          align-items: center;
          margin-top: 4px;
          color: #f3a43d;
          svg {
            width: 15px;
            height: 15px;
            fill: #f3a43d;
          }
          span {
            font-weight: var(--font-weight-semibold);
            font-size: var(--font-size-7);
            color: var(--color-text-alt-2);
            margin-left: 4px;
          }
        }
      }
    }
    .dealOrders {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      .remarks {
        margin-top: 0px;
        margin-bottom: 12px;
        display: block;
        color: var(--color-status-info);
        font-weight: var(--font-weight-semibold);
        font-size: var(--font-size-5);
      }
      .product {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        padding: 4px 8px;
        border-radius: var(--border-radius-small);
        border: 1px solid var(--border-color);
        cursor: pointer;
        .text {
          display: flex;
          align-items: center;
          font-weight: var(--font-weight-semibold);
          overflow: hidden;
          .amount {
            margin-right: 4px;
            font-weight: var(--font-weight-bold);
          }
        }
        .link {
          display: inline-block;
          max-width: 280px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:hover {
            color: var(--color-main-800);
            font-weight: var(--font-weight-bold);
            text-decoration: underline;
          }
        }
      }
    }
    .headInstructions {
      font-size: var(--font-size-5);
      color: var(--color-primary);
      margin: 10px auto;
    }
    .divInstructions {
      ul {
        margin: 10px 10px 10px 0px;
        li {
          list-style-type: "✓";
          list-style-position: outside;
          font-size: var(--font-size-6);
          margin-bottom: 6px;
          margin-left: 20px;
          padding-left: 10px;
        }
        li::marker {
          color: var(--color-status-success);
        }
        
      }
    }
    .sectionCheck {
      margin-top: 8px;
    }
    .actions {
      margin-top: 10px;
      display: grid;
      gap: 14px;
      p {
        color: var(--color-text-alt-2);
      }
    }
  }
}
