.root {
  display: flex;
  flex-direction: column;
  border-bottom: 1px dashed var(--border-color-2);
}
.top {
  display: flex;
  flex-direction: row;
  padding: 16px;
  // border-bottom: 1px dashed var(--border-color-2);
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}
.cover {
  position: relative;
  width: 192px;
  height: 192px;
  flex-shrink: 0;
  border-radius: var(--border-radius-medium);
  // border-right: 1px dashed var(--border-color-2);
  overflow: hidden;
  a {
    display: block;
    width: 100%;
    height: 100%;
    background: #efefef;
  }
  .image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    user-select: none;
  }
  .badge {
    position: absolute;
    top: 8px;
    left: 8px;
    min-height: 23px;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    .image {
      object-fit: contain;
    }
  }
}
.content {
  flex: 1 1 auto;
  min-width: 0px;
  padding: 4px 0 4px 16px;
  .contentWrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    height: 100%;
  }
  .date {
    color: var(--color-text-alt-2);
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-7);
    font-style: italic;
  }
  .name {
    margin-top: 2px;
    margin-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      color: var(--color-primary-darker);
      text-decoration: underline;
    }
  }
  .condition {
    margin-bottom: 2px;
  }
  .obs {
    font-weight: var(--font-weight-semibold);
    color: var(--color-text-primary);
    margin-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .detail {
    margin-bottom: 4px;
    color: var(--color-text-helper);
    span {
      color: var(--color-text-base);
      font-weight: var(--font-weight-semibold);
    }
  }
  .chip {
    display: block;
    .link {
      display: flex;
      align-items: center;
      padding: 2px 8px;
      background-color: rgba(34, 51, 84, 0.05);
      border-radius: var(--border-radius-small);
      svg {
        font-size: 14px;
      }
      .text {
        font-size: var(--font-size-6);
        line-height: 22.4px;
        margin-left: 4px;
      }
      &:hover {
        background-color: rgba(34, 51, 84, 0.15);
        .text,
        svg {
          color: var(--color-text-primary-darker);
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    // border-top: 1px dashed var(--border-color-2);
    border-left: none;
    padding: 16px 0;
  }
}
.shop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-medium);
  .shopTitle {
    font-weight: var(--font-weight-semibold);
    &:hover {
      color: var(--color-text-primary-darker);
      text-decoration: underline;
    }
  }
  .shopInfo {
    display: flex;
    align-items: center;
    line-height: 1;
    gap: 8px;
    .link {
      color: #fff;
      background: var(--color-primary);
      svg {
        width: 18px;
        height: 18px;
      }
    }
    .price {
      flex-grow: 1;
      h6 {
        text-align: right;
        margin-bottom: 2px;
      }
      strong {
        font-size: var(--font-size-4);
        font-weight: var(--font-weight-bold);
        color: var(--color-text-alt);
      }
      p {
        font-size: var(--font-size-7);
        color: var(--color-text-alt-2);
      }
    }
  }
  &:hover {
    background-color: var(--color-background-alt);
  }
}
.actions {
  background-color: rgba(237, 246, 245, 0.7);
  min-width: 220px;
  border-radius: var(--border-radius-medium);
  padding: 16px;
  margin: 0 16px 16px;
  .actionsWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.avatar {
  display: flex;
  align-items: center;
  :global {
    .MuiAvatar-root {
      width: 32px;
      height: 32px;
      margin-right: 6px;
    }
  }
  .name {
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-semibold);
  }
  svg {
    width: 20px;
    height: 20px;
  }
}
.userScore {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 2px;
  color: #f3a43d;
  svg {
    width: 17px;
    height: 17px;
    fill: #f3a43d;
  }
  span {
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-6);
    color: var(--color-text-alt-2);
  }
}
.commission {
  text-align: end;
  .price {
    color: var(--color-text-primary);
    font-size: var(--font-size-4);
    font-weight: var(--font-weight-bold);
    .help {
      font-size: var(--font-size-6);
      font-weight: var(--font-weight-normal);
    }
  }
  .hint {
    font-size: var(--font-size-7);
    color: var(--color-text-alt-2);
  }
}
