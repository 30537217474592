.root {
  position: relative;
  width: 272px;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 8px;
  }
}
.dates {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  font-weight: var(--font-weight-semibold);
  color: var(--color-text-alt-2);
}
.plane {
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  svg {
    size: 30px;
    color: #00594f;
    fill: #00594f;
  }
}
.hint {
  display: inline-block;
  color: var(--color-text-alt-2);
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-7);
  font-style: italic;
  margin-top: 4px;
}
