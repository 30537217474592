.root {
  display: flex;
  align-items: center;
  min-height: 64px;
  border-top: 1px solid var(--border-color);
  padding: 0 16px;
}
.sellerSumary {
  display: flex;
  align-items: center;
}
.sellerInfo {
  margin-left: 16px;
}
.name {
  font-size: var(--font-size-6);
  font-weight: var(--font-weight-semibold);
}
.sellerScore {
  display: inline-flex;
  align-items: center;
  margin-top: 2px;
  svg {
    color: #f3a43d;
    fill: #f3a43d;
  }
  span {
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-7);
    color: var(--color-text-alt-2);
    margin-left: 4px;
  }
}
