.root {
  background-color: var(--color-background-base);
  border-radius: var(--border-radius-medium);
}
.header {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
  border-bottom: 1px solid var(--border-color);
  .user {
    flex: 1 1 auto;
    display: inline-flex;
    align-items: center;
  }
  .avatar {
    width: 36px;
    height: 36px;
  }
  .userInfo {
    flex: 1 1 auto;
    margin-left: 5px;
  }
  .name {
    max-width: 120px;
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-semibold);
  }
  .userScore {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    margin-top: 2px;
    color: #f3a43d;
    svg {
      width: 17px;
      height: 17px;
      fill: #f3a43d;
    }
    span {
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-6);
      color: var(--color-text-alt-2);
    }
  }
  button {
    padding: 4px;
  }
}
.content {
  display: block;
  flex-direction: column;
  padding: 10px;

  .offerInfo {
    margin-bottom: 0px;
    h5 {
      color: var(--color-text-base);
      font-weight: var(--font-weight-normal);
      line-height: 20px;
    }
  }
  .statsGroup {
    display: flex;
    flex-direction: column;
    & > :not(style) + :not(style) {
      margin-top: 8px;
    }
  }
  .stats {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    svg {
      width: 20px;
      height: 20px;
      color: var(--color-text-alt-2);
    }
    p {
      color: var(--color-text-alt-2);
    }
    h6 {
      font-weight: var(--font-weight-semibold);
    }
  }
  .offerQty {
    font-size: 16px;
  }
  .offerPrice {
    display: flex;
    align-items: center;
    margin-top: 16px;
    padding: 10px 10px;
    background-color: rgba(237, 246, 245, 0.7);
    border-radius: var(--border-radius-medium);
    cursor: pointer;
    .priceInfo {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .link {
      color: #fff;
      background: var(--color-primary);
      svg {
        width: 18px;
        height: 18px;
      }
    }
    .currency {
      font-weight: var(--font-weight-normal);
      font-size: var(--font-size-5);
      color: var(--color-text-alt-2);
      vertical-align: top;
      margin-right: 2px;
    }
    .priceTotal {
      font-weight: var(--font-weight-bold);
    }
    .pricetext {
      font-size: var(--font-size-7);
      font-style: italic;
      color: var(--color-text-alt-2);
    }
  }
}
.offerAction {
  padding: 8px 10px 16px;
  .expire {
    display: inline-block;
    color: var(--color-text-alt-2);
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-7);
    font-style: italic;
    margin-top: 5px;
  }
}
.offerRejected {
  display: inherit;
  text-align: right !important;
  color: var(--color-status-error-expired);
  font-style: italic;
  font-size: var(--font-size-7);
  font-weight: var(--font-weight-semibold);
  margin-top: -10px;
}
.statusLabel {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  height: 18px;
  margin-bottom: 4px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;
  border: 1px dashed var(--color-purple);
  background-color: inherit;
  font-size: 12px;
  font-weight: var(--font-weight-semibold);
  color: var(--color-purple);
  text-transform: capitalize;
  span {
    display: inline-block;
    margin-top: -1px;
  }
}
.afterAction {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.expirationStatus {
  display: flex;
  justify-content: flex-end;
  margin-left: 8px;
}
