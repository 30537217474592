.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.desc {
  font-size: var(--font-size-5);
  margin-bottom: 20px;
}
.paper {
  background-color: var(--color-background-alt);
  text-align: center;
  border-radius: 8px;
  padding: 24px;
  .list {
    max-width: 416px;
    margin: 0 auto;
    text-align: start;
    list-style: disc;
    li {
      font-size: var(--font-size-5);
      margin-bottom: 8px;
    }
  }
  .icon {
    width: 80px;
    height: 80px;
    margin-bottom: 16px;
  }
  .header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    h4 {
      font-size: var(--font-size-3);
      margin-right: 8px;
    }
    svg {
      width: 28px;
      height: 28px;
    }
  }
  .text {
    max-width: 512px;
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-normal);
    margin: 0 auto;
    margin-bottom: 16px;
  }
  .action {
    margin-top: 32px;
  }
  @media only screen and (max-width: 600px) {
    .header {
      h4 {
        margin-right: 0;
        margin-bottom: 8px;
      }
    }
  }
}
.paperQuestion {
  border-radius: 8px;
  // background-color: var(--color-background-alt);
  overflow: hidden;
}
