.root {
    & > :not(style) + :not(style) {
      padding-top: 10px;
    }
    p {
      line-height: 1.37;
    }
    hr {
        border-bottom: 1px solid #ccc;
    }
  }
  .actions {
    margin-top: 16px;
  }
  