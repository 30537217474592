.root {
  & > :not(style) + :not(style) {
    padding-top: 10px;
  }
  p {
    line-height: 1.37;
  }
  ul {
    list-style: disc;
    margin-left: 24px;
    & > :not(style) + :not(style) {
      margin-top: 5px;
    }
  }
}
.actions {
  margin-top: 16px;
}
