.root {
  display: flex;
  overflow: hidden;
  height: calc(100vh - 65px);
  @media only screen and (max-width: 768px) {
    height: calc(100vh - 65px);
  }
  @media only screen and (max-width: 600px) {
    height: calc(100vh - 57px);
  }
}
