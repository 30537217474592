.servicesPaper {
  width: 320px;
  margin-top: 10px;
  margin-left: 2px;
  overflow: visible !important;
  border: 1px solid rgba(145, 158, 171, 0.08);
  box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.24) 0px 12px 24px 0px !important;
}
.arrow {
  content: '';
  position: absolute;
  top: -7px;
  right: 12px;
  width: 12px;
  height: 12px;
  border-radius: 0px 0px 4px;
  background: var(--color-background-base);
  transform: rotate(-135deg);
  border-right: 1px solid rgba(145, 158, 171, 0.12);
  border-bottom: 1px solid rgba(145, 158, 171, 0.12);
  z-index: 1500;
}
.box {
  padding: 16px 4px;
}
.container {
  display: grid;
  gap: 16px;
  box-sizing: border-box;
  // width: 320px;
}
.title {
  font-weight: var(--font-weight-bold);
  line-height: 24px;
  text-align: center;
}
.group {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: start;
  width: 100%;
}
.wrap {
  display: inline-flex;
}
.link {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 4px;
  justify-items: center;
  box-sizing: border-box;
  padding: 16px 8px 20px;
  // width: 96px;
  width: 100%;
  border-radius: 8px;
  transition: background 0.2s ease 0s;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
}
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  // background-color: var(--color-main);
  border-radius: var(--border-radius-medium);
  color: #fff;
  &.guvery {
    background-color: var(--color-primary);
    img {
      width: 70%;
      display: block;
      filter: brightness(0) invert(1);
    }
  }
  &.tienda {
    background-image: -webkit-linear-gradient(0deg, #8668ad 0%, #55bbeb 100%);
    img {
      width: 70%;
      display: block;
      // filter: opacity(0.5) drop-shadow(0 0 0 #00594f);
    }
  }
  &.one {
    background-color: #ffbe4b;
    img {
      width: 80%;
      display: block;
      filter: brightness(0) invert(1);
    }
  }
  &.test {
    background-color: #fdd8dd;
    img {
      width: 65%;
      display: block;
    }
  }
  &.calculadora {
    background-color: #c9d3dc;
    img {
      width: 75%;
      display: block;
    }
  }
  &.coupons {
    background-color: #c3ade7;
    img {
      width: 80%;
      display: block;
    }
  }
  svg {
    width: 28px;
    height: 28px;
  }
}
.text {
  color: var(--color-text-alt-2);
  line-height: 16px;
  text-align: center;
}
