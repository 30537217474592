.user-shipments {
  .text-destination {
    text-transform: capitalize;
  }
  .no-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  .subtitle {
    margin: 20px 0px;
    font-weight: 400;
    color: var(--color-primary);
  }
  .title {
    position: relative;
    .dot {
      display: block;
      position: absolute;
      top: 5px;
      right: -16px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: rgb(255, 72, 66);
      box-shadow: 0 0 0 rgba(255, 72, 66, 0.4);
      animation: pulse-dot 2s ease-out;
      animation-iteration-count: infinite;
      animation-delay: 1.1s;
    }
  }
}

.dialog-content-ship {
  p {
    margin-bottom: 5px;
  }
  h4 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  ul {
    margin-bottom: 20px;
    li {
      list-style-type: disc;
      line-height: 30px;
      font-size: 16px;
      // margin-top: 1.56em;
      margin-left: 24px;
    }
  }
  .actions {
    text-align: center;
    p {
      margin-bottom: 16px;
      font-weight: var(--font-weight-normal);
    }
  }
}
