.root {
  .title {
    font-weight: var(--font-weight-bold);
    margin-bottom: 16px;
  }
  .steps {
    display: inline-flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;
    .stepLabel {
      display: flex;
      align-items: center;
      background: var(--color-background-alt);
      border-radius: var(--border-radius-small);
      padding: 16px;
      color: white;
    }
    .circle {
      flex-shrink: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: var(--color-green);
      user-select: none;
    }
    .label {
      color: var(--color-text-alt-2);
      font-weight: var(--font-weight-normal);
      line-height: 16px;
      margin-left: 8px;
    }
  }
  .amount {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 32px;
    margin-bottom: 8px;
    .header {
      color: var(--color-text-helper);
      font-weight: var(--font-weight-normal);
      margin-bottom: 4px;
    }
    .total {
      color: var(--color-text-primary);
      font-size: var(--font-size-2);
      font-weight: var(--font-weight-bold);
      &.negative {
        color: var(--color-status-error);
      }
    }
    .totalOld {
      color: var(--color-text-alt);
      text-decoration: line-through;
      opacity: 1;
    }
    .amountDivider {
      margin: 0 8px;
    }
  }
  .couponHint {
    max-width: 336px;
    text-align: center;
    margin: 0 auto;
    &.success {
      color: var(--color-status-success);
    }
    &.error {
      color: var(--color-status-error);
    }
    .italic {
      font-weight: var(--font-weight-normal);
      color: var(--color-text-base);
      // font-style: italic;
    }
    p {
      font-weight: var(--font-weight-normal);
      font-size: var(--font-size-5);
    }
  }
  .discount {
    margin-top: 16px;
    text-align: center;
    input {
      text-transform: uppercase;
    }
  }
  .helpCoupon {
    text-align: end;
    margin-top: 8px;
    span {
      color: var(--color-status-info);
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
