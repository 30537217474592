.root {
  display: flex;
  margin-bottom: 20px;
}
.wrap {
  display: flex;
  &.owner {
    margin-left: auto;
  }
}
.profile {
  :global {
    .MuiAvatar-root {
      width: 32px;
      height: 32px;
    }
  }
}
.content {
  max-width: 480px;
  margin-left: 16px;
}
.time {
  display: flex;
  margin-top: 0;
  font-size: var(--font-size-7);
  color: var(--color-text-alt-2);
  border-radius: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.owner {
    justify-content: flex-end;
    margin-left: auto;
  }
}
.text {
  // max-width: 220px;
  background-color: rgb(244, 246, 248);
  border-radius: 8px;
  padding: 12px;
  overflow: hidden;
  &.owner {
    background-color: rgb(160, 246, 232);
  }
  @media only screen and (max-width: 768px) {
    max-width: 220px;
  }
}
.msg {
  font-size: var(--font-size-6);
  font-weight: var(--font-weight-thin);
  line-height: 1.57143;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  a {
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }
}
.icon {
  display: flex;
  margin-right: 4px;
  &.owner {
    justify-content: flex-end;
    margin-left: 4px;
    margin-right: 0;
  }
  &.active {
    color: #4fc3f7;
  }
  svg {
    width: 18px;
    height: 18px;
  }
}
