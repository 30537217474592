.root {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  min-width: 520px;
  @media screen and (max-width: 768px) {
    min-width: 280px;
  }
}
.noBottom {
    padding-bottom: 0px;
}
.dialog {
  :global(.MuiDialog-container) {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-32%);
  }
}
.field {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--color-background-alt);
  border-radius: var(--border-radius-small);
  border: 1px solid var(--border-color-2);
  // padding-left: 16px;
}
.input {
  padding-left: 8px;
  &:global(.MuiInputBase-root) {
    font-size: 18px;
  }
}
.submitIcon {
  &:global(.MuiIconButton-root) {
    border-radius: var(--border-radius-small) 0 0 var(--border-radius-small);
    padding: 10px;
  }
  :global(.MuiTouchRipple-root) {
    border-radius: var(--border-radius-small);
  }
}
.submit {
  &:global(.MuiButton-root) {
    font-size: 16px;
    padding: 8px 24px;
  }
}
