.body {
  padding: 16px 16px;
}
.qualify {
  .text {
    display: flex;
    font-size: var(--font-size-6);
    color: var(--color-primary);
    font-weight: var(--font-weight-semibold);
  }
}
.shield {
  display: flex;
  align-items: center;
  color: var(--color-main-800);
  svg {
    flex-shrink: 0;
  }
  .text {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    font-size: var(--font-size-6);
    color: var(--color-primary);
    font-weight: var(--font-weight-semibold);
  }
}
.validating {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .text {
    display: flex;
    align-items: center;
    margin: 4px 0;
    color: var(--color-primary);
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-6);
    svg {
      margin-right: 4px;
    }
  }
  .hint {
    font-size: var(--font-size-7);
    font-weight: var(--font-weight-semibold);
    color: var(--color-text-alt-2);
  }
}
.expire {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 4px;
  color: var(--color-status-alert);
  .expireText {
    margin-left: 4px;
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-6);
  }
}
.deliverySetup {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  p {
    color: var(--color-status-success);
    text-align: end;
    font-size: var(--font-size-7);
    font-weight: bold;
  }
  button {
    background-color: var(--color-blue);
    color: #fff;
  }
  button:hover {
    background-color: var(--color-purple);
    color: #fff;
  }
}
