.container {
  max-width: 300px;
  display: flex;
  justify-content: start;
  &.anchor {
    text-decoration: none;
    cursor: pointer;
  }
}
.box {
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 6px;
  justify-content: space-between;
  align-items: center;
  .avatar {
    width: 36px;
    height: 36px;
    &.bigAvatar {
      width: 72px;
      height: 72px;
    }
  }
  .userInfo {
    display: flex;
    flex-direction: column;
    gap: 2px;
    .line {
      display: flex;
      margin-left: 5px;
      &.emphasis {
        align-items: center;
        gap: 6px;
      }
    }
    .name {
      max-width: 120px;
      font-size: var(--font-size-5);
      font-weight: var(--font-weight-semibold);
      color: #333;
      &.emphasis {
        padding-left: 2px;
        font-weight: var(--font-weight-bold);
        color: #000;
      }
    }
    .ratingAvg {
      display: flex;
      align-items: center;
      gap: 2px;
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-6);
      color: var(--color-text-alt-3);
      svg {
        width: 16px;
        height: 16px;
        fill: #f3a43d;
        color: #f3a43d;
      }
      &.emphasis {
        color: var(--color-text-alt-2);
        font-weight: var(--font-weight-semibold);
        font-size: var(--font-size-7);
        padding-right: 4px;
        svg {
          width: 14px;
          height: 14px;
          fill: #f3a43d;
          color: #f3a43d;
        }
      }
    }
    .scoreLevel {
      color: var(--color-text-darker);
      font-weight: var(--font-weight-semibold);
    }
  }
}
