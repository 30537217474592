.root {
  width: 100%;
  position: relative;
  color: var(--color-text-base);
  background-color: var(--color-background-base);
  box-shadow: rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px;
  z-index: 1100;
  svg {
    color: var(--color-text-base);
  }
}
.content {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
}
.wrap {
  display: flex;
  margin-right: 16px;
  :global(.MuiAvatar-root) {
    width: 42px;
    height: 42px;
    background-color: var(--color-background-alt-2);
  }
}
.title {
  flex: 1;
  margin-left: 16px;
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-semibold);
  line-height: 1.6;
  letter-spacing: 0.0075em;
}
.info {
  flex-grow: 1;
  padding-left: 16px;
}
.name {
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-bold);
  line-height: 1.25;
  letter-spacing: 0.0075em;
}
.type {
  font-weight: var(--font-weight-semibold);
  letter-spacing: 0.0075em;
  color: var(--color-text-alt-2);
}
