.amountTitle {
  padding: 10px 0;
  h3 {
    font-weight: var(--font-weight-bold);
  }
  p {
    color: var(--color-text-alt-2);
    font-weight: var(--font-weight-normal);
  }
}
.titleText {
  font-weight: var(--font-weight-bold);
}
.groupPrice {
  display: flex;
  align-items: center;
  justify-content: center;
  // gap: 16px;
  .currency {
    display: flex;
    gap: 16px;
  }
  .input {
    flex-grow: 1;
  }
  & > * {
    margin: 0.5rem 0.5rem;
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
    &.one {
      flex-wrap: nowrap;
    }
  }
}
.helpText {
  display: block;
  color: var(--color-text-alt-2);
  font-weight: var(--font-weight-normal);
  line-height: 1.35;
  font-style: italic;
}
.cambio {
  text-align: end;
  margin: 0 0.5rem;
  color: var(--color-text-alt-2);
  font-size: var(--font-size-7);
  font-weight: var(--font-weight-normal);
  .bold {
    color: var(--color-text-base);
    font-weight: var(--font-weight-semibold);
  }
}
.radioInput {
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  white-space: nowrap;
  overflow: hidden;
  &[type='radio']:checked + .radioTile {
    border-color: var(--color-green);
    box-shadow: 0 5px 10px rgba(#000, 0.1);
    color: var(--color-green);
    &:before {
      transform: scale(1);
      opacity: 1;
      background-color: var(--color-green);
      border-color: var(--color-green);
    }
    .radioIcon,
    .radioLabel {
      color: var(--color-green);
    }
  }
  &[type='radio']:focus + .radioTile {
    border-color: var(--color-green);
    box-shadow: 0 5px 10px rgba(#000, 0.1), 0 0 0 4px #bcf6d4;
    &:before {
      transform: scale(1);
      opacity: 1;
    }
  }
}
.radioTile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // width: 7rem;
  min-height: 7.5rem;
  margin: 0 auto;
  border-radius: var(--border-radius-medium);
  border: 2px solid var(--color-text-alt);
  background-color: #fff;
  box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    border: 2px solid #b5bfd9;
    background-color: #fff;
    border-radius: 50%;
    top: 0.25rem;
    left: 0.25rem;
    opacity: 0;
    transform: scale(0);
    transition: 0.25s ease;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  &:hover {
    border-color: var(--color-green);
    &:before {
      transform: scale(1);
      opacity: 1;
    }
  }
  &.small {
    flex-direction: row;
    width: 8rem;
    min-height: 3.5rem;
    .radioLabel {
      margin-left: 8px;
    }
    .radioIcon {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
  &.disabled {
    opacity: 0.5;
    cursor: default;
    border-color: rgba(0, 0, 0, 0.26);
    &:hover {
      border-color: rgba(0, 0, 0, 0.26);
      &:before {
        opacity: 0;
      }
    }
    .radioIcon {
      filter: grayscale(1);
    }
    .radioLabel {
      color: rgba(0, 0, 0, 0.56);
    }
  }
}
.radioIcon {
  // width: 3rem;
  // height: 3rem;
  transition: 0.375s ease;
  color: #494949;
  user-select: none;
  margin-top: -4px;
  text-align: center;
  svg,
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
.radioLabel {
  display: block;
  color: #707070;
  font-weight: var(--font-weight-normal);
  transition: 0.375s ease;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.checkboxInput {
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  white-space: nowrap;
  overflow: hidden;
  &[type='checkbox']:checked + .checkboxTile {
    border-color: var(--color-green);
    box-shadow: 0 5px 10px rgba(#000, 0.1);
    color: var(--color-green);
    &:before {
      transform: scale(1);
      opacity: 1;
      background-color: var(--color-green);
      border-color: var(--color-green);
    }
    .checkboxIcon,
    .checkboxLabel {
      color: var(--color-green);
    }
  }
  &[type='checkbox']:focus + .checkboxTile {
    border-color: var(--color-green);
    box-shadow: 0 5px 10px rgba(#000, 0.1), 0 0 0 4px #bcf6d4;
    &:before {
      transform: scale(1);
      opacity: 1;
    }
  }
}
.checkboxTile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 4rem;
  min-height: 6rem;
  border-radius: var(--border-radius-medium);
  border: 2px solid var(--color-text-alt);
  background-color: #fff;
  box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    border: 2px solid #b5bfd9;
    background-color: #fff;
    border-radius: 50%;
    top: 0.25rem;
    left: 0.25rem;
    opacity: 0;
    transform: scale(0);
    transition: 0.25s ease;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  &:hover {
    border-color: var(--color-green);
    &:before {
      transform: scale(1);
      opacity: 1;
    }
  }
  &.small {
    flex-direction: row;
    width: 8rem;
    min-height: 3.5rem;
    .checkboxLabel {
      margin-left: 8px;
    }
    .checkboxIcon {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
  &.disabled {
    opacity: 0.7;
    cursor: default;
    border-color: rgba(0, 0, 0, 0.25);
    &:hover {
      border-color: rgba(0, 0, 0, 0.25);
      &:before {
        opacity: 0;
      }
    }
    .checkboxIcon,
    .checkboxLabel {
      color: rgba(0, 0, 0, 0.56);
    }
  }
}
.checkboxIcon {
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-semibold);
  color: #494949;
  user-select: none;
  transition: 0.375s ease;
}
.checkboxLabel {
  color: #707070;
  font-weight: var(--font-weight-normal);
  transition: 0.375s ease;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.creditHint {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 192px;
  min-height: 48px;
  border-radius: var(--border-radius-medium);
  padding: 10px 16px;
}
.creditSummary {
  background-color: var(--color-background-alt);
  line-height: 1.43;
  p {
    font-size: var(--font-size-5);
  }
  .amount {
    font-weight: var(--font-weight-bold);
  }
}
.creditWarning {
  background-color: rgb(255, 244, 229);
  svg {
    flex-shrink: 0;
    color: var(--color-status-alert);
  }
  .text {
    font-weight: var(--font-weight-normal);
    margin-left: 8px;
    color: rgb(102, 60, 0);
  }
}
