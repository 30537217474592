.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-background-alt);
  border-radius: var(--border-radius-medium);
  padding: 20px;
  margin-bottom: 30px;
  @media only screen and (max-width: 1024px) {
    & {
      flex-direction: column;
    }
  }
}
.content {
  .header {
    display: flex;
    color: var(--color-status-error);
    h5 {
      font-weight: var(--font-weight-semibold);
      margin-left: 8px;
    }
  }
  .text {
    color: var(--color-text-alt-2);
  }
  @media only screen and (max-width: 1024px) {
    & {
      margin-bottom: 16px;
      .header {
        justify-content: center;
      }
      .text {
        text-align: center;
      }
    }
  }
}
