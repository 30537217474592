.root {
  padding: 16px 16px;
}
.hint {
  color: var(--color-text-alt-2);
}
.request {
  display: flex;
  align-items: center;
  color: rgb(13, 60, 97);
  background-color: rgb(232, 244, 253);
  svg {
    color: var(--color-status-info);
    margin-right: 5px;
  }
}
.paid {
  display: flex;
  align-items: center;
  color: rgb(30, 70, 32);
  background-color: rgb(237, 247, 237);
  svg {
    color: var(--color-status-success);
    margin-right: 5px;
  }
}
.dialogContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 240px;
  .text {
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-thin);
    color: var(--color-text-alt-2);
    margin-bottom: 24px;
  }
}
