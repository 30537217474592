.root {
  margin: 16px;
}
.info {
  background: var(--color-background-alt);
  border-radius: var(--border-radius-small);
  padding: 16px;
  & > :not(style) + :not(style) {
    margin-top: 8px;
  }
  @media screen and (max-width: 768px) {
    padding: 10px;
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  h5 {
    font-weight: var(--font-weight-semibold);
  }
}
.item {
  display: flex;
  align-items: center;
  gap: 8px;
  p {
    color: var(--color-text-alt-2);
  }
  h6 {
    font-weight: var(--font-weight-semibold);
  }
}
.acordion {
  box-shadow: none !important;
  // background: var(--color-background-alt);
  // background-color: var(--color-background-alt);
  border: 2px dashed var(--border-color-2);
  :global {
    .MuiAccordionSummary-root.Mui-expanded {
      min-height: 32px;
    }
    .MuiAccordionSummary-content.Mui-expanded {
      // margin: 12px 0 5px;
    }
  }
  .header {
    width: 100%;
    h5 {
      color: var(--color-text-alt-2);
      font-weight: var(--font-weight-normal);
      line-height: 20px;
    }
  }
}
.formHelp {
  display: flex;
  align-items: center;
  color: var(--color-primary);
  margin-top: 2px;
  p {
    font-weight: var(--font-weight-semibold);
    font-size: 13px;
  }
  svg {
    width: 19px;
    height: 19px;
    margin-left: 4px;
  }
}
