.root {
  background-color: var(--color-background-alt);
  border-radius: var(--border-radius-medium);
}
.header {
  display: flex;
  align-items: center;
  padding: 16px;
  .headerContent {
    flex: 1 1 auto;
  }
  .headerTitle {
    display: block;
    font-size: var(--font-size-4);
    font-weight: var(--font-weight-semibold);
    line-height: 1.334;
  }
}
.divider {
  margin: 0px;
  flex-shrink: 0;
  background: rgba(34, 51, 84, 0.1);
  border: 0px;
  height: 1px;
}
.body {
  padding: 24px;
  @media screen and (max-width: 768px) {
    padding: 16px;
  }
}
.offerInfo {
  padding: 12px 16px;
}
.traveler {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 16px;
  // background-color: var(--color-background-alt);
  border-radius: var(--border-radius-medium);
}
.travelerCover {
  flex-shrink: 0;
  .avatar {
    height: 50px;
    width: 50px;
    // border-radius: 6px;
    background-color: var(--color-background-alt-2);
    color: rgb(80, 97, 118);
  }
}
.travelerText {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 8px;
  padding: 8px 0;
  .name {
    font-weight: var(--font-weight-semibold);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .score {
    display: inline-flex;
    align-items: center;
    margin-top: 2px;
    color: #f3a43d;
    svg {
      width: 18px;
      height: 18px;
      fill: #f3a43d;
    }
    span {
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-6);
      color: var(--color-text-alt-2);
      margin-left: 4px;
    }
  }
}
.product {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
  .cover {
    flex-shrink: 0;
    .image {
      height: 80px;
      width: 80px;
      border-radius: var(--border-radius-medium);
    }
  }
  .info {
    flex: 1 1 auto;
    padding-left: 9px;
    width: auto;
    .offerId {
      color: var(--color-text-alt-2);
      font-weight: var(--font-weight-semibold);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .link {
      font-size: var(--font-size-5);
      font-weight: var(--font-weight-semibold);
      color: rgb(34, 51, 84);
      line-height: 1.334;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:hover {
        color: var(--color-main-800);
        text-decoration: underline;
      }
    }
    .observation {
      color: var(--color-status-info);
      font-weight: var(--font-weight-semibold);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 2px;
    }
    @media screen and (max-width: 768px) {
      padding-top: 8px;
      padding-left: 0px;
    }
  }
}
.item {
  display: flex;
  height: 100%;
  .wrap {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    padding: 10px 16px;
  }
}
.itemText {
  flex-shrink: 0;
  flex: 1 1 auto;
  min-width: 0px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  h6 {
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-normal);
    line-height: 1.2;
    letter-spacing: 0px;
    color: var(--color-text-alt-2);
  }
  .response {
    flex: 1 1 0%;
    margin-top: 0px;
    p {
      font-weight: bold;
      color: var(--color-graphite);
      font-size: var(--font-size-5);
      line-height: 1.6;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
}
