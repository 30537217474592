.root {
  text-align: center;
  background: var(--color-background-alt-1);
  padding: 24px;
  margin: 0 16px 16px;
  border: 1px solid var(--border-color-2);
  // border-top: 1px solid var(--border-color-2);
  border-radius: var(--border-radius-medium);
  .wrap {
    max-width: 360px;
    margin: 0 auto;
  }
}
.desc {
  margin-bottom: 16px;
  color: var(--color-text-alt-2);
  line-height: 1.5;
}
.user {
  margin-bottom: 4px;
  .avatar {
    width: 80px;
    height: 80px;
    margin: 0 auto;
  }
  .name {
    margin-top: 8px;
  }
}
.score {
  margin-bottom: 24px;
  .rating {
    font-size: 2.5rem;
  }
  .text {
    margin-top: 4px;
    color: rgb(102, 60, 0);
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-semibold);
  }
}
.form {
  margin-bottom: 24px;
  .accordion {
    box-shadow: none;
    border: 2px dashed var(--border-color-2);
    &.expanded {
      border: 2px dashed transparent;
      background-color: transparent;
    }
    :global(.MuiAccordionDetails-root) {
      padding: 0;
    }
  }
  .summary {
    &:global(.MuiAccordionSummary-root.Mui-expanded) {
      min-height: 32px;
      padding: 0;
      cursor: auto;
    }
    :global(.MuiAccordionSummary-content.Mui-expanded) {
      margin: 10px 0;
      padding: 0;
    }
  }
  .field {
    background-color: var(--color-background-base);
  }
  .heading {
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-semibold);
    color: var(--color-text-alt-2);
  }
}
.hint {
  display: inline-block;
  color: var(--color-text-alt-2);
  // font-size: var(--font-size-7);
  // font-weight: var(--font-weight-semibold);
  margin-top: 8px;
}
