.divider {
  margin: 0px;
  flex-shrink: 0;
  background: rgba(34, 51, 84, 0.1);
  border: 0px;
  height: 1px;
}
.traveler-dashboard {
  .dashboard-box {
    // min-height: 108px;
    padding: 20px;
  }
  .box-info-small {
    position: relative;
    .info-small-icon {
      position: absolute;
      top: 24px;
      right: 24px;
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      line-height: 1;
      color: #827ffb;
      background-color: #e4e4ff;
      border-radius: 50%;
      &.blue {
        color: #0093d3;
        background-color: #aae2fa;
      }
      svg {
        font-size: 30px;
        &.pack-icon {
          font-size: 34px;
        }
      }
    }
    .info-small-title {
      color: var(--color-text-alt);
      font-weight: var(--font-weight-normal);
      margin-bottom: 4px;
    }
    .info-small-count {
      color: var(--color-text-darker);
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-2);
    }
    .info-small-content {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      font-size: 14px;
      margin-top: 16px;
      svg {
        font-size: 16px;
        margin-right: 2px;
      }
      span {
        margin-right: 4px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
  .boxEarnings {
    position: relative;
    height: 91px;
    height: 91px;
    @media screen and (max-width: 768px) {
      height: 84px;
    }
    &.info-success {
      background-color: #17c57e;
      color: var(--color-text-white);
      .info-medium-icon {
        top: 50%;
        right: 20px;
        transform: translateX(0) translateY(-50%) rotateZ(-20deg);
        svg {
          color: #5adda7;
          width: 112px;
          height: 112px;
        }
      }
    }
    .info-medium-icon {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateX(0) translateY(-50%) rotateZ(-10deg);
      svg {
        // color: #17c57e;
        color: var(--color-primary);
        width: 160px;
        height: 160px;
      }
      @media screen and (max-width: 1150px) {
        top: 40%;
        svg {
          color: var(--color-primary);
          width: 112px;
          height: 112px;
        }
      }
      @media screen and (max-width: 768px) {
        top: 40%;
        svg {
          color: var(--color-primary);
          width: 80px;
          height: 80px;
        }
      }
    }
    .info-medium-title {
      color: inherit;
      font-size: var(--font-size-4);
      font-weight: var(--font-weight-semibold);
      margin-bottom: 4px;
    }
    .info-medium-count {
      color: var(--color-primary);
      font-size: var(--font-size-2);
      font-weight: var(--font-weight-bold);
    }
    .info-medium-footer {
      color: inherit;
      font-size: var(--font-size-5);
      margin-top: 38px;
      span {
        margin-right: 4px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
  .box-info-travel {
    display: flex;
    flex-direction: row;
    .info-travel-left {
      width: 100%;
    }
    .info-travel-right {
      width: 100%;
    }
    .info-travel-title {
      color: var(--color-text-alt);
      font-weight: var(--font-weight-normal);
      margin-bottom: 4px;
      strong {
        color: var(--color-text-base);
      }
    }
    .info-stack {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      .info-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }
      .info-space {
        display: flex;
        align-items: center;
      }
    }
    .info-detai {
      display: flex;
      align-items: center;
      margin-top: 10px;
      padding: 8px;
      .detai-icon {
        flex-shrink: 0;
        color: var(--color-blue);
      }
      .detai-body {
        flex-grow: 1;
        margin-left: 16px;
        p {
          color: var(--color-text-helper);
          font-weight: var(--font-weight-normal);
        }
        h6 {
          font-weight: var(--font-weight-semibold);
        }
      }
    }
  }  
}
