.root {
  & > :not(style) + :not(style) {
    padding-top: 10px;
  }
  p {
    line-height: 1.37;
  }
  hr {
    border-bottom: 1px solid #ccc;
  }
  .dealDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .deliveryDate {
      min-height: 44px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 0 10px;
      border-radius: var(--border-radius-small);
      border: 1px solid var(--color-dark-gray);
      color: var(--color-text-alt-2);
      .textHelper {
        color: var(--color-graphite);
        &::first-letter {
          text-transform: capitalize;
        }
      }
      .text {
        margin-top: 2px;
        color: var(--color-graphite);
        font-weight: var(--font-weight-semibold);
      }
    }
    .user {
      flex: 1 1 auto;
      display: inline-flex;
      align-items: center;
      .avatar {
        width: 38px;
        height: 38px;
      }
      .userInfo {
        flex: 1 1 auto;
        margin-left: 8px;
      }
      .name {
        max-width: 120px;
        font-size: var(--font-size-5);
        font-weight: var(--font-weight-semibold);
      }
      .userScore {
        display: inline-flex;
        align-items: center;
        margin-top: 4px;
        color: #f3a43d;
        svg {
          width: 15px;
          height: 15px;
          fill: #f3a43d;
        }
        span {
          font-weight: var(--font-weight-semibold);
          font-size: var(--font-size-7);
          color: var(--color-text-alt-2);
          margin-left: 4px;
        }
      }
    }
  }
  .deliverySetup {
    margin-top: 10px;
    .body {
      margin-top: 20px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        width: 100%;
      }
      p {
        display: flex;
        align-items: flex-start;
        gap: 6px;
        & svg {
          margin-top: 2px;
          overflow: visible;
          font-weight: bold;
          color: var(--color-blue);
        }
      }
    }
    .details {
      margin-top: 10px;
    }
  }
}
