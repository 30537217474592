.root {
  p {
    margin-bottom: 5px;
  }
  h4 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  ul {
    margin-bottom: 10px;
    li {
      list-style-type: disc;
      line-height: 30px;
      font-size: 16px;
      // margin-top: 1.56em;
      margin-left: 24px;
    }
  }
  .actions {
    text-align: center;
    p {
      margin-bottom: 16px;
      font-weight: var(--font-weight-normal);
    }
  }
}
.divider {
  margin: 0px;
  flex-shrink: 0;
  border-width: 1px thin 0px 0px;
  border-style: solid;
  border-color: #ccc;
  height: auto;
  align-self: stretch;
}
