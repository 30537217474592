.root {
  width: 100%;
  position: relative;
  color: var(--color-text-base);
  background-color: var(--color-background-base);
  box-shadow: rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px;
  z-index: 1100;
  svg {
    color: var(--color-text-base);
  }
}
.title {
  flex: 1;
  margin-left: 8px;
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-bold);
  line-height: 1.6;
  letter-spacing: 0.0075em;
}
