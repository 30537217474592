.root {
  padding-top: 24px;
  padding-bottom: 24px;
}
.header {
  display: flex;
  align-items: center;
  padding: 0 24px;
  .headerContent {
    flex: 1 1 auto;
    span {
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-4);
    }
  }
}
.stack {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
}
.divider {
  margin: 0px;
  flex-shrink: 0;
  border-width: 0px thin 0px 0px;
  border-style: solid;
  border-color: rgba(145, 158, 171, 0.24);
  height: auto;
  align-self: stretch;
}
.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  p {
    color: var(--color-text-alt-2);
    font-weight: var(--font-weight-normal);
  }
  h4 {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-3);
    margin-bottom: 4px;
  }
  span {
    font-size: 20px;
  }
  h5 {
    width: 80%;
    color: var(--color-text-alt-2);
    font-weight: var(--font-weight-thin);
    font-size: var(--font-size-5);
  }
}
