.root {
  background-color: var(--color-background-alt);
  border-radius: var(--border-radius-medium);
  padding: 32px 24px;
  font-size: var(--font-size-5);
  line-height: 1.5715;
  text-align: center;
  overflow: hidden;
}
.image {
  height: 128px;
  margin-bottom: 16px;
  img {
    height: 100%;
    opacity: 0.75;
    filter: grayscale(1);
    user-select: none;
  }
}
.desc {
  max-width: 640px;
  margin: 24px auto;
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-semibold);
  color: var(--color-text-alt-2);
  line-height: 20px;
}
.footer {
  padding-top: 8px;
}
