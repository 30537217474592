.title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 24px;
  p {
    margin-top: 4px;
  }
  a {
    color: var(--color-text-primary);
    font-weight: var(--font-weight-normal);
    &:hover {
      text-decoration: underline;
    }
  }
}
.box {
  .header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    border-bottom: 1px solid rgba(135, 153, 160, 0.25);
    .link {
      a {
        color: var(--color-text-primary);
        font-weight: var(--font-weight-normal);
        &:hover {
          text-decoration: underline;
        }
      }
    }
    @media (max-width: 960px) {
      .link {
        margin-top: 4px;
      }
    }
  }
  .content {
    padding: 24px;
    & > :not(style) + :not(style) {
      margin-top: 24px;
    }
    @media (max-width: 960px) {
      // padding: 16px;
      & > :not(style) + :not(style) {
        margin-top: 16px;
      }
    }
  }
  .submit {
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px;
    background-color: var(--color-background-alt);
    border-top: 1px solid rgba(135, 153, 160, 0.25);
  }
}
.iconWrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.icon {
  width: 24px !important;
  height: 24px !important;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 16px;
}
.terms {
  display: block;
  max-width: 520px;
  margin-top: 16px;
  a {
    color: var(--color-text-primary);
    font-weight: var(--font-weight-normal);
    &:hover {
      text-decoration: underline;
    }
  }
}
