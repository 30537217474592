.header {
  display: flex;
  align-items: center;
  padding: 16px;
  .headerContent {
    flex: 1 1 auto;
  }
  .headerTitle {
    display: block;
    font-size: var(--font-size-4);
    font-weight: var(--font-weight-semibold);
    line-height: 1.334;
  }
}
.divider {
  margin: 0px;
  flex-shrink: 0;
  background: rgba(34, 51, 84, 0.1);
  border: 0px;
  height: 1px;
}
.traveler {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  margin: 12px 24px 16px;
  padding: 10px 16px;
  background-color: var(--color-background-alt);
  border-radius: var(--border-radius-medium);
}
.list {
  list-style: none;
  margin: 0px;
  padding: 0px;
  position: relative;
}
.item {
  position: relative;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  padding: 12px 24px;
}
.itemText {
  flex: 1 1 auto;
  min-width: 0px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  h6 {
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-normal);
    line-height: 1.2;
    letter-spacing: 0px;
    color: var(--color-text-alt-2);
  }
  .response {
    flex: 1 1 0%;
    margin-top: 0px;
    p {
      font-weight: bold;
      color: var(--color-graphite);
      font-size: var(--font-size-5);
      line-height: 1.6;
    }
  }
}
