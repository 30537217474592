.root {
  .content,
  .actions {
    padding: 16px 24px;
    @media screen and (max-width: 768px) {
      padding: 16px;
    }
  }
  .wrap {
    min-width: 240px;
  }
  .minHeight {
    min-height: 240px;
  }
}
