.table {
  height: auto;
  overflow: hidden;
  td {
    padding: 10px 2px;
    @media (min-width: 768px) {
        padding: 14px 10px;
    }
  }
  
}
.header {
  background-color: rgb(243, 244, 247);
  border-top: 1px solid rgb(225, 227, 234);
}
.name {
  display: inline-flex;
  align-items: center;
  span {
    font-weight: var(--font-weight-semibold);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.link {
  display: inline-block;
  max-width: 176px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 4px;
  &:hover {
    color: var(--color-main-800);
    text-decoration: underline;
  }
}
.total {
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-bold);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.remarks {
  color: rgb(9, 153, 182);
  font-size: var(--font-size-6);
  font-weight: bold;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.extra {
  background-color: #333;
  display: flex;
  flex-direction: row;
  gap: 2;
  align-items: flex-start;
  justify-content: space-between;
  div {
    flex: 1 1 0px;
  }
  
}
.wrap {
  & > :not(style) + :not(style) {
    margin-top: 10px;
  }
  p {
    font-size: var(--font-size-5);
    line-height: 1.45;
  }
  ul {
    opacity: 0.7;
    padding-left: 24px;
  }
  ul > li {
    line-height: 1.2;
    margin-top: 4px;
  }
  .benefitsMore {
    li  {
      list-style-type: "✅";
      padding-inline-start: 6px;
    }
  }
  .benefitsLess {
    li  {
      list-style-type: "⏱️";
      padding-inline-start: 6px;
    }
  }
}