.root {
  margin-bottom: 16px;
  h5 {
    font-weight: var(--font-weight-semibold);
    line-height: 1.43;
    letter-spacing: 0.01071em;
    margin-top: -2px;
    margin-bottom: 4px;
  }
}
