.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // min-height: 240px;
}
.table {
  height: auto;
  overflow: hidden;
}
.header {
  background-color: rgb(243, 244, 247);
  border-top: 1px solid rgb(225, 227, 234);
}
.product {
  display: flex;
  align-items: center;
  overflow: hidden;
  border-top: 1px solid rgb(225, 227, 234);
  padding: 10px 16px;
}
.cover {
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  border-radius: 6px;
  background-color: rgb(225, 227, 234);
  overflow: hidden;
  user-select: none;
  color: rgb(80, 97, 118);
  margin-right: 16px;
  .img {
    width: 100%;
    height: 100%;
    text-align: center;
    object-fit: cover;
    color: transparent;
    text-indent: 10000px;
  }
  .holder {
    width: 75%;
    height: 75%;
    text-align: center;
    object-fit: cover;
    color: transparent;
    text-indent: 10000px;
  }
}
.info {
  flex: 1 1 auto;
  min-width: 0;
  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 3px;
  }
  .link {
    max-width: 176px;
    color: var(--color-text-alt-2);
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      text-decoration: underline;
    }
  }
}

.total {
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-semibold);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
