.list {
  min-height: 133px;
  padding-bottom: 16px;
}
.link {
  display: block;
}
.item {
  position: relative;
  margin: 16px 20px 0px;
  padding: 10px 16px;
  font-size: var(--font-size-6);
  background-color: var(--color-background-alt-1);
  border-radius: var(--border-radius-small);
  cursor: pointer;
  &:hover {
    background-color: var(--color-background-alt);
  }
  &::before {
    content: '';
    background: var(--color-primary);
    color: var(--color-primary);
    border-top-left-radius: var(--border-radius-small);
    border-bottom-left-radius: var(--border-radius-small);
    position: absolute;
    text-align: center;
    width: 6px;
    left: 0px;
    height: 100%;
    top: 0px;
  }
  .dot {
    width: 10px;
    height: 10px;
    background: var(--color-primary);
    border-radius: 22px;
    display: inline-block;
    margin-right: 8px;
  }
  .title {
    font-weight: var(--font-weight-semibold);
  }
  .text {
    padding-left: 19px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.itemLoading {
  margin: 16px 20px 0px;
  border-radius: var(--border-radius-small);
}
.empty {
  margin: 16px 20px 0px;
  padding: 10px 16px;
  font-size: var(--font-size-5);
}
