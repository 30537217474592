.root {
  height: 100%;
  overflow-x: auto;
}
.subTitle {
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 16px 16px 0;
  h4 {
    font-weight: var(--font-weight-bold);
  }
  .subTitleIcon {
    width: 24px;
    height: 24px;
  }
}
.offerForm {
  margin: 16px;
}
.action {
  margin: 24px 16px;
}
.formLabel {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  label {
    display: block;
    font-weight: var(--font-weight-normal);
  }
  svg {
    width: 20px;
    height: 20px;
    margin-left: 4px;
  }
}
.formHelp {
  display: flex;
  align-items: center;
  color: var(--color-primary);
  margin-top: 2px;
  p {
    font-weight: var(--font-weight-normal);
    font-size: 13px;
  }
  svg {
    width: 19px;
    height: 19px;
    margin-left: 4px;
  }
}
.acordion {
  box-shadow: none !important;
  // background-color: var(--color-background-alt);
  border: 2px dashed var(--border-color-2);
  overflow: hidden;
  :global {
    .MuiAccordionSummary-root.Mui-expanded {
      min-height: 32px;
    }
    .MuiAccordionSummary-content {
      width: calc(100% - 48px);
    }
    .MuiAccordionSummary-content.Mui-expanded {
      width: calc(100% - 48px);
      margin: 12px 0 5px;
    }
  }
  .header {
    max-width: 95%;
    flex-grow: 1;
    h5 {
      color: var(--color-text-alt-2);
      font-weight: var(--font-weight-normal);
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.offerHelp {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin: 16px;
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-6);
  color: var(--color-text-primary);
  svg {
    width: 18px;
    height: 18px;
  }
}
.slider {
  position: relative;
  padding: 0 24px;
}
.offerOptions {
  display: flex;
  gap: 0px;
  align-items: center;
  background: rgba(224, 241, 255, 0.75);
  border-radius: var(--border-radius-small);
  padding: 4px 8px;
  margin: 16px 16px;
  svg {
    width: 20px;
    height: 20px;
    color: #022d92;
  }
  p {
    margin-left: 4px;
    font-weight: var(--font-weight-semibold);
    line-height: 20px;
    color: #022d92;
  }
  span {
    font-size: 0.8em;
    font-weight: var(--font-weight-normal);
  }
}
