.root {
  padding: 16px;
}
.list {
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
  }
}
.item {
  display: flex;
  align-items: center;
  width: 100%;
  .icon {
    flex-shrink: 0;
  }
  .info {
    flex-grow: 1;
    margin-left: 4px;
    p {
      font-size: var(--font-size-6);
      font-weight: var(--font-weight-normal);
      color: var(--color-text-alt-2);
    }
    h6 {
      font-size: var(--font-size-5);
      font-weight: var(--font-weight-semibold);
      margin-top: 2px;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
}
.place {
  margin-top: 16px;
}
