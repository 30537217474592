.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 320px;
  overflow: hidden;
  border-left: 1px solid rgba(145, 158, 171, 0.24);
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &.close {
    width: 0px;
    visibility: hidden;
  }
}
.content {
  padding: 20px;
  overflow-x: auto;
}
.header {
  margin-bottom: 16px;
  .chip {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    margin-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 8px;
    border: 1px dashed var(--color-purple);
    background-color: inherit;
    font-size: 14px;
    font-weight: var(--font-weight-semibold);
    color: var(--color-purple);
    text-transform: capitalize;
    span {
      display: inline-block;
      margin-top: -1px;
    }
    &.created {
      background-color: rgb(0, 171, 85);
    }
  }
}
.list {
  & > :not(style) + :not(style) {
    margin-bottom: 16px;
  }
}
.product {
  background: var(--color-background-alt);
  border-radius: var(--border-radius-small);
  padding: 16px;
}
.cover {
  border-radius: var(--border-radius-medium);
  background: var(--color-background-alt-2);
  overflow: hidden;
  img {
    display: block;
    user-select: none;
    object-fit: cover;
  }
}
.image {
  // width: 100%;
  // height: 100%;
  height: 144px;
  margin: 0 auto;
}
.holder {
  height: 144px;
  margin: 10px auto;
}

.title {
  font-weight: var(--font-weight-semibold);
  margin-top: 10px;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.info {
  font-weight: var(--font-weight-semibold);
  color: var(--color-status-info);
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  .cant {
    flex-shrink: 0;
    margin-right: 4px;
  }
  .head {
    display: flex;
    align-items: center;
    margin-right: 4px;
    font-weight: var(--font-weight-semibold);
    .text {
      margin-left: 2px;
    }
  }
  svg {
    width: 14px;
    height: 14px;
  }
  a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.priceList {
  .charge {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 2px 0;
    &:last-child {
      border-top: 1px solid var(--border-color-2);
      margin-top: 8px;
      padding-top: 8px;
    }
  }
  dt {
    flex: 1 1;
    font-weight: var(--font-weight-normal);
    color: var(--color-text-alt-2);
  }
  dd {
    font-weight: var(--font-weight-semibold);
  }
}
.moreInfo {
  margin-top: 16px;
}
