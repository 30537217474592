.root {
  margin: 0 16px;
  padding: 24px;
  background: var(--color-background-alt);
  border: 1px solid var(--border-color-2);
  border-radius: var(--border-radius-large);
}
.title {
  h4 {
    color: var(--color-text-base);
  }
  p {
    color: var(--color-text-alt-2);
  }
}
.image {
  display: flex;
  align-items: center;
  min-height: 192px;
  overflow: hidden;
  .holderLabel {
    width: 100%;
    cursor: pointer;
  }
  .imageHolder {
    position: relative;
    display: flex;
    align-items: center;
    height: 192px;
    opacity: 1;
    border: 2px dashed var(--border-color);
    border-radius: var(--border-radius-small);
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      height: 75%;
      object-fit: contain;
      user-select: none;
      opacity: 0.95;
    }
    .btnHolder {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      color: #000;
      svg {
        width: 28px;
        height: 28px;
        margin-bottom: 8px;
      }
    }
    &:hover {
      .btnHolder {
        background-color: rgba(76, 83, 92, 0.267);
      }
    }
  }
}

.hint {
  margin-top: 4px;
  p {
    color: var(--color-text-alt-2);
    font-size: var(--font-size-7);
  }
}
.inputHide {
  display: none;
}
