.card {
  padding: 16px;
  &.cardDisable {
    .content {
      opacity: 0.5;
    }
  }
}
.badge {
  position: absolute;
  top: 16px;
  left: 16px;
  color: var(--color-text-white);
  background-color: var(--color-primary);
  border-radius: var(--border-radius-small);
  padding: 0 6px;
  &.regular {
    background-color: #bbfff4;
    color: var(--color-main-800);
  }
  &.one {
    background-color: #fff3df;
    // color: #ffbe4b;
    color: #f3ac31;
  }
  .badgeText {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    font-size: var(--font-size-7);
    font-weight: var(--font-weight-semibold);
    color: inherit;
  }
}
.content {
  display: flex;
  align-items: flex-start;
  align-items: center;
  margin-top: 16px;
  @media only screen and (max-width: 600px) {
    flex-direction: column-reverse;
    margin-top: 32px;
    .right {
      width: 100%;
      border-bottom: 1px dashed var(--border-color);
      padding-bottom: 14px;
      margin-bottom: 10px;
    }
  }
}
.left {
  flex-shrink: 0;
  text-align: center;
  h2 {
    color: var(--color-text-primary);
  }
  p {
    color: var(--color-text-alt-2);
    font-weight: var(--font-weight-normal);
  }
  .max {
    color: var(--color-text-helper);
    font-size: var(--font-size-7);
  }
}
.right {
  flex-grow: 1;
  padding-left: 20px;
  .name {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    h6 {
      font-size: var(--font-size-3);
      font-weight: var(--font-weight-bold);
      margin-left: 5px;
    }
    svg {
      transform: translateX(0) translateY(0) rotateZ(-40deg);
    }
  }
  .detail {
    color: var(--color-text-alt-2);
  }
  .space {
    margin-bottom: 2px;
  }
  .desc {
    // background-color: var(--color-background-alt-2);
    border-radius: var(--border-radius-small);
    border: 2px dashed var(--color-primary-light);
    padding: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: var(--font-weight-semibold);
  }
}

.label-code {
  background-color: #00594f;
  color: white;
  margin: 4px;
  border-radius: 5px;
  padding: 4px 10px 4px 10px;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px dashed var(--border-color);
  margin-top: 16px;
  padding-top: 14px;
  .code {
    color: var(--color-text-darker);
    font-weight: var(--font-weight-bold);
    margin-left: 4px;
  }
}
.dialogSteps {
  margin-left: 16px;
  ol,
  li {
    list-style: decimal;
  }
  & > :not(style) + :not(style) {
    margin-top: 16px;
  }
}
