.root {
  background-color: var(--color-background-alt);
  border-radius: var(--border-radius-medium);
}
.header {
  display: flex;
  align-items: center;
  padding: 16px;
  .headerContent {
    flex: 1 1 auto;
  }
  .headerTitle {
    display: block;
    font-size: var(--font-size-4);
    font-weight: var(--font-weight-semibold);
    line-height: 1.334;
  }
}
.divider {
  margin: 0px;
  flex-shrink: 0;
  background: rgba(34, 51, 84, 0.1);
  border: 0px;
  height: 1px;
}
.body {
  padding: 24px;
  @media screen and (max-width: 768px) {
    padding: 16px;
  }
}
.traveler {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 16px;
  // background-color: var(--color-background-alt);
  border-radius: var(--border-radius-medium);
  .wrap {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
  }
}
.travelerCover {
  flex-shrink: 0;
  .avatar {
    height: 64px;
    width: 64px;
    // border-radius: 6px;
    background-color: var(--color-background-alt-2);
    color: rgb(80, 97, 118);
  }
}
.travelerText {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
  padding: 8px 0;
  .link {
    &:hover {
      color: var(--color-main-800);
      text-decoration: underline;
    }
  }
  .score {
    display: inline-flex;
    align-items: center;
    margin-top: 2px;
    color: #f3a43d;
    svg {
      width: 18px;
      height: 18px;
      fill: #f3a43d;
    }
    span {
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-6);
      color: var(--color-text-alt-2);
      margin-left: 4px;
    }
  }
}
.item {
  display: flex;
  height: 100%;
  .wrap {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    padding: 10px 16px;
  }
}
.itemText {
  flex-shrink: 0;
  flex: 1 1 auto;
  min-width: 0px;
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  h6 {
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-normal);
    line-height: 1.2;
    letter-spacing: 0px;
    color: var(--color-text-alt-2);
  }
  .response {
    max-width: 260px;
    flex: 1 1 0%;
    margin-top: 0px;
    p {
      font-weight: bold;
      color: var(--color-graphite);
      font-size: var(--font-size-5);
      line-height: 1.6;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.observation {
  color: var(--color-status-info);
}
