.root {
  display: flex;
  flex-direction: row;
  padding: 0;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.cover {
  position: relative;
  width: 240px;
  height: 240px;
  flex-shrink: 0;
  border-right: 1px dashed var(--border-color-2);
  overflow: hidden;
  :global(.MuiAvatar-root) {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    background-color: transparent;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    user-select: none;
    margin: 0 auto;
  }
  .placeholder {
    width: 75%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    border-right: none;
    border-bottom: 1px dashed var(--border-color-2);
    .placeholder {
      width: 50%;
    }
  }
}
.content {
  position: relative;
  flex: 1 1 auto;
  min-width: 0px;
  padding: 16px;
  .flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 16px 0 0;
    .flex {
      width: auto;
      padding: 16px 16px;
    }
  }
}
.actions {
  flex-shrink: 0;
  width: 242px;
  padding: 16px 24px;
  border-left: 1px dashed var(--border-color-2);
  .flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    border-left: none;
    padding: 0;
    .flex {
      padding: 0 16px 16px;
      & > :not(style) + :not(style) {
        margin: 10px 0px 0px;
      }
    }
  }
}
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 56px;
  border-top: 1px solid var(--border-color-2);
  background-color: rgba(237, 246, 245, 0.7);
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 12px;
    padding: 12px 0;
  }
  &.created {
    background-color: #fff;
  }
  &.processing {
    background-color: #e8f4fd;
  }
  &.reject {
    background-color: #f5f7f8;
  }
}
.tools {
  position: absolute;
  top: 10px;
  right: 10px;
  :global(.MuiIconButton-root) {
    padding: 6px;
  }
}
.toolsButton {
  @media screen and (max-width: 768px) {
    background-color: var(--color-light-gray);
    box-shadow: 0px 0px 4px var(--color-primary);
    &:hover {
      background-color: inherit;
      color: var(--color-primary);
    }
  }
}
.alert {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-text-primary);
  font-weight: var(--font-weight-semibold);
  margin-left: 2px;
  margin-top: 8px;
  &.created {
    color: #2b353b;
  }
  &.processing {
    color: #0d3c61;
  }
}
.orderInfo {
  .date {
    display: block;
    color: var(--color-text-alt-2);
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-7);
    font-style: italic;
  }
  .title {
    display: flex;
    .shipping {
      margin-left: 8px;
    }
  }
  .id {
    margin-top: 4px;
    font-weight: var(--font-weight-normal);
    font-weight: var(--font-weight-semibold);
    color: var(--color-text-primary);
  }
  .nameLink {
    display: block;
  }
  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 4px 0px;
  }
  .obs {
    display: inline-block;
    color: var(--color-status-info);
    font-weight: var(--font-weight-semibold);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 4px;
  }
  .product {
    display: inline-flex;
    align-items: center;
    border-radius: var(--border-radius-small);
    border: 1px solid var(--border-color);
    padding: 4px 8px;
    .link {
      display: inline-block;
      max-width: 196px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:hover {
        color: var(--color-main);
        text-decoration: underline;
      }
    }
    .text {
      display: flex;
      align-items: flex-end;
      font-weight: var(--font-weight-semibold);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      & > :not(style) + :not(style) {
        margin-left: 4px;
      }
    }
  }
}
.statusBadge {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  background: transparent;
  border-radius: var(--border-radius-large);
  border: 1px dashed var(--color-purple);
  padding: 1px 8px;
  color: var(--color-purple);
  line-height: 20px;
  font-size: 13px;
  font-weight: var(--font-weight-semibold);
  text-transform: capitalize;
  margin: 14px 14px 0 0;
  user-select: none;
}
.priceInfo {
  position: absolute;
  bottom: 0;
  right: 0;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  background: var(--color-background-alt-1);
  border-radius: var(--border-radius-medium);
  padding: 6px 10px;
  margin: 0 16px 16px 0;
  & > :not(style) + :not(style) {
    margin-top: 4px;
  }
  .price {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .hint {
      min-width: 80px;
      font-size: var(--font-size-7);
      color: var(--color-text-alt-2);
    }
    .number {
      strong {
        font-size: var(--font-size-4);
        font-weight: var(--font-weight-bold);
        color: var(--color-text-primary);
      }
    }
  }
  @media screen and (max-width: 768px) {
    position: relative;
    margin: 16px 0 0;
  }
}
.offerInfo {
  .date {
    display: inline-block;
    color: var(--color-text-alt-2);
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-7);
    font-style: italic;
    margin-bottom: 8px;
  }
  .items {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    color: var(--color-text-alt-2);
    svg {
      flex-shrink: 0;
    }
    .info {
      margin-left: 4px;
    }
  }
}
.expireInfo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 4px;
  color: var(--color-status-alert);
  .text {
    margin-left: 4px;
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-7);
  }
}
.tag {
  display: inline-flex;
  padding: 3px 6px;
  margin-top: 5px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-small);
  font-size: var(--font-size-7);
}
.userInfo {
  padding: 0 16px;
  .badge {
    display: flex;
    align-items: center;
    :global(.MuiAvatar-root) {
      width: 34px;
      height: 34px;
    }
  }
  .name {
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-semibold);
  }
  .data {
    flex: 1 1 auto;
    margin-left: 8px;
  }
  .userScore {
    display: inline-flex;
    align-items: center;
    margin-top: 2px;
    color: #f3a43d;
    svg {
      width: 15px;
      height: 15px;
      fill: #f3a43d;
    }
    span {
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-7);
      color: var(--color-text-alt-2);
      margin-left: 4px;
    }
  }
}
