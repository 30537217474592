.root {
  margin-bottom: 30px;
}
.wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.box {
  flex-grow: 1;
}
.action {
  flex-shrink: 0;
  & > :not(style) + :not(style) {
    margin: 0px 0px 0px 10px;
  }
}
.title {
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-bold);
}
.subTitle {
  display: inline-block;
  margin-top: 8px;
  font-size: var(--font-size-5);
  color: var(--color-text-alt-2);
}
