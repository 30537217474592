.table {
  height: auto;
  overflow: hidden;
}
.header {
  background-color: rgb(243, 244, 247);
  border-top: 1px solid rgb(225, 227, 234);
}
.box {
  display: flex;
  align-items: center;
}
.image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: var(--border-radius-medium);
  overflow: hidden;
  user-select: none;
  img {
    width: 100%;
    height: 100%;
    text-align: center;
    object-fit: cover;
    color: transparent;
  }
}
.body {
  flex: 1 1 auto;
  margin-left: 16px;
  max-width: 240px;
  overflow: hidden;
}
.name {
  font-weight: var(--font-weight-normal);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.link {
  &:hover {
    color: var(--color-main-800);
    text-decoration: underline;
  }
}
.desc {
  color: var(--color-text-alt-2);
  font-style: italic;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.total {
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-bold);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
