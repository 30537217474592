.status {
  display: block;
  color: var(--color-text-helper);
  font-weight: var(--font-weight-semibold);
}
.statusComplete {
  color: var(--color-text-base);
}
.time {
  display: block;
  color: var(--color-text-alt-2);
  font-size: var(--font-size-6);
  margin-top: 2px;
}
.icon {
  position: relative;
  background-color: #ebf7fa;
  border: 2px solid #ebf7fa;
  z-index: 1;
  color: #fff;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  img {
    width: 34px;
    height: 34px;
    user-select: none;
  }
  &.iconMobile {
    // background-color: #bff3ea;
    width: 48px;
    height: 48px;
    background-color: #ebf7fa;
    img {
      width: 32px;
      height: 32px;
      object-fit: cover;
    }
  }
}
.activeState {
  border: 2px solid var(--color-main-800);
  box-shadow: 0 4px 10px 0 rgba(60, 219, 193, 0.5);
}
.coverCompleted {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(60, 219, 193, 0.45);
  border: 2px solid transparent;
  border-radius: 50%;
  .iconCompleted {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    background-color: #fff;
    border-radius: 50%;
    svg {
      color: var(--color-main);
    }
  }
}
.iconWrap {
  position: relative;
  min-width: 48px;
  margin-right: 16px;
  flex-shrink: 0;
}
.mobileText {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 42px;
  margin-top: 4px;
  margin-bottom: 4px;
}
.mobileTabs {
  position: relative;
}
.mobileList {
  position: relative;
  z-index: 99;
}
.mobileLine {
  position: absolute;
  top: 16px;
  left: 42px;
  height: 86%;
  width: 2px;
  z-index: 9;
  border-left: 2px dashed #51f8dc;
}
