.guv-font-size-1 {
  font-size: 36px !important;
  font-size: var(--font-size-1) !important;
}
.guv-font-size-2 {
  font-size: 29px !important;
  font-size: var(--font-size-2) !important;
}
.guv-font-size-3 {
  font-size: 23px !important;
  font-size: var(--font-size-3) !important;
}
.guv-font-size-4 {
  font-size: 18px !important;
  font-size: var(--font-size-4) !important;
}
.guv-font-size-5 {
  font-size: 16px !important;
  font-size: var(--font-size-5) !important;
}
.guv-font-size-6 {
  font-size: 14px !important;
  font-size: var(--font-size-6) !important;
}
.guv-font-size-7 {
  font-size: 12px !important;
  font-size: var(--font-size-7) !important;
}
.guv-font-size-base {
  font-size: 14px !important;
  font-size: var(--font-size-base) !important;
}

.guv-regular {
  font-weight: 400;
  font-weight: var(--font-weight-normal);
}
.guv-semibold,
.guv-strong {
  font-weight: 700;
  font-weight: var(--font-weight-semibold);
}
.guv-bold {
  font-weight: 900;
  font-weight: var(--font-weight-bold);
}
.guv-italic {
  font-style: italic;
}
.guv-underline {
  text-decoration: underline;
}
.guv-strikecase {
  text-decoration: line-through;
}
.guv-upcase {
  text-transform: uppercase;
}
.guv-capcase {
  text-transform: capitalize;
}
.guv-lowcase {
  text-transform: lowercase;
}
.guv-force-capitalize {
  text-transform: lowercase;
  display: inline-block;
  &:first-letter {
    text-transform: uppercase;
  }
}
.guv-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.guv-pointer {
  cursor: pointer;
}

.guv-text-base {
  color: var(--color-text-base);
}
.guv-text-darker {
  color: var(--color-text-darker);
}
.guv-text-primary {
  color: var(--color-text-primary);
}
.guv-text-primary-darker {
  color: var(--color-text-primary-darker);
}
.guv-text-alt {
  color: var(--color-text-alt);
}
.guv-text-alt-2 {
  color: var(--color-text-alt-2);
}
.guv-text-white {
  color: var(--color-text-white);
}
.guv-text-info {
  color: var(--color-status-info);
}
.guv-text-success {
  color: var(--color-status-success);
}
.guv-text-error {
  color: var(--color-status-error);
}
.guv-text-link {
  font-weight: var(--font-weight-semibold);
  &:hover {
    color: var(--color-text-primary);
    text-decoration: underline;
  }
}

.guv-background-body {
  background-color: var(--color-background-body) !important;
}
.guv-background-base {
  background-color: var(--color-background-base) !important;
}
.guv-background-alt {
  background-color: var(--color-background-alt) !important;
}
.guv-background-primary {
  background-color: var(--color-background-primary) !important;
}

.guv-no-decoration {
  color: inherit;
  text-decoration: none;
}

.guv-fill-none {
  fill: none !important;
}

.guv-main-container {
  min-height: calc(100vh - 128px);
  padding-top: 32px;
  padding-bottom: 32px;
  &.guv-main-container-disable-gutters {
    min-height: calc(100vh - 65px);
    padding-top: 0;
    padding-bottom: 0;
    @media only screen and (max-width: 600px) {
      min-height: calc(100vh - 57px);
    }
  }
  @media screen and (max-width: 1024px) {
    min-height: calc(100vh - 112px);
    padding-top: 24px;
    padding-bottom: 24px;
  }
  @media only screen and (max-width: 600px) {
    min-height: calc(100vh - 105px);
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.guv-paper {
  position: relative;
  background-color: var(--color-background-base);
  color: var(--color-text-base);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 0rem;
  box-shadow: none;
  overflow: hidden;
  z-index: 0;
  &--shadow {
    box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px,
      rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;
  }
  &--shadow-2 {
    // box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  }
  &--border {
    display: block;
    border: 1px solid rgba(135, 153, 160, 0.25);
  }
  &--rounded-small {
    border-radius: var(--border-radius-small);
  }
  &--rounded-medium {
    border-radius: var(--border-radius-medium);
  }
  &--rounded-large {
    border-radius: var(--border-radius-large);
  }
  &--hover {
    cursor: pointer;
    &:hover {
      background-color: var(--color-background-alt);
    }
  }
}

.guv-space {
  // display: inline-flex;
  display: flex;
  &.guv-space-vertical {
    flex-direction: column;
  }
  &.guv-space-align-center {
    align-items: center;
  }
  &.guv-space-align-start {
    align-items: flex-start;
  }
  &.guv-space-align-end {
    align-items: flex-end;
  }
  &.guv-space-align-baseline {
    align-items: baseline;
  }
}

.guv-table-no-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.guv-effect-grow {
  transform: none;
  &:hover {
    transform: scale(1.05) translateZ(0px);
  }
}

.guv-block,
.guv-show {
  display: block;
}
.guv-inline {
  display: inline;
}
.guv-inline-block {
  display: inline-block !important;
}
.guv-hide,
.guv-hidden {
  display: none !important;
}

.guv-flex {
  display: flex !important;
}
.guv-justify-content-center {
  justify-content: center !important;
}
.guv-align-items-center {
  align-items: center !important;
}
