.root {
  flex-grow: 1;
  height: 100%;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    background: rgba(43, 53, 59, 0.5);
    transition: color 0.2s ease 0s;
    border-radius: 7px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(43, 53, 59, 0.7);
  }
}
.content {
  padding: 24px;
}
.alert {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  background-color: var(--color-background-alt);
  border-bottom: 1px solid var(--border-color);
  color: var(--color-text-secondary);
  .text {
    flex-grow: 1;
    padding-left: 8px;
    font-weight: var(--font-weight-normal);
    letter-spacing: 0.0075em;
  }
}
