html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  // font-size: 62.5%;
  font-size: 100%;
  text-size-adjust: 100%;
}
body {
  background-color: #fff;
  background-color: var(--color-background-body);
  color: #2b353b;
  color: var(--color-text-base);
  font-size: 14px;
  font-size: var(--font-size-base);
  cursor: default;
}
img {
  max-width: 100%;
  // vertical-align: top;
}
a {
  color: inherit;
  text-decoration: none;
}
h1,
h2 {
  font-weight: 700;
  font-weight: var(--font-weight-bold);
}
h3,
h4,
strong {
  font-weight: 700;
  font-weight: var(--font-weight-semibold);
}
em {
  font-style: italic;
}
.h0 {
  font-size: 45px;
  font-size: var(--font-size-0);
}
.h1,
h1 {
  font-size: 36px;
  font-size: var(--font-size-1);
}
.h2,
h2 {
  font-size: 29px;
  font-size: var(--font-size-2);
}
.h3,
h3 {
  font-size: 23px;
  font-size: var(--font-size-3);
}
.h4,
h4 {
  font-size: 18px;
  font-size: var(--font-size-4);
}
.h5,
h5 {
  font-size: 16px;
  font-size: var(--font-size-5);
}
.h6,
h6,
p {
  font-size: 14px;
  font-size: var(--font-size-base);
}
.h7 {
  font-size: 10px;
  font-size: var(--font-size-7);
}
