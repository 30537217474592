.root {
  padding: 16px 16px;
}
.wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.text {
  display: flex;
  align-items: center;
  margin: 4px 0;
  color: var(--color-status-error);
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-6);
  svg {
    margin-right: 2px;
  }
}
.hint {
  font-size: var(--font-size-7);
  font-weight: var(--font-weight-semibold);
  color: var(--color-text-alt-2);
}
