.root {
  position: relative;
  width: 272px;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 8px;
  }
}
.dates {
  display: flex;
  justify-content: space-between;
  position: sticky;
  margin-bottom: 8px;
  font-weight: var(--font-weight-bold);
  color: var(--color-text-alt-2);
  z-index: 100;
}
.plane {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  svg {
    color: #00594f;
    fill: rgb(158, 191, 188);
  }
}
.hint {
  display: inline-block;
  color: var(--color-text-alt-2);
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-7);
  font-style: italic;
  margin-top: 4px;
}
