.table {
  height: auto;
  overflow: hidden;
}
.header {
  background-color: rgb(243, 244, 247);
  border-top: 1px solid rgb(225, 227, 234);
}
.name {
  display: inline-flex;
  align-items: center;
  span {
    font-weight: var(--font-weight-semibold);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.link {
  display: inline-block;
  max-width: 176px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 4px;
  &:hover {
    color: var(--color-main-800);
    font-weight: var(--font-weight-bold);
    text-decoration: underline;
  }
}
.total {
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-bold);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
