.root {
  display: flex;
  flex-direction: row;
  padding: 0;
  @media screen and (max-width: 768px) {
    & {
      flex-direction: column;
      align-items: flex-start;
      .cover {
        width: 100%;
        img {
          object-fit: contain;
        }
      }
      .content {
        width: 100%;
      }
      .actions {
        width: 100%;
        border-top: 1px dashed var(--border-color-2);
        border-left: none;
        .actionsWrap {
          padding: 16px;
        }
      }
    }
  }
}
.cover {
  width: 240px;
  height: 240px;
  flex-shrink: 0;
  border-right: 1px solid var(--border-color-2);
  overflow: hidden;
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
  .placeholder {
    width: 65%;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    user-select: none;
    margin: 0 auto;
  }
}
.content {
  position: relative;
  flex: 1 1 auto;
  min-width: 0px;
  .contentWrap {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    // padding: 16px 0 16px 16px;
    width: 100%;
    height: 100%;
  }
}
.orderInfo {
  padding: 12px 16px 0;
}
.userInfo {
  margin-top: 24px;
  padding: 0 16px 16px 16px;
}
.actions {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 290px;
  border-left: 1px dashed var(--border-color-2);
  .actionsWrap {
    padding: 16px 24px;
  }
}
.date {
  color: var(--color-text-alt-2);
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-7);
  font-style: italic;
}
.name {
  // max-width: 320px;
  margin-top: 2px;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    color: var(--color-primary-darker);
    text-decoration: underline;
  }
}
.linkUrl {
  border-radius: var(--border-radius-small);
  border: 1px solid var(--border-color);
  padding: 4px 8px;
  margin-bottom: 4px;
  display: inline-block;
  max-width: 196px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    color: var(--color-main);
    text-decoration: underline;
  }
}
.obs {
  color: var(--color-status-info);
  font-weight: var(--font-weight-semibold);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 6px;
}
.chip {
  display: block;
}
.link {
  display: flex;
  align-items: center;
  padding: 2px 8px;
  background-color: rgba(34, 51, 84, 0.05);
  border-radius: var(--border-radius-small);
  svg {
    font-size: 14px;
    margin-right: 4px;
  }
  .text {
    font-size: var(--font-size-6);
    line-height: 22.4px;
  }
  &:hover {
    background-color: rgba(34, 51, 84, 0.15);
    .text,
    svg {
      color: var(--color-text-primary-darker);
    }
  }
}
.avatar {
  display: flex;
  align-items: center;
  :global {
    .MuiAvatar-root {
      width: 38px;
      height: 38px;
      margin-right: 8px;
    }
  }
  .userName {
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-semibold);
  }
  svg {
    width: 20px;
    height: 20px;
  }
}
.userScore {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 2px;
  color: #f3a43d;
  svg {
    width: 17px;
    height: 17px;
    fill: #f3a43d;
  }
  span {
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-6);
    color: var(--color-text-alt-2);
  }
}
.firstOffer {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  background: rgba(224, 241, 255, 0.75);
  border-radius: var(--border-radius-small);
  padding: 2px 8px;
  svg {
    width: 20px;
    height: 20px;
    color: #5ab3fb;
  }
  p {
    margin-left: 4px;
    font-weight: var(--font-weight-semibold);
    line-height: 20px;
    color: #5ab3fb;
  }
}
.destiny {
  color: var(--color-text-alt-2);
  font-weight: var(--font-weight-normal);
  margin-bottom: 2px;
}
.price {
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 8px;
  h5 {
    margin-bottom: 2px;
  }
  strong {
    font-size: var(--font-size-3);
    font-weight: var(--font-weight-bold);
  }
  p {
    font-size: var(--font-size-7);
    color: var(--color-text-alt-2);
  }
}
