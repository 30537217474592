.itemLink {
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  color: inherit;
  text-decoration: none;
  .wand {
    width: 20px;
    height: 20px;
    padding: 2px;
    color: var(--color-text-base);
  }
}
.userPopoverPaper {
  width: 240px;
  margin-top: 10px;
  margin-left: 2px;
  overflow: visible !important;
  border: 1px solid rgba(145, 158, 171, 0.08);
  box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.24) 0px 12px 24px 0px !important;
}
.arrow {
  content: '';
  position: absolute;
  top: -7px;
  right: 12px;
  width: 12px;
  height: 12px;
  border-radius: 0px 0px 4px;
  background: var(--color-background-base);
  transform: rotate(-135deg);
  border-right: 1px solid rgba(145, 158, 171, 0.12);
  border-bottom: 1px solid rgba(145, 158, 171, 0.12);
  z-index: 1500;
}
.profileBox {
  margin-top: 12px;
  margin-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
  h6 {
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-semibold);
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  p {
    font-size: var(--font-size-6);
    line-height: 1.57143;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--color-text-alt-2);
  }
}
.content {
  padding: 8px 0;
}
.menuActions {
  padding: 8px 0px;
}
.menuItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  text-align: left;
  font-weight: 500;
  font-size: var(--font-size-6);
  line-height: 1.57143;
  padding: 8px 20px;
  cursor: pointer;
  user-select: none;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  .wand {
    width: 20px;
    height: 20px;
    padding: 2px;
  }
  &:hover {
    text-decoration: none;
    background-color: rgba(145, 158, 171, 0.08);
  }
}
.footer {
  padding: 8px;
}
.footerButton {
  font-weight: var(--font-weight-bold) !important;
}
.divider {
  margin: 0px;
  flex-shrink: 0;
  border-width: 0px 0px thin;
  border-style: solid;
  border-color: rgba(145, 158, 171, 0.24);
}
.badge {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;
  background-color: var(--color-status-error);
  color: #fff;
  border-radius: 10px;
}
