:root {
  box-sizing: border-box;
}

.main {
  padding: 20px;
  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    div {
      width: 100%;
    }
    .announce {
      margin: 0px 10px;
      font-size: var(--font-size-5);
    }
    .scoring {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      & > h2 {
        color: var(--color-blue);
      }
      p {
        font-weight: bold;
      }
    }
    // Progress
    .progress {
      @media screen and (max-width: 768px) {
        margin-bottom: 10px;
      }
    }
  }
  .footer {
    margin-top: 10px;
    p {
      text-align: center;
    }
    a {
      cursor: pointer;
      color: var(--color-status-info);
      text-decoration: underline;
      font-weight: bold;
    }
  }
  
}
