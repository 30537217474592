.title {
  display: inline-flex;
  align-items: center;
}
.badgeOne {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 27px;
  padding: 0 10px;
  margin-left: 8px;
  background-color: #ffbe4b;
  border-radius: var(--border-radius-small);
  img {
    width: 38px;
    display: block;
    filter: brightness(0) invert(1);
    user-select: none;
  }
}
.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 27px;
  padding: 0 10px;
  margin-left: 6px;
  border-radius: var(--border-radius-small);
  font-size: 50%;
  .text {
    font-weight: var(--font-weight-semibold);
    text-transform: capitalize;
  }
  &.badgeOne {
    background-color: #ffbe4b;
    img {
      width: 38px;
      display: block;
      filter: brightness(0) invert(1);
      user-select: none;
    }
  }
  &.office {
    // background-color: #fffde7;
    box-shadow: inset 0px 0px 0px 1px #fdd835;
    color: #fbc02d;
    cursor: pointer;
  }
}
