.title {
  display: flex;
  align-items: center;
}
.badgeOne {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 26px;
  padding: 0 10px;
  margin-left: 16px;
  background-color: #ffbe4b;
  border-radius: var(--border-radius-small);
  img {
    width: 38px;
    display: block;
    filter: brightness(0) invert(1);
    user-select: none;
  }
}
.imageDelivery {
  :global(.MuiAvatar-root) {
    height: 240px;
    width: 240px;
    border-radius: 8px;
    margin: 0 auto;
    background-color: var(--color-background-alt);
    .holder {
      width: 80%;
    }
  }
}
.courier {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 26px;
  padding: 0 10px;
  margin-left: 10px;
  border-radius: var(--border-radius-small);
  box-shadow: inset 0px 0px 0px 1px #fdd835;
  color: #fbc02d;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-6);
}
