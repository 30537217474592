.root {
  display: flex;
  align-items: flex-start;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.cover {
  position: relative;
  flex-shrink: 0;
  width: 240px;
  height: 240px;
  overflow: hidden;
  border-right: 1px dashed var(--border-color-2);
  &.holder {
    background-color: var(--color-background-alt);
    img {
      width: 75%;
    }
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    user-select: none;
    margin: 0 auto;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    border-bottom: 1px dashed var(--border-color-2);
    &.holder {
      img {
        width: 50%;
      }
    }
  }
}
.body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 240px;
  height: auto;
  width: 100%;
  @media screen and (max-width: 768px) {
    height: auto;
    width: 100%;
  }
}
.top {
  padding: 16px;
}
.footer {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
  }
}
.badge {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 5px 10px;
  color: #0cad92;
  background-color: #cefaf3;
  border-radius: var(--border-radius-small);
  box-shadow: inset 0px 0px 0px 1px var(--color-main-800);
  font-size: var(--font-size-6);
  font-weight: var(--font-weight-semibold);
  text-transform: lowercase;
  &::first-line {
    text-transform: capitalize;
  }
}
.time {
  display: inline-block;
  color: var(--color-text-alt-2);
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-7);
  font-style: italic;
  margin-bottom: 4px;
}
.title {
  font-weight: var(--font-weight-bold);
  color: var(--color-text-primary);
  font-size: var(--font-size-4);
  margin-bottom: 3px;
}
.name {
  color: var(--color-text-base);
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-semibold);
}
.provincia {
  display: flex;
  // align-items: center;
  margin-top: 8px;
  svg {
    color: var(--color-text-alt-2);
  }
  .provinciaKey {
    margin-left: 4px;
    display: inline-block;
    margin-right: 8px;
  }
  .provinciaLink {
    font-weight: var(--font-weight-semibold);
    color: var(--color-main-800);
    &:hover {
      color: var(--color-main-800);
      text-decoration: underline;
    }
  }
}
.delivery {
  margin-top: 16px;
  font-size: var(--font-size-5);
  & > :not(style) + :not(style) {
    margin-top: 4px;
  }
  .deliveryTitle {
    font-size: var(--font-size-5);
  }
  .deliveryRes {
    font-weight: var(--font-weight-semibold);
  }
  .format {
    display: inline-block;
    text-transform: lowercase;
    &::first-line {
      text-transform: capitalize;
    }
  }
}
.orderPrice {
  min-width: 160px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  background-color: rgba(237, 246, 245, 0.7);
  border-radius: var(--border-radius-medium);
  cursor: pointer;
  .priceTotal {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-4);
  }
  .priceHint {
    margin-left: 2px;
    color: var(--color-text-alt-2);
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-7);
    font-style: italic;
  }
  .link {
    color: #fff;
    background: var(--color-primary);
    margin-right: 8px;
    svg {
      width: 18px;
      height: 18px;
    }
  }
}
