.header {
  display: flex;
  align-items: center;
  padding: 16px;
  .headerContent {
    flex: 1 1 auto;
  }
  .headerTitle {
    display: block;
    font-size: var(--font-size-4);
    font-weight: var(--font-weight-semibold);
    line-height: 1.334;
  }
}
.divider {
  margin: 0px;
  flex-shrink: 0;
  background: rgba(34, 51, 84, 0.1);
  border: 0px;
  height: 1px;
}
.partner {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: var(--border-radius-small);
  border: 1px dashed var(--color-main);
  margin: 0 24px;
  color: var(--color-primary);
  .partnerWrap {
    display: flex;
    align-items: center;
    padding: 6px 10px;
  }
  p {
    margin-left: 6px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: none;
    margin-bottom: 16px;
  }
}
.traveler {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 12px 24px 16px;
  padding: 10px 16px;
  background-color: var(--color-background-alt);
  border-radius: var(--border-radius-medium);
}
.travelerCover {
  flex-shrink: 0;
  .avatar {
    height: 64px;
    width: 64px;
    // border-radius: 6px;
    background-color: var(--color-background-alt-2);
    color: rgb(80, 97, 118);
  }
}
.travelerText {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
  padding: 8px 0;
  .link {
    &:hover {
      color: var(--color-main-800);
      text-decoration: underline;
    }
  }
  .score {
    display: inline-flex;
    align-items: center;
    margin-top: 2px;
    color: #f3a43d;
    svg {
      width: 18px;
      height: 18px;
      fill: #f3a43d;
    }
    span {
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-6);
      color: var(--color-text-alt-2);
      margin-left: 4px;
    }
  }
}
.list {
  list-style: none;
  margin: 0px;
  padding: 0px;
  position: relative;
}
.item {
  position: relative;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  padding: 12px 24px;
}
.itemText {
  flex: 1 1 auto;
  min-width: 0px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  h6 {
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-normal);
    line-height: 1.2;
    letter-spacing: 0px;
    color: var(--color-text-alt-2);
  }
  .response {
    flex: 1 1 0%;
    margin-top: 0px;
    p {
      font-weight: bold;
      color: var(--color-graphite);
      font-size: var(--font-size-5);
      line-height: 1.6;
    }
  }
}
.obs {
    display: inherit;
    color: var(--color-status-info);
    font-weight: var(--font-weight-semibold);
    margin-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
