.card {
  // padding: 24px;
}
.header {
  display: flex;
  align-items: center;
  padding: 16px;
  .headerContent {
    flex: 1 1 auto;
  }
  .headerTitle {
    display: block;
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-semibold);
    line-height: 1.2;
  }
  .headerCountry {
    display: inline-flex;
    align-items: center;
    .flag {
      width: 24px;
      height: 24px;
    }
    .name {
      margin-left: 8px;
      font-size: var(--font-size-5);
      font-weight: var(--font-weight-semibold);
      color: var(--color-text-alt-2);
    }
  }
}
.divider {
  margin: 0px;
  flex-shrink: 0;
  background: rgba(34, 51, 84, 0.1);
  border: 0px;
  height: 1px;
}
.body {
  padding: 24px;
}
