.appbar {
  position: relative !important;
  svg {
    color: var(--color-text-white);
  }
}
.appbarTitle {
  flex: 1;
  margin-left: 8px;
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-bold);
  line-height: 1.6;
  letter-spacing: 0.0075em;
}
.notificationPaper {
  width: 360px;
  margin-top: 10px;
  margin-left: 2px;
  overflow: visible !important;
  border: 1px solid rgba(145, 158, 171, 0.08);
  box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.24) 0px 12px 24px 0px !important;
}
.arrow {
  content: '';
  position: absolute;
  top: -7px;
  right: 12px;
  width: 12px;
  height: 12px;
  border-radius: 0px 0px 4px;
  background: var(--color-background-base);
  transform: rotate(-135deg);
  border-right: 1px solid rgba(145, 158, 171, 0.12);
  border-bottom: 1px solid rgba(145, 158, 171, 0.12);
  z-index: 1500;
}
.title {
  display: flex;
  align-items: center;
  padding: 16px 20px;
  svg {
    width: 20px !important;
    height: 20px !important;
  }
}
.titleText {
  flex-grow: 1;
  h6 {
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-bold);
    line-height: 1.5;
  }
  p {
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-6);
    line-height: 1.57143;
    color: rgb(99, 115, 129);
  }
}
.content {
  flex-grow: 1;
  height: auto;
  overflow-x: auto;
  height: 340px;
  // height: 512px;
  @media only screen and (min-width: 1024px) {
    height: auto;
    max-height: 512px;
  }
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    background: rgba(43, 53, 59, 0.5);
    transition: color 0.2s ease 0s;
    border-radius: 7px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(43, 53, 59, 0.7);
  }
}
.link {
  position: relative;
  display: block;
  padding: 12px 20px;
  color: inherit;
  text-decoration: none;
  overflow: hidden;
  h6 {
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-6);
    color: #637381;
    line-height: 1.57143;
    strong {
      color: var(--color-text-base);
    }
  }
  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
  &:hover {
    text-decoration: none;
    background-color: rgba(145, 158, 171, 0.08);
  }
}
.linkActive {
  &::before {
    content: '.';
    background: var(--color-main);
    color: var(--color-main);
    border-radius: 4px;
    position: absolute;
    text-align: center;
    width: 6px;
    left: 0px;
    height: 100%;
    top: 0px;
  }
}
.linkTime {
  display: flex;
  align-items: center;
  margin-top: 4px;
  font-size: var(--font-size-7);
  line-height: 1.5;
  color: var(--color-text-helper);
  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
}
.actions {
  padding: 8px;
}
.actionsButton {
  font-weight: var(--font-weight-bold) !important;
}
.divider {
  margin: 0px;
  flex-shrink: 0;
  border-width: 0px 0px thin;
  border-style: solid;
  border-color: rgba(145, 158, 171, 0.24);
}
