.root {
  display: flex;
  margin-bottom: 24px;
}
.wrap {
  display: flex;
  &.owner {
    margin-left: auto;
  }
}
.profile {
  :global {
    .MuiAvatar-root {
      width: 32px;
      height: 32px;
    }
  }
}
.content {
  margin-left: 16px;
}
.time {
  display: flex;
  margin-bottom: 6px;
  font-size: var(--font-size-7);
  color: var(--color-text-alt-2);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.owner {
    justify-content: flex-end;
  }
}
.text {
  background-color: rgb(244, 246, 248);
  border-radius: 8px;
  padding: 12px;
  &.owner {
    background-color: rgb(160, 246, 232);
  }
}
.msg {
  font-size: var(--font-size-6);
  font-weight: var(--font-weight-thin);
  line-height: 1.57143;
}
