.fullWidth {
  width: 100%;
}
.input {
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  white-space: nowrap;
  overflow: hidden;
  &[type='radio']:checked + .tile {
    border-color: var(--color-primary);
    box-shadow: 0 5px 10px rgba(#000, 0.1);
    color: var(--color-primary);
    &:before {
      transform: scale(1);
      opacity: 1;
      background-color: var(--color-primary);
      border-color: var(--color-primary);
    }
    .icon,
    .label {
      color: var(--color-primary);
    }
  }
  &[type='radio']:focus + .tile {
    border-color: var(--color-primary);
    box-shadow: 0 5px 10px rgba(#000, 0.1), 0 0 0 4px #07978678;
    &:before {
      transform: scale(1);
      opacity: 1;
    }
  }
}
.tile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 8rem;
  min-height: 3rem;
  border-radius: var(--border-radius-medium);
  border: 2px solid var(--color-text-alt);
  background-color: #fff;
  padding: 0 32px;
  /*
  box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;
  */
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
  @media (max-width: 768px) {
    padding: 0 16px 0 32px;
  }
  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    border: 2px solid #b5bfd9;
    background-color: #fff;
    border-radius: 50%;
    top: 0.25rem;
    left: 0.25rem;
    opacity: 0;
    transform: scale(0);
    transition: 0.25s ease;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  &:hover {
    border-color: var(--color-primary);
    &:before {
      transform: scale(1);
      opacity: 1;
    }
  }
}
.label {
  flex-grow: 1;
  display: flex;
  color: var(--color-text-base);
  font-weight: var(--font-weight-normal);
  transition: 0.375s ease;
  // text-align: center;
  overflow: hidden;
  &.center {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
