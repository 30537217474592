.root {
  display: flex;
  flex-direction: column;
  border-bottom: 1px dashed var(--border-color-2);
  margin-bottom: 14px;
  .wrap {
    display: flex;
    flex-direction: row;
    padding: 16px;
    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
  }
}
.cover {
  position: relative;
  width: 112px;
  height: 112px;
  flex-shrink: 0;
  border-radius: var(--border-radius-medium);
  overflow: hidden;
  a {
    display: block;
    width: 100%;
    height: 100%;
    background: var(--color-background-alt);
  }
  :global(.MuiAvatar-root) {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    :global(.MuiAvatar-root) {
      img {
        object-fit: contain;
      }
    }
  }
}
.content {
  flex: 1 1 auto;
  min-width: 0px;
  padding: 0 0 0 16px;
  .sheath {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    height: 100%;
  }
  .name {
    display: block;
    margin-bottom: 4px;
    h4 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:hover {
        color: var(--color-primary-darker);
        text-decoration: underline;
      }
    }
  }
  .obs {
    display: block;
    color: var(--color-status-info);
    font-weight: var(--font-weight-semibold);
    margin-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media screen and (max-width: 600px) {
    // border-top: 1px dashed var(--border-color-2);
    border-left: none;
    padding: 16px 0 0 0;
  }
}
.list {
  margin-top: 8px;
}
.shop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-medium);
  .shopTitle {
    font-weight: var(--font-weight-semibold);
    &:hover {
      color: var(--color-text-primary-darker);
      text-decoration: underline;
    }
  }
  .shopInfo {
    display: flex;
    align-items: center;
    line-height: 1;
    gap: 8px;
    .link {
      color: #fff;
      background: var(--color-primary);
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
  &:hover {
    background-color: var(--color-background-alt-1);
  }
}
