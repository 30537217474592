.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.emptyChat {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .emptyCover {
    display: block;
    max-width: 208px;
    user-select: none;
    margin-top: -20px;
  }
  .emptyText {
    margin-top: 10px;
    color: var(--color-text-alt-2);
  }
}
.divider {
  margin: 0px;
  flex-shrink: 0;
  border-width: 0px 0px thin;
  border-style: solid;
  border-color: rgba(145, 158, 171, 0.24);
}
