.scoreCriteria {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  & > div {
    width: 100%;
    margin: 0px 20px;
    @media screen and (max-width: 768px) {
      margin: 0px 0px;
    }
  }
  .criteria {
    h4 {
      font-weight: var(--font-weight-bold);
    }
    p {
      margin-top: 6px;
      color: var(--color-text-alt-2);
    }
  }
  .criteriaMetricsEmpty {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & :first-child {
      color: black;
      opacity: 70%;
      font-weight: lighter;
      font-size: var(--font-size-7);
    }
    & :last-child {
      color: var(--color-blue);
      opacity: 50%;
      font-weight: bold;
    }
    & .constraint {
      color: var(--color-warning);
      font-size: var(--font-size-7);
      font-weight: var(--font-weight-bold);
    }
  }
  .criteriaMetrics {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    & :first-child {
      color: black;
    }
    & :last-child {
      color: var(--color-blue);
      font-weight: bold;
    }
  }
  .criteriaProgressEmpty {
    margin: 0px 20px;
    :global {
      .MuiLinearProgress-root {
        height: 6px;
        border-radius: 4px;
      }
      .MuiLinearProgress-colorPrimary {
        background-color: var(--color-primary)  !important;
        opacity: 20%;
      }
    }
  }
  .criteriaProgress {
    margin: 0px 20px;
    :global {
      .MuiLinearProgress-root {
        height: 6px;
        border-radius: 4px;
      }
      .MuiLinearProgress-barColorPrimary {
        background-color: #444444 !important;
      }
    }
  }
}
