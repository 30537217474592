.root {
  padding: 8px 16px 8px 16px;
  // border-bottom: 1px dashed var(--border-color);
}
.wrap {
  display: flex;
  flex-direction: column;
  padding: 12px;
  // background-color: var(--color-background-alt);
}
.content {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
}
.userInfo {
  flex: 1 1 auto;
  display: inline-flex;
  align-items: center;
}
.avatar {
  width: 48px;
  height: 48px;
}
.user {
  flex: 1 1 auto;
  margin-left: 8px;
  .name {
    max-width: 120px;
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-semibold);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .userScore {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    margin-top: 2px;
    color: #f3a43d;
    svg {
      width: 17px;
      height: 17px;
      fill: #f3a43d;
    }
    span {
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-6);
      color: var(--color-text-alt-2);
    }
  }
  button {
    padding: 4px;
  }
}
.message {
  margin-top: 8px;
  color: var(--color-text-primary);
  font-weight: var(--font-weight-semibold);
  border-radius: var(--border-radius-small);
  background-color: #d7f5f0;
  padding: 8px;
}
