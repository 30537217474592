.title {
  display: inline-flex;
  align-items: center;
}
.badgeOne {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 27px;
  padding: 0 10px;
  margin-left: 8px;
  background-color: #ffbe4b;
  border-radius: var(--border-radius-small);
  img {
    width: 38px;
    display: block;
    filter: brightness(0) invert(1);
    user-select: none;
  }
}
.courier {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 27px;
  padding: 0 10px;
  margin-left: 16px;
  border-radius: var(--border-radius-small);
  box-shadow: inset 0px 0px 0px 1px #fdd835;
  color: #fbc02d;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-6);
}
.hintOffers {
    color: var(--color-text-alt-2);
    padding: 10px;
    border: 1px solid var(--color-gray);
    border-radius: 5px;
    .small {
      font-size: 0.9em;
      opacity: 0.85; 
    }
    &.warning {
      border: 1px solid var(--color-warning) !important;
      color: var(--color-warning);
    }
    &.danger {
      border: 1px solid var(--color-status-error-expired) !important;
      color: var(--color-status-error-expired);
    }
}
.cardExpressFeeSection {
  padding: 10px;
  border-radius: var(--border-radius-medium);
  border: 1px solid rgba(135, 153, 160, 0.25);
  display: flex;
  align-items: center;
  background-color: #333 !important;
  color: var(--color-express-fee) !important;

  .content {
    display: flex;
    align-items: center;
  }
  .icon {
    display: flex;
    opacity: 0.9;
    padding: 8px 0;
    font-size: 22px;
    margin-right: 12px;
    color: var(--color-express-fee);
  }
  .text {
    padding: 8px 0;
    line-height: 24px;
  }
  .action {
    flex-shrink: 0;
    margin-left: auto;
    padding: 8px 0;
    button {
      width: inherit;
    }
  }
}
