.header {
  display: flex;
  align-items: center;
  padding: 16px;
  .headerContent {
    flex: 1 1 auto;
  }
  .headerTitle {
    display: block;
    font-size: var(--font-size-4);
    font-weight: var(--font-weight-semibold);
    line-height: 1.334;
  }
}
.divider {
  margin: 0px;
  flex-shrink: 0;
  background: rgba(34, 51, 84, 0.1);
  border: 0px;
  height: 1px;
}
.body {
  padding: 24px;
  @media screen and (max-width: 768px) {
    padding: 16px;
  }
}
.hint {
  .text {
    color: var(--color-text-helper);
    font-size: var(--font-size-6);
    font-weight: var(--font-weight-normal);
  }
}
.user {
  display: flex;
  align-items: center;
  :global(.MuiAvatar-root) {
    width: 32px;
    height: 32px;
    // background-color: var(--color-background-alt-2);
  }
  .text {
    padding-left: 8px;
  }
}
.avatar {
  &:global(.MuiAvatar-root) {
    width: 32px;
    height: 32px;
    // background-color: var(--color-background-alt-2);
  }
}
.validate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-background-alt-3);
  border-radius: var(--border-radius-small);
  padding: 16px;
  .title {
    display: flex;
    align-items: centers;
    p {
      font-size: var(--font-size-5);
      padding-left: 4px;
    }
  }
  .number {
    color: var(--color-primary);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-5);
  }
}
