.root {
  padding: 16px;
  @media (min-width: 600px) {
    height: 112px;
    padding: 16px;
  }
}
.content {
  width: 100%;
  @media (min-width: 600px) {
    display: flex;
    height: 100%;
  }
}
.left {
  flex-grow: 1;
  @media (min-width: 600px) {
    display: flex;
  }
}
.right {
  padding: 0;
  margin: 0;
  @media (min-width: 600px) {
    // border-left: 1px dashed var(--border-color);
    margin: 0 0 0 16px;
    // padding: 0 0 0 16px;
    width: 273px;
  }
}
.time {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 64px;
  margin-right: 16px;
  padding: 10px 12px;
  background-color: var(--color-background-alt-1);
  border-radius: var(--border-radius-medium);
  h5 {
    font-size: var(--font-size-4);
    font-weight: var(--font-weight-semibold);
    line-height: 1.5;
    // font-family: Inter, sans-serif;
    color: var(--color-text-alt-3);
  }
  P {
    font-size: var(--font-size-6);
    font-weight: var(--font-weight-normal);
    line-height: 1.6;
    // font-family: Inter, sans-serif;
    color: var(--color-text-alt-3);
    &:first-letter {
      text-transform: uppercase;
    }
  }
  @media (max-width: 600px) {
    display: none;
  }
}
.info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  overflow: hidden;
}
.number {
  margin: 0px 0px 2px;
  font-size: var(--font-size-7);
  color: var(--color-text-alt-2);
  font-style: italic;
}
.title {
  margin: 0px 0px 4px;
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-semibold);
  color: var(--color-text-primary-darker);
  line-height: 1.235;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.extraInfo {
  display: inline-flex;
  margin-top: 10px;
  color: var(--color-text-primary);
  .text {
    padding-left: 4px;
  }
}
.subtitle {
  margin: 0px 0px 4px;
  font-size: var(--font-size-6);
  font-weight: var(--font-weight-semibold);
  color: var(--color-dark-gray);
  line-height: 1.235;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.actions {
  justify-content: end;
}
.footer {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
}
.price {
  display: flex;
  align-items: center;
  // padding: 10px 10px;
  padding: 0;
  border-radius: var(--border-radius-medium);
  .totalPrice {
    font-weight: var(--font-weight-bold);
    margin-right: 4px;
    &.primary {
      color: var(--color-text-primary);
    }
  }
  .totalHint {
    display: inline-block;
    font-size: var(--font-size-7);
    font-weight: var(--font-weight-semibold);
    color: var(--color-text-alt-2);
    font-style: italic;
    &.primary {
      color: var(--color-text-primary);
      opacity: 0.85;
    }
  }
  .space {
    margin: 0 6px;
  }
}
.card {
  position: relative;
  height: 100%;
  .wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .cardImage {
    position: absolute;
    bottom: 2px;
    right: 0px;
    width: 60px;
    height: 40px;
    img {
      width: 80%;
      height: 80%;
      object-fit: cover;
      user-select: none;
    }
  }
  .cardContent {
    padding: 10px 16px;
    @media (min-width: 600px) {
        .bankResponsive {
            display: none;
        }
    }
    .bankType {
      color: var(--color-text-alt-2);
      font-size: var(--font-size-6);
      font-weight: var(--font-weight-normal);
      margin-bottom: 2px;
    }
    .bankUser {
      line-height: 1.6;
      margin-bottom: 2px;
    }
    .bankNum {
      font-size: var(--font-size-6);
      font-weight: var(--font-weight-semibold);
    }
  }
  .cardHolder {
    padding: 10px 16px;
  }
  @media (max-width: 600px) {
    .wrap {
      flex-direction: column-reverse;
    }
    .cardContent {
      padding: 10px;
    }
    .cardImage {
      display: none;
      position: initial;
      padding: 0 6px 6px;
    }
  }
}
