.offerSumary {
  .user {
    flex: 1 1 auto;
    display: inline-flex;
    align-items: center;
  }
  .avatar {
    width: 36px;
    height: 36px;
  }
  .userInfo {
    flex: 1 1 auto;
    margin-left: 8px;
  }
  .name {
    max-width: 120px;
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-semibold);
  }
  .userScore {
    display: inline-flex;
    align-items: center;
    margin-top: 2px;
    color: #f3a43d;
    svg {
      width: 15px;
      height: 15px;
      fill: #f3a43d;
    }
    span {
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-7);
      color: var(--color-text-alt-2);
      margin-left: 4px;
    }
  }
  button {
    padding: 4px;
  }
}
.userOffer {
  background-color: var(--color-background-base);
  .content {
    display: flex;
    flex-direction: column;
    padding: 16px;
    .expire {
      color: var(--color-text-alt-2);
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-7);
      font-style: italic;
      margin-bottom: 5px;
    }
    .calification {
      display: flex;
      align-items: center;
      color: #ffa000;
      background-color: rgb(255, 244, 229);
      border-radius: var(--border-radius-small);
      padding: 8px 16px;
      margin-bottom: 16px;
      .value {
        display: inline-block;
        font-size: var(--font-size-4);
        font-weight: var(--font-weight-semibold);
        margin-left: 4px;
      }
      .hint {
        color: var(--color-text-alt-2);
        font-weight: var(--font-weight-semibold);
        font-size: var(--font-size-6);
        font-style: italic;
        margin-right: 10px;
      }
    }
    .offerInfo {
      margin-bottom: 10px;
      h5 {
        color: var(--color-text-base);
        font-weight: var(--font-weight-normal);
        line-height: 20px;
      }
    }
    .statsGroup {
      display: flex;
      flex-direction: column;
      & > :not(style) + :not(style) {
        margin-top: 16px;
      }
    }
    .stats {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      .nameKey {
        display: flex;
        align-items: center;
        min-width: 120px;
        svg {
          width: 22px;
          height: 22px;
          color: var(--color-text-alt-2);
        }
        p {
          font-size: var(--font-size-5);
          color: var(--color-text-alt-2);
          margin-left: 5px;
        }
      }
      .link {
        display: inline-block;
        max-width: 196px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:hover {
          color: var(--color-main);
          text-decoration: underline;
        }
      }
      h5 {
        display: flex;
        align-items: center;
        font-weight: var(--font-weight-semibold);
        & > :not(style) + :not(style) {
          margin-left: 4px;
        }
      }
    }
    .offerPrice {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
      padding: 10px 10px;
      background-color: rgba(237, 246, 245, 0.7);
      border-radius: var(--border-radius-medium);
      cursor: pointer;
      .link {
        color: #fff;
        background: var(--color-primary);
        margin-right: 8px;
        svg {
          width: 18px;
          height: 18px;
        }
      }
      .priceTotal {
        font-weight: var(--font-weight-bold);
      }
      .pricetext {
        font-size: var(--font-size-7);
      }
      .pricehint {
        display: inline-block;
        font-size: var(--font-size-7);
        font-weight: var(--font-weight-semibold);
        color: var(--color-text-alt-2);
        font-style: italic;
        margin-right: 4px;
      }
    }
  }
  .offerAction {
    padding: 8px 16px 16px;
  }
}
