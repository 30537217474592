.root {
  padding: 16px 16px;
}
.rating {
  display: flex;
  align-items: center;
  color: rgb(102, 60, 0);
  background-color: rgb(255, 244, 229) !important;
}
.score {
  display: inline-flex;
  align-items: center;
  gap: 3px;
  color: #ffa000;
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-semibold);
  margin-left: 4px;
  svg {
    width: 20px;
    height: 20px;
    fill: #f3a43d;
  }
}
.qualify {
  .text {
    display: flex;
    font-size: var(--font-size-6);
    color: var(--color-text-alt-2);
    font-weight: var(--font-weight-normal);
  }
}
