.profileLayout {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.layoutWrap {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  padding-top: 64px;
  @media only screen and (min-width: 1024px) {
    padding-left: 208px;
  }
  @media only screen and (max-width: 600px) {
    padding-top: 57px;
  }
}
.layoutContainer {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
}
.layoutContent {
  flex: 1 1 auto;
  height: 100%;
  overflow: auto;
}
