.title {
  margin-bottom: 16px;
  h3 {
    font-weight: var(--font-weight-bold);
  }
  p {
    color: var(--color-text-alt-2);
    font-weight: var(--font-weight-normal);
  }
}
.alert {
  padding: 16px;
  margin-bottom: 16px;
  border-radius: var(--border-radius-small);
  background-color: rgba(155, 248, 233, 0.45);
  color: #012921;
  & > :not(style) + :not(style) {
    margin-top: 10px;
  }
  .item {
    display: flex;
    align-items: center;
  }
  .text {
    flex: 1 1 auto;
    margin-left: 8px;
    font-size: var(--font-size-5);
  }
  strong {
    font-weight: var(--font-weight-bold);
  }
}
.tableWrap {
  border-radius: var(--border-radius-small);
  margin-bottom: 16px;
  overflow: hidden;
}
.table {
  height: auto;
  overflow: hidden;
  border-left: 1px solid rgb(225, 227, 234);
  border-right: 1px solid rgb(225, 227, 234);
}
.header {
  background-color: rgb(243, 244, 247);
  border-top: 1px solid rgb(225, 227, 234);
}
.total {
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-bold);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.subTitle {
  padding: 0 8px;
  margin-bottom: 4px;
  p {
    color: var(--color-primary);
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-semibold);
    line-height: 1.43;
  }
}
.titleEnd {
  padding: 0 8px;
  margin-top: 4px;
  p {
    color: var(--color-text-alt-2);
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-thin);
    line-height: 1.43;
  }
}
.steps {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  gap: 16px;
  background: var(--color-background-alt);
  padding: 16px;
  border-radius: var(--border-radius-small);
  .stepLabel {
    display: flex;
    align-items: center;
    border-radius: var(--border-radius-small);
    // padding: 8px 10px;
  }
  .label {
    // color: var(--color-text-alt-2);
    font-weight: var(--font-weight-normal);
    line-height: 16px;
  }
}
.content {
  text-align: center;
  background: var(--color-background-alt);
  border-radius: var(--border-radius-small);
  padding: 16px;
  .desc {
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-normal);
    line-height: 1.43;
    margin-bottom: 4px;
    &.stroke {
      font-size: var(--font-size-3);
      font-weight: var(--font-weight-bold);
    }
    .italic {
      font-style: italic;
    }
  }
  .qr {
    max-height: 200px;
    display: inline-block;
    user-select: none;
    margin-bottom: 10px;
  }
}
.footer {
  text-align: center;
  margin-top: 16px;
  font-size: var(--font-size-7);
  color: var(--color-text-helper);
  a {
    text-decoration: none;
    color: var(--color-status-info);
    font-weight: var(--font-weight-semibold);
    &:hover {
      text-decoration: underline;
    }
  }
}
.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0px;
  p {
    margin-left: -4px;
    color: var(--color-text-base);
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-semibold);
    text-decoration: underline;
    user-select: none;
    cursor: pointer;
  }
}
