.root {
  display: flex;
  align-items: flex-start;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.image {
  position: relative;
  flex-shrink: 0;
  width: 240px;
  height: 240px;
  overflow: hidden;
  border-right: 1px dashed var(--border-color-2);
  &.holder {
    background-color: var(--color-background-alt);
    img {
      width: 75%;
    }
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    user-select: none;
    margin: 0 auto;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    border-bottom: 1px dashed var(--border-color-2);
    &.holder {
      img {
        width: 50%;
      }
    }
  }
}
.body {
  // position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 240px;
  height: auto;
  width: 100%;
  @media screen and (max-width: 768px) {
    height: auto;
    width: 100%;
  }
}
.info {
  position: relative;
  flex: 1 1 auto;
  min-width: 0px;
  padding: 16px 16px 0;
  .time {
    display: inline-block;
    color: var(--color-text-alt-2);
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-7);
    font-style: italic;
    margin-bottom: 3px;
  }
  .title {
    // position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
  }
  .titleText {
    font-weight: var(--font-weight-bold);
    color: var(--color-text-primary);
    margin-right: 4px;
    &:hover {
      text-decoration: underline;
    }
  }
  .state {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    height: 20px;
    margin-left: 6px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 8px;
    border: 1px dashed var(--color-purple);
    background-color: inherit;
    font-size: 14px;
    font-weight: var(--font-weight-semibold);
    color: var(--color-purple);
    text-transform: capitalize;
    span {
      display: inline-block;
      margin-top: -1px;
    }
    @media screen and (max-width: 768px) {
      position: absolute;
      top: 16px;
      right: 8px;
    }
  }
  .badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 27px;
    padding: 0 10px;
    margin-left: 4px;
    background-color: #faecca;
    border-radius: var(--border-radius-small);
    box-shadow: inset 0px 0px 0px 1px var(--color-status-alert);
    font-weight: var(--font-weight-semibold);
    .badgeText {
      color: var(--color-status-alert);
    }
  }
  .validate {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    min-height: 27px;
    padding: 0 10px;
    margin-left: 4px;
    border-radius: var(--border-radius-small);
    color: #007965;
    background-color: #a3eee1;
    text-transform: capitalize;
  }
  .badgeOne {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 27px;
    padding: 0 10px;
    margin-left: 4px;
    background-color: #ffbe4b;
    border-radius: var(--border-radius-small);
    img {
      width: 38px;
      display: block;
      filter: brightness(0) invert(1);
      user-select: none;
    }
  }
  .nameWrap {
    width: 100%;
    margin-bottom: 4px;
    .name {
      color: var(--color-text-base);
      font-weight: var(--font-weight-semibold);
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .obsWrap {
    width: 100%;
    .obs {
      color: var(--color-status-info);
      font-weight: var(--font-weight-semibold);
      font-style: italic;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  .cancelTraveler {
    color: var(--color-status-error);
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-6);
    font-style: italic;
    margin-top: 4px;
  }
  .product {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 8px;
    margin-top: 8px;
    border-radius: var(--border-radius-small);
    border: 1px solid var(--border-color);
    &.several {
      cursor: pointer;
      h5 {
        margin-right: 8px;
        :hover {
          color: var(--color-main-800);
          text-decoration: underline;
        }
      }
    }
    .linkButton {
      color: #fff;
      background: var(--color-primary);
      svg {
        width: 18px;
        height: 18px;
      }
    }
    .link {
      display: inline-block;
      max-width: 196px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:hover {
        color: var(--color-main);
        text-decoration: underline;
      }
    }
    .productHint {
      color: var(--color-text-alt-2);
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-7);
      margin-left: 2px;
    }
    h5 {
      display: flex;
      align-items: flex-end;
      font-weight: var(--font-weight-semibold);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      & > :not(style) + :not(style) {
        margin-left: 4px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 16px 16px;
    .name {
      width: 100%;
    }
  }
}
.actions {
  padding: 0 16px 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px;
  .offerPrice {
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    background-color: rgba(237, 246, 245, 0.7);
    border-radius: var(--border-radius-medium);
    cursor: pointer;
    .link {
      color: #fff;
      background: var(--color-text-alt-2);
      margin-right: 8px;
      svg {
        width: 18px;
        height: 18px;
      }
    }
    .expressFee {
      font-weight: var(--font-weight-normal);
      font-size: var(--font-size-6);
      color: var(--color-text-alt-2);
      vertical-align: middle;
      margin-right: 6px;
      padding: 4px;
      background-color: #2b353b;
      border-radius: 5px;
    }
    .currency {
      font-weight: var(--font-weight-normal);
      font-size: var(--font-size-5);
      color: var(--color-text-alt-2);
      vertical-align: top;
      margin-right: 2px;
    }
    .priceTotal {
      font-weight: var(--font-weight-bold);
    }
    .pricetext {
      font-size: var(--font-size-7);
    }
    .priceHint {
      display: inline-block;
      font-size: var(--font-size-7);
      font-weight: var(--font-weight-semibold);
      color: var(--color-text-alt-2);
      font-style: italic;
      margin-right: 4px;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .shop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    min-width: 180px;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-medium);
    .shopTitle {
      font-weight: var(--font-weight-semibold);
      &:hover {
        color: var(--color-text-primary-darker);
        text-decoration: underline;
      }
    }
    .shopInfo {
      display: flex;
      align-items: center;
      line-height: 1;
      .link {
        color: #fff;
        background: var(--color-primary);
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
    &:hover {
      background-color: var(--color-background-alt);
    }
  }
  .inactiveWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .inactiveHint {
    font-size: var(--font-size-7);
    font-weight: var(--font-weight-semibold);
    color: var(--color-text-alt-2);
  }
  .inactive {
    display: flex;
    align-items: center;
    max-width: 140px;
    margin: 4px 0;
    color: var(--color-status-error);
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-6);
    svg {
      margin-right: 2px;
    }
  }
  .expire {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 4px;
    color: var(--color-status-alert);
    .expireText {
      margin-left: 4px;
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-7);
    }
  }
  .btns {
    display: flex;
    align-items: center;
    @media screen and (max-width: 768px) {
      width: 100%;
      a {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 16px 16px;
  }
}
.tools {
  position: absolute;
  top: 10px;
  right: 10px;
}
.toolsButton {
  @media screen and (max-width: 768px) {
    background-color: var(--color-light-gray);
    box-shadow: 0px 0px 4px var(--color-primary);
    &:hover {
      background-color: inherit;
      color: var(--color-primary);
    }
  }
}
.rating {
  display: flex;
  align-items: center;
  color: var(--color-text-alt-2);
  padding: 6px 10px;
  border-radius: var(--border-radius-small);
  border: 1px dashed var(--color-text-alt-2);
  .score {
    display: inline-flex;
    align-items: center;
    gap: 3px;
    color: #ffa000;
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-semibold);
    margin-left: 5px;
    svg {
      width: 18px;
      height: 18px;
      fill: #f3a43d;
    }
  }
}
.imageDelivery {
  :global(.MuiAvatar-root) {
    height: 240px;
    width: 240px;
    border-radius: 8px;
    margin: 0 auto;
    background-color: var(--color-background-alt);
    .holder {
      width: 80%;
    }
  }
}
.courier {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 26px;
  padding: 0 10px;
  margin-left: 10px;
  border-radius: var(--border-radius-small);
  box-shadow: inset 0px 0px 0px 1px #fdd835;
  color: #fbc02d;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-6);
}
.deliverySetup {
  display: flex;
  flex-direction: column;
  gap: 10px;
  p {
    color: var(--color-blue);
    text-align: end;
    font-size: var(--font-size-7);
    font-weight: bold;
  }
  button {
    background-color: var(--color-blue);
    color: #fff;
  }
  button:hover {
    background-color: var(--color-purple);
    color: #fff;
  }
}