.user {
  flex: 1 1 auto;
  display: inline-flex;
  align-items: center;
  .avatar {
    width: 38px;
    height: 38px;
  }
  .userInfo {
    flex: 1 1 auto;
    margin-left: 8px;
  }
  .name {
    max-width: 120px;
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-semibold);
  }
  .userScore {
    display: inline-flex;
    align-items: center;
    margin-top: 4px;
    color: #f3a43d;
    svg {
      width: 15px;
      height: 15px;
      fill: #f3a43d;
    }
    span {
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-7);
      color: var(--color-text-alt-2);
      margin-left: 4px;
    }
  }
}
.dealExtra {
  width: 100%;
  // padding: 16px 16px;
}
.list {
  display: flex;
  flex-direction: column;
  & > :not(style) + :not(style) {
    margin-top: 16px;
  }
}
.actions {
  display: flex;
  gap: 16px;
  margin-top: 16px;
}
.stats {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  .name {
    display: flex;
    align-items: center;
    min-width: 120px;
    color: var(--color-text-alt-2);
    p {
      font-size: var(--font-size-5);
      margin-left: 5px;
    }
  }
  .value {
    font-weight: var(--font-weight-semibold);
    text-transform: capitalize;
  }
}
