.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 25px;
  padding: 0 10px;
  border: 1px solid #fdd835;
  color: #fbc02d;
  border-radius: var(--border-radius-small);
  font-size: var(--font-size-6);
  font-weight: var(--font-weight-semibold);
  .text {
    font-size: inherit;
    font-weight: inherit;
  }
}
.clickable {
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  &:hover {
    background-color: #fff9eb;
  }
}
.background {
  background-color: #fff9eb;
  &:hover {
    background-color: #fbe8b9;
  }
}
