.auth-validate {
  position: relative;
  min-height: 100vh;
  transition: background-color 1000ms linear;
  &__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    img {
      width: 176px;
      display: block;
      user-select: none;
      animation: pulse 2s ease 0s infinite;
    }
  }
  @keyframes pulse {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    50% {
      opacity: 1;
      transform: scale(1.15);
    }
  }
}
