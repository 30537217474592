.header {
  display: inline-flex;
  color: #ff9800;
  .text {
    margin-left: 8px;
  }
}
.title {
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-4);
  margin-bottom: 6px;
}
.info {
  color: var(--color-text-alt-2);
}
.expiration {
  color: var(--color-text-alt-2);
  margin-top: 5px;
}
.solution {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: rgb(255, 244, 229);
  border-radius: var(--border-radius-medium);
  padding: 12px;
  margin-top: 16px;
  .text {
    color: #663c00;
    p {
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-5);
    }
  }
  .action {
    color: #663c00;
    flex-shrink: 0;
  }
  @media (min-width: 600px) {
    flex-direction: row;
    align-items: center;
  }
}
