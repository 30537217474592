.body {
  position: relative;
  flex-grow: 1;
  display: flex;
  overflow: hidden;
}
.main {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.chat {
  position: relative;
  flex-grow: 1;
  height: 100%;
  margin-bottom: 57px;
  // overflow: hidden;
  overflow-x: auto;
  &.subHeader {
    padding-top: 58px;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: rgba(99, 115, 129, 0.48);
      opacity: 0;
    }
  }
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    background-color: transparent;
    border-radius: 7px;
    opacity: 0;
    transition: background-color 1000ms linear;
    &::before {
      content: '';
      position: absolute;
      top: 2px;
      bottom: 2px;
      left: 2px;
      right: 2px;
      background-color: rgba(3, 39, 71, 0.48);
    }
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(99, 115, 129, 0.68);
  }
}
.content {
  padding: 24px;
}
.help {
  position: absolute;
  left: 0;
  top: 0;
  // bottom: 57px;
  // bottom: 0;
  width: 100%;
  // border-top: 1px solid var(--border-color-2);
  border-bottom: 1px solid var(--border-color-2);
  background-color: rgba(219, 227, 231, 0.8);
  backdrop-filter: blur(6px);
  .helpWrap {
    display: inline-flex;
    align-items: center;
    padding: 4px 16px;
    color: var(--color-text-base);
    svg {
      width: 18px;
      height: 18px;
    }
    p {
      color: inherit;
      font-weight: var(--font-weight-semibold);
      margin-left: 4px;
    }
  }
}
.divider {
  margin: 0px;
  flex-shrink: 0;
  border-width: 0px 0px thin;
  border-style: solid;
  border-color: var(--border-color-2);
}
