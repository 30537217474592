.panel {
  width: 100%;
  margin: 24px 0 0;
}
.expire {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 180px;
  background-color: var(--color-background-alt);
  border-radius: var(--border-radius-medium);
}
.alert {
  position: relative;
}
.dot {
  display: block;
  position: absolute;
  top: 10px;
  right: -14px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgb(255, 72, 66);
  box-shadow: 0 0 0 rgba(255, 72, 66, 0.4);
  animation: pulse-dot 2s ease-out;
  animation-iteration-count: infinite;
  animation-delay: 1.1s;
}
@-webkit-keyframes pulse-dot {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 72, 66, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 72, 66, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 72, 66, 0);
  }
}
@keyframes pulse-dot {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 72, 66, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 72, 66, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(255, 72, 66, 0);
    box-shadow: 0 0 0 10px rgba(255, 72, 66, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 72, 66, 0);
    box-shadow: 0 0 0 0 rgba(255, 72, 66, 0);
  }
}
.title {
  display: flex;
  align-items: center;
  h2 {
    margin-left: 8px;
  }
}

.updateOffersExpiration {
  color: var(--color-purple);
  padding: 10px;
  border: 1px solid var(--color-purple);
  border-radius: 5px;
  margin-bottom: 8px;
}