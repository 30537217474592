.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .traveler {
    display: flex;
    flex-direction: column;
    gap: 6px;
    .travelerCardExtra {
      color: var(--color-text-alt-2);
    }
  }
  .levels {
    padding: 0px;
    font-size: var(--font-size-7);
    li {
      padding: 2px 24px 2px 8px;
      div {
        margin: 0px;
        span {
          font-size: var(--font-size-7);
        }
      }
      .selected span {
        font-weight: var(--font-weight-bold);
      }
    }
    .check {
      min-width: 40px;
      color: rgba(0, 0, 0, 1);
      svg {
        width: 16px;
        color: var(--color-primary);
      }
      span {
        width: 100%;
      }
    }
    .icon {
      min-width: 40px;
      color: rgba(0, 0, 0, 1);
    }
    .noIcon {
      padding-left: 40px;
    }
  }
}
.avoidReviews {
  padding: 20px 10px;
  p {
    color: var(--color-text-alt-2);
    font-size: var(--font-size-5);
  }
}
.content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  .reviewsSubtitle {
    margin-left: -10px;
    color: var(--color-text-alt-2);
    font-size: var(--font-size-6);
  }
  .reviewCard {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: start;
    gap: 10px;
    .avatar {
      width: 36px;
      height: 36px;
    }
    .userInfo {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      gap: 4px;
      width: 100%;
      .firstCol {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 4px;
        .name {
            font-weight: var(--font-weight-semibold);
        }
        .opinion {
            padding-left: 6px;
            color: var(--color-text-alt-2);
            font-size: var(--font-size-7);
            font-weight: var(--font-weight-normal);
        }
        &.emptyOpinion {
            align-self: center;
        }
      }
      .secondCol {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        gap: 4px;
        .elapsedTime {
          font-size: var(--font-size-8);
          color: var(--color-text-alt);
        }
      }
    }
  }
}
