:root {
  .main {
    padding: 10px 20px;
    .head {
      font-size: var(--font-size-5);
      color: var(--color-text-alt-2);
      margin: 10px auto;
    }
    hr {
      border-bottom: 1px solid var(--color-text-alt);
      margin: 20px auto;
    }
    .dealDetails {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .deliveryDate {
        min-height: 44px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 0 10px;
        border-radius: var(--border-radius-small);
        border: 1px solid var(--color-dark-gray);
        color: var(--color-text-alt-2);
        .textHelper {
          color: var(--color-graphite);
          &::first-letter {
            text-transform: capitalize;
          }
        }
        .text {
          margin-top: 2px;
          color: var(--color-graphite);
          font-weight: var(--font-weight-semibold);
        }
      }
      .user {
        flex: 1 1 auto;
        display: inline-flex;
        align-items: center;
        .avatar {
          width: 38px;
          height: 38px;
        }
        .userInfo {
          flex: 1 1 auto;
          margin-left: 8px;
        }
        .name {
          max-width: 120px;
          font-size: var(--font-size-5);
          font-weight: var(--font-weight-semibold);
        }
        .userScore {
          display: inline-flex;
          align-items: center;
          margin-top: 4px;
          color: #f3a43d;
          svg {
            width: 15px;
            height: 15px;
            fill: #f3a43d;
          }
          span {
            font-weight: var(--font-weight-semibold);
            font-size: var(--font-size-7);
            color: var(--color-text-alt-2);
            margin-left: 4px;
          }
        }
      }
    }
    .radioOptions {
        margin-top: 20px;
        .option {
            margin-top: 10px;
            .label {
                display: flex;
                align-items: center;
                gap: 4px;
                svg {
                    color: var(--color-text-alt-2);
                }
                p.title {
                    font-weight: bold;
                    font-size: var(--font-size-6);
                    margin-bottom: 0px;
                }
                p.subtitle {
                    line-height: 1;
                    font-size: var(--font-size-7);
                    color: var(--color-text-alt);
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    span {
                        width: 50%;
                    }
                }
            }
        }    
    }
    .editLink {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        a {
            color: var(--color-primary);
            text-decoration: underline;
            font-weight: bold;
            font-size: var(--font-size-7);
            &.super {
              font-weight: 800;
              color: var(--color-blue);
              font-size: var(--font-size-6);
            }
        }
    }
    .actions {
        margin-top: 30px;
        display: grid;
        gap: 14px;
        p {
            color: var(--color-text-alt-2);
        }
    }
  }
}
