.progressBar {
  :global {
    .MuiLinearProgress-root {
      height: 8px;
      border-radius: 4px;
    }
    .MuiLinearProgress-bar2Buffer {
      background-color: rgba(130, 127, 251, 0.5) !important;
    }
  }
}
.hints {
  position: relative;
}
.num {
  width: auto !important;
  position: absolute;
  top: 10px;
  transform: translateX(-50%) translateY(-50%);
  font-size: 12px;
  color: #827ffb !important;
  & strong {
    color: var(--color-blue);
  }
}
.numHeader {
  width: auto !important;
  position: absolute;
  top: -16px;
  transform: translateX(-50%) translateY(-50%);
  font-size: 12px;
  color: var(--color-dark-gray);
}
