.root {
  --my: 24px;
  @media only screen and (max-width: 768px) {
    --my: 16px;
  }
}
.resume {
  padding: 16px var(--my) 12px;
  .wrap {
    display: flex;
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    .wrap {
      flex-direction: column;
    }
  }
}
.cover {
  &:global(.MuiAvatar-root) {
    height: 200px;
    width: 200px;
    background-color: var(--color-background-alt);
    color: rgb(80, 97, 118);
    border-radius: 6px;
  }
  @media only screen and (max-width: 768px) {
    &:global(.MuiAvatar-root) {
      width: 100%;
      img {
        object-fit: contain;
      }
    }
  }
}
.info {
  margin-left: 16px;
  .name {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .time {
    display: inline-block;
    color: var(--color-text-alt-2);
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-7);
    font-style: italic;
    margin-bottom: 4px;
  }
  @media only screen and (max-width: 768px) {
    & {
      margin: 10px 0 0;
    }
  }
}
.list {
  display: inline-flex;
  align-items: flex-start;
}
.obs {
  display: block;
  color: var(--color-status-info);
  font-weight: var(--font-weight-semibold);
  margin-top: 6px;
}
.link {
  display: inline-block;
  padding: 3px 8px;
  margin-top: 5px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-small);
  .text {
    font-size: var(--font-size-5);
  }
  &:hover {
    border-color: var(--color-main);
    .text {
      color: var(--color-main-800);
      text-decoration: underline;
    }
  }
}
.tag {
  display: inline-flex;
  padding: 3px 8px;
  margin-top: 5px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-small);
  .text {
    font-size: var(--font-size-5);
  }
  .smallText {
    font-size: var(--font-size-6);
  }
}
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px var(--my) 24px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: end;
  }
}
.detailPrice {
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  background-color: rgba(237, 246, 245, 0.7);
  border-radius: var(--border-radius-medium);
  .priceTotal {
    font-weight: var(--font-weight-bold);
  }
  .currency {
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-5);
    color: var(--color-text-alt-2);
    vertical-align: top;
    margin-right: 2px;
  }
  .priceHint {
    display: inline-block;
    font-size: var(--font-size-7);
    font-weight: var(--font-weight-semibold);
    color: var(--color-text-alt-2);
    font-style: italic;
    margin-right: 4px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.detailPriceExpress {
  min-width: 240px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  padding: 10px 10px;
  background-color: #333;
  color: var(--color-express-fee);
  border-radius: var(--border-radius-medium);
  .priceTotal {
    font-weight: var(--font-weight-bold);
  }
  .currency {
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-5);
    color: #fff;
    vertical-align: top;
    margin-right: 2px;
  }
  .priceHint {
    display: inline-block;
    font-size: var(--font-size-7);
    font-weight: var(--font-weight-semibold);
    color: var(--color-silver);
    font-style: italic;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.expire {
  margin-top: 6px;
  font-style: italic;
}
