.header {
  display: flex;
  align-items: center;
  padding: 16px;
  .headerContent {
    flex: 1 1 auto;
  }
  .headerTitle {
    display: block;
    font-size: var(--font-size-4);
    font-weight: var(--font-weight-semibold);
    line-height: 1.334;
  }
}
.divider {
  margin: 0px;
  flex-shrink: 0;
  background: rgba(34, 51, 84, 0.1);
  border: 0px;
  height: 1px;
}
.body {
  position: relative;
  z-index: 99;
  width: 100%;
  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    background: rgb(243, 244, 247);
    border-top: 1px solid rgba(34, 51, 84, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    background: rgba(20, 194, 165, 0.6);
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
    transition: color 0.2s ease 0s;
    border-radius: 4px;
  }
  &:hover,
  &:active,
  &:focus {
    &::-webkit-scrollbar-thumb {
      background: rgba(20, 194, 165, 1);
    }
  }
}
.table {
  height: auto;
  overflow: hidden;
}
.headerTable {
  background-color: rgb(243, 244, 247);
  // border-top: 1px solid rgb(225, 227, 234);
}
.bodyTable {
  tr:last-of-type td {
    border-width: 0px;
  }
}
.name {
  display: inline-flex;
  align-items: center;
  span {
    font-weight: var(--font-weight-semibold);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.link {
  display: inline-block;
  max-width: 176px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 4px;
  &:hover {
    color: var(--color-main-800);
    text-decoration: underline;
  }
}
.total {
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-bold);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
