.root {
  display: flex;
  align-items: center;
  padding: 16px;
  border-top: 1px solid var(--border-color-2);
  & > :not(style) + :not(style) {
    margin: 0 0 0 16px;
  }
  .remove {
    flex-shrink: 0;
    color: var(--color-text-alt-2);
  }
}
.accept {
  width: 100%;
}
