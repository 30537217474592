.root {
  padding: 16px;
}
.anchorActionSuggestionText {
  font-size: var(--font-size-6);
  margin-bottom: 0px;
  text-align: center;
  color: var(--color-primary);
  text-decoration: underline;
  cursor: pointer;
}