.menuButton {
  padding: 0px !important;
  margin-right: 6px !important;
}
.logoWrap {
  height: 100%;
  margin-bottom: -4px;
}
.logoImage {
  display: block;
  user-select: none;
}
.space {
  flex-grow: 1;
}
.avatarButton {
  padding: 3px 8px !important;
}
.avatarButtonActive {
  background-color: rgba(62, 134, 123, 0.15) !important;
  color: var(--color-primary) !important;
  .avatar {
    background-color: var(--color-primary) !important;
  }
}
.avatar {
  width: 32px !important;
  height: 32px !important;
  background-color: var(--color-blue-darker) !important;
  svg {
    font-size: 20px;
  }
}
.userName {
  margin-left: 6px;
}
.item {
  padding: 5px !important;
  &.adminBtn {
    svg {
      fill: #817ffb9f;
      color: #827ffb;
    }
  }
  svg {
    font-size: 20px;
    color: var(--color-blue-darker);
  }
  &.itemMobile {
    svg {
      font-size: 24px;
      stroke-width: 2px !important;
    }
  }
}
.bellButtonActive {
  background-color: rgba(62, 134, 123, 0.15) !important;
  svg {
    color: var(--color-primary);
  }
}
