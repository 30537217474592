.wrap {
  display: flex;
  align-items: flex-start;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
:global(.iconMessageCircleContained) {
  background-color: var(--color-primary);
  color: white;
}
:global(.iconMessageCircleContained:hover) {
  background-color: var(--color-primary-light);
  color: white;
}
.image {
  position: relative;
  flex-shrink: 0;
  width: 240px;
  height: 240px;
  overflow: hidden;
  border-right: 1px dashed var(--border-color-2);
  &.holder {
    background-color: var(--color-background-alt);
    img {
      width: 75%;
    }
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    user-select: none;
    margin: 0 auto;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    border-bottom: 1px dashed var(--border-color-2);
    &.holder {
      img {
        width: 50%;
      }
    }
  }
}
.body {
  max-width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 240px;
  min-width: 0px;
  @media screen and (max-width: 768px) {
    height: auto;
  }
}
.info {
  padding: 16px 16px 0;
  .time {
    display: inline-block;
    color: var(--color-text-alt-2);
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-7);
    font-style: italic;
    margin-bottom: 4px;
  }
  .titleWrap {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }
  .title {
    font-weight: var(--font-weight-bold);
    color: var(--color-text-primary);
  }
  .badgeOne {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    margin-left: 5px;
    background-color: #ffbe4b;
    border-radius: var(--border-radius-small);
    img {
      width: 36px;
      display: block;
      filter: brightness(0) invert(1);
      user-select: none;
    }
  }
  .badgeExpress {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 10px;
    margin-left: 5px;
    background-color: #333;
    color: var(--color-express-fee);
    border-radius: var(--border-radius-small);
  }
  .badgeCourier {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    margin-left: 5px;
    border-radius: var(--border-radius-small);
    box-shadow: inset 0px 0px 0px 1px #fdd835;
    color: #fbc02d;
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-6);
  }
  .badge {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    margin-left: 5px;
    background-color: #faecca;
    border-radius: var(--border-radius-small);
    .badgeText {
      color: var(--color-status-alert);
    }
  }
  .name {
    width: 90%;
    color: var(--color-text-base);
    margin-bottom: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    &:hover {
      color: var(--color-text-primary);
      text-decoration: underline;
    }
  }
  .stats {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    padding: 0 10px;
    margin-bottom: 5px;
    // background-color: var(--color-background-alt);
    border-radius: var(--border-radius-small);
    border: 1px solid var(--border-color);
    .attri {
      color: var(--color-text-base);
      font-size: var(--font-size-6);
      font-weight: var(--font-weight-normal);
      line-height: 1.4;
      text-transform: capitalize;
    }
  }
  .remark {
    color: var(--color-status-info);
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-6);
    font-style: italic;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 8px;
  }
  @media screen and (max-width: 768px) {
    padding: 16px 16px;
    .name {
      width: 100%;
    }
  }
}
.detailPriceExpress {
  min-width: 240px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  padding: 10px 10px;
  background-color: #333;
  color: var(--color-express-fee);
  border-radius: var(--border-radius-medium);
  .priceTotal {
    font-weight: var(--font-weight-bold);
  }
  .currency {
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-5);
    color: #fff;
    vertical-align: top;
    margin-right: 2px;
  }
  .priceHint {
    display: inline-block;
    font-size: var(--font-size-7);
    font-weight: var(--font-weight-semibold);
    color: var(--color-silver);
    font-style: italic;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.hintOffers {
    color: var(--color-text-alt-2);
    padding: 10px;
    border: 1px solid var(--color-gray);
    border-radius: 5px;
    .small {
      font-size: 0.9em;
      opacity: 0.85; 
    }
    &.warning {
      border: 1px solid var(--color-warning) !important;
      color: var(--color-warning);
    }
    &.danger {
      border: 1px solid var(--color-status-error-expired) !important;
      color: var(--color-status-error-expired);
    }
}
.actions {
  padding: 0 16px 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px;
  .detailPrice {
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    background-color: rgba(237, 246, 245, 0.7);
    border-radius: var(--border-radius-medium);
    .priceTotal {
      font-weight: var(--font-weight-bold);
    }
    .currency {
      font-weight: var(--font-weight-normal);
      font-size: var(--font-size-5);
      color: var(--color-text-alt-2);
      vertical-align: top;
      margin-right: 2px;
    }
    .priceHint {
      display: inline-block;
      font-size: var(--font-size-7);
      font-weight: var(--font-weight-semibold);
      color: var(--color-text-alt-2);
      font-style: italic;
      margin-right: 4px;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .expireStr {
    color: var(--color-text-alt-2);
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-7);
    font-style: italic;
    margin-top: 4px;
  }
  .shop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    min-width: 180px;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-medium);
    .shopTitle {
      font-weight: var(--font-weight-semibold);
      &:hover {
        color: var(--color-text-primary-darker);
        text-decoration: underline;
      }
    }
    .shopInfo {
      display: flex;
      align-items: center;
      line-height: 1;
      .link {
        color: #fff;
        background: var(--color-primary);
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
    &:hover {
      background-color: var(--color-background-alt);
    }
  }
  @media screen and (max-width: 768px) {
    padding: 16px 16px;
  }
}
.tools {
  position: absolute;
  top: 10px;
  right: 10px;
}
.userOffer {
  background-color: var(--color-background-base);
  // border: 1px solid var(--border-color);
  border-radius: var(--border-radius-small);
  .header {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px;
    border-bottom: 1px solid var(--border-color);
    .user {
      flex: 1 1 auto;
      display: inline-flex;
      align-items: center;
    }
    .avatar {
      width: 36px;
      height: 36px;
    }
    .userInfo {
      flex: 1 1 auto;
      margin-left: 5px;
    }
    .name {
      max-width: 120px;
      font-size: var(--font-size-5);
      font-weight: var(--font-weight-semibold);
    }
    .userScore {
      display: inline-flex;
      align-items: center;
      gap: 4px;
      margin-top: 2px;
      color: #f3a43d;
      svg {
        width: 17px;
        height: 17px;
        fill: #f3a43d;
      }
      span {
        font-weight: var(--font-weight-semibold);
        font-size: var(--font-size-6);
        color: var(--color-text-alt-2);
      }
    }
    button {
      padding: 4px;
    }
  }
  .content {
    display: block;
    flex-direction: column;
    padding: 10px;

    .offerInfo {
      margin-bottom: 0px;
      h5 {
        color: var(--color-text-base);
        font-weight: var(--font-weight-normal);
        line-height: 20px;
      }
    }
    .statsGroup {
      display: flex;
      flex-direction: column;
      & > :not(style) + :not(style) {
        margin-top: 8px;
      }
    }
    .stats {
      display: inline-flex;
      align-items: center;
      gap: 4px;
      svg {
        width: 20px;
        height: 20px;
        color: var(--color-text-alt-2);
      }
      p {
        color: var(--color-text-alt-2);
      }
      h6 {
        font-weight: var(--font-weight-semibold);
      }
    }
    .offerQty {
      font-size: 16px;
    }
    .offerPrice {
      display: flex;
      align-items: center;
      margin-top: 16px;
      padding: 10px 10px;
      background-color: rgba(237, 246, 245, 0.7);
      border-radius: var(--border-radius-medium);
      cursor: pointer;
      .priceInfo {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .link {
        color: #fff;
        background: var(--color-primary);
        svg {
          width: 18px;
          height: 18px;
        }
      }
      .currency {
        font-weight: var(--font-weight-normal);
        font-size: var(--font-size-5);
        color: var(--color-text-alt-2);
        vertical-align: top;
        margin-right: 2px;
      }
      .priceTotal {
        font-weight: var(--font-weight-bold);
      }
      .pricetext {
        font-size: var(--font-size-7);
        font-style: italic;
        color: var(--color-text-alt-2);
      }
    }
  }
  .offerAction {
    padding: 8px 10px 16px;
    .expire {
      display: inline-block;
      color: var(--color-text-alt-2);
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-7);
      font-style: italic;
      margin-top: 5px;
    }
  }
  .offerRejected {
    display: inherit;
    text-align: right !important;
    color: var(--color-status-error-expired);
    font-style: italic;
    font-size: var(--font-size-7);
    font-weight: var(--font-weight-semibold);
    margin-top: -10px;
  }
  .statusLabel {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    height: 18px;
    margin-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 8px;
    border: 1px dashed var(--color-purple);
    background-color: inherit;
    font-size: 12px;
    font-weight: var(--font-weight-semibold);
    color: var(--color-purple);
    text-transform: capitalize;
    span {
      display: inline-block;
      margin-top: -1px;
    }
  }
  .afterAction {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
  .expirationStatus {
    display: flex;
    justify-content: flex-end;
    margin-left: 8px;
  }
}
.reazon {
  display: flex;
  align-items: center;
  color: rgb(102, 60, 0);
  background-color: rgb(255, 244, 229);
  svg {
    color: #ff9800;
  }
  .text {
    padding-left: 12px;
    font-size: var(--font-size-5);
    line-height: 24px;
  }
}
.observationInfo {
  flex: 1 1 auto;
  .hint {
    color: var(--color-text-alt-2);
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-normal);
    margin-bottom: 6px;
  }
  .expInfo {
    color: var(--color-text-alt-2);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-5);
  }
  .solution {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: var(--color-background-alt-1);
    border-radius: var(--border-radius-medium);
    padding: 12px;
    margin-top: 16px;
    // color: #663c00;
    .text {
      flex-grow: 1;
      p {
        font-weight: var(--font-weight-semibold);
        font-size: var(--font-size-5);
      }
      .code {
        font-weight: var(--font-weight-normal);
        font-size: var(--font-size-5);
        margin-top: 6px;
      }
    }
    .action {
      flex-shrink: 0;
    }
    @media (min-width: 600px) {
      flex-direction: row;
      align-items: center;
    }
  }
}
