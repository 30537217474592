.root {
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 16px 0 24px;
}
.content {
  flex-grow: 1;
  margin: 0 24px 24px;
  h5 {
    // color: var(--color-text-alt-2);
    line-height: 1.25;
    margin-bottom: 2px;
  }
}
.actions {
  .wrap {
    width: 80%;
    margin: 0 auto;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}
