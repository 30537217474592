.root {
  display: flex;
  align-items: center;
  min-height: 56px;
  border-top: 1px solid var(--border-color);
  padding: 0 16px;
  &.error {
    color: rgb(97, 26, 21);
    background-color: rgb(253, 236, 234);
    .icon {
      color: #f44336;
    }
  }
  &.warning {
    color: rgb(102, 60, 0);
    background-color: rgb(255, 244, 229);
    .icon {
      color: #ff9800;
    }
  }
  &.info {
    color: rgb(13, 60, 97);
    background-color: rgb(232, 244, 253);
    .icon {
      color: #2196f3;
    }
  }
  &.success {
    color: rgb(30, 70, 32);
    background-color: rgb(237, 247, 237);
    .icon {
      color: #4caf50;
    }
  }
  &.express {
    color: var(--color-express-fee);
    background-color: #333;
    .icon {
      color: var(--color-express-fee);
    }
  }
  @media screen and (max-width: 768px) {
    padding: 8px 16px;
    flex-wrap: wrap;
    .action {
      width: 100%;
    }
  }
}
.content {
  display: flex;
}
.icon {
  display: flex;
  opacity: 0.9;
  padding: 8px 0;
  font-size: 22px;
  margin-right: 12px;
}
.text {
  padding: 8px 0;
  line-height: 24px;
}
.action {
  flex-shrink: 0;
  margin-left: auto;
  padding: 8px 0;
  button {
    width: inherit;
  }
}
