.root {
  display: flex;
  flex-direction: row;
  padding: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 16px;
  }
}
.expandButton {
  flex-shrink: 0;
}
.body {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin: 0 16px 0 0;
  @media (max-width: 768px) {
    margin: 0 0 24px;
  }
}
.right {
  display: flex;
  flex-direction: column;
  width: 160px;
  .top {
    flex: 1 1 auto;
    padding-bottom: 16px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
}
.price {
  text-align: end;
  h4 {
    font-weight: var(--font-weight-bold);
  }
}
.actions {
  display: flex;
  flex-direction: column;
  & > :not(style) + :not(style) {
    margin-top: 16px;
  }
}
.header {
  position: relative;
  display: flex;
  margin-bottom: 10px;
  .headerTitle {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
  }
  .id {
    font-weight: var(--font-weight-bold);
  }
}
.dot {
  display: block;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: rgb(255, 72, 66);
  box-shadow: 0 0 0 rgba(255, 72, 66, 0.4);
  animation: pulse-dot 2s ease-out;
  animation-iteration-count: infinite;
  animation-delay: 1.1s;
}
.statusBadge {
  background: transparent;
  border-radius: var(--border-radius-large);
  border: 1px dashed var(--color-primary);
  padding: 1px 7px;
  color: var(--color-primary);
  line-height: 20px;
  font-size: var(--font-size-6);
  font-weight: var(--font-weight-semibold);
  margin-left: 10px;
  &.RED_LABEL {
    border-color: var(--color-red);
    color: var(--color-red);
  }
  &.extra {
    color: var(--color-blue);
    opacity: 0.75;
    border: none;
    font-size: var(--font-size-7);
    margin-left: 4px;
    padding: 2px 4px;
  }
}
.shipInfo {
  width: 100%;
  .title {
    margin-bottom: 5px;
    color: var(--color-text-alt-2);
  }
  .content {
    display: flex;
    align-items: center;
    color: var(--color-text-darker);
    svg {
      flex-shrink: 0;
    }
  }
  .text {
    margin-left: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 320px;
  }
  @media (max-width: 768px) {
    .content {
      align-items: flex-start;
    }
  }
}
@-webkit-keyframes pulse-dot {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 72, 66, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 72, 66, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 72, 66, 0);
  }
}
@keyframes pulse-dot {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 72, 66, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 72, 66, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(255, 72, 66, 0);
    box-shadow: 0 0 0 10px rgba(255, 72, 66, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 72, 66, 0);
    box-shadow: 0 0 0 0 rgba(255, 72, 66, 0);
  }
}
