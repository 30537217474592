.root {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.btn {
  &:global(.MuiButton-root) {
    opacity: 0.45;
    &:hover {
      opacity: 1;
    }
  }
}
