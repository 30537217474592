.root {
  color: white;
  border-radius: var(--border-radius-small);
  box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;
}
.wrap {
  display: flex;
  justify-content: space-between;
  padding: 16px 16px;
  &.error {
    background: var(--color-status-error);
  }
  &.info {
    background: var(--color-status-info);
  }
  &.success {
    background: var(--color-status-success);
  }
  &.warning {
    background: var(--color-status-alert);
  }
  @media only screen and (max-width: 768px) {
    & {
      flex-direction: column;
    }
  }
}
.content {
  display: flex;
  align-items: center;
  .text {
    color: white;
    font-size: var(--font-size-5);
    line-height: 24px;
    margin-left: 16px;
  }
  @media only screen and (max-width: 768px) {
    & {
      margin-bottom: 8px;
    }
  }
}
.actions {
  display: flex;
  .btn {
    color: white;
    background: transparent;
    border-color: rgba(255, 255, 255, 0.5);
  }
  @media only screen and (max-width: 768px) {
    & {
      justify-content: flex-end;
    }
  }
}
