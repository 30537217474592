.header {
  display: flex;
  align-items: center;
  padding: 16px;
  .headerContent {
    flex: 1 1 auto;
  }
  .headerTitle {
    display: block;
    font-size: var(--font-size-4);
    font-weight: var(--font-weight-semibold);
    line-height: 1.334;
  }
}
.divider {
  margin: 0px;
  flex-shrink: 0;
  background: rgba(34, 51, 84, 0.1);
  border: 0px;
  height: 1px;
}
.body {
  padding: 24px;
  @media screen and (max-width: 768px) {
    padding: 16px;
  }
}
.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px;
  background: var(--color-background-alt);
  @media screen and (max-width: 768px) {
    padding: 16px;
  }
}
.addOrder {
  &:global(.MuiButton-root) {
    border-style: dashed;
    padding-top: 8px;
    padding-bottom: 8px;
    &:hover {
      border-style: dashed;
    }
  }
}
.removeOrder {
  :global(.MuiIconButton-root) {
    color: var(--color-red);
  }
}
.priceItem {
  display: flex;
  align-items: flex-end;
  h6 {
    font-size: var(--font-size-4);
    font-weight: var(--font-weight-semibold);
    margin-right: 4px;
  }
  span {
    color: var(--color-text-alt-2);
    font-size: var(--font-size-7);
    font-style: italic;
  }
}
