.root {
}
.body {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 16px 24px;
  p {
    font-size: var(--font-size-5);
    margin: 2px 0;
  }
}
.footer {
  text-align: right;
  padding: 16px 24px;
  & > :not(style) + :not(style) {
    margin-left: 16px;
  }
}
.header {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;
  h4 {
    font-weight: var(--font-weight-bold);
  }
}
.divider {
  margin: 0px;
  flex-shrink: 0;
  border-width: 1px thin 0px 0px;
  border-style: solid;
  border-color: #ccc;
  height: auto;
  align-self: stretch;
}
.statusBadge {
  background: var(--color-blue);
  border-radius: var(--border-radius-small);
  padding: 4px 8px;
  color: #fff;
  font-size: 15px;
  &.extra {
    display: block;
    margin-top: 10px;
    background: transparent;
    border-radius: var(--border-radius-large);
    border: 1px dashed var(--color-blue);
    padding: 4px 10px;
    color: var(--color-blue)
  }
}
.info {
  & > :not(style) + :not(style) {
    margin: 4px 0 0 0;
  }
  p {
    color: var(--color-text-alt-2);
  }
  strong {
    color: var(--color-text-base);
  }
}
.infoBox {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  background-color: var(--color-background-alt);
  border-radius: var(--border-radius-medium);
  padding: 10px 10px;
  .icon {
    flex-shrink: 0;
  }
  .content {
    flex-grow: 1;
    & > :not(style) + :not(style) {
      margin: 5px 0 0 0;
    }
    p {
      color: var(--color-text-alt-2);
    }
    strong {
      color: var(--color-text-base);
      font-weight: var(--font-weight-semibold);
    }
  }
}
.tracing {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--color-text-alt-2);
  margin-top: 16px;
  .text {
    flex-grow: 1;
  }
  .number {
    color: var(--color-text-base);
    font-weight: var(--font-weight-semibold);
  }
}
.section {
  margin: 16px 0;
}
.head {
  color: var(--color-text-alt-2);
  font-weight: var(--font-weight-semibold);
}
.condition {
  display: inline-block;
  text-transform: capitalize;
  color: var(--color-text-darker);
}
.subTitle {
  margin-bottom: 4px;
  h5 {
    font-weight: var(--font-weight-semibold);
    color: var(--color-text-darker);
  }
}
.direction {
  display: inline-flex;
  align-items: center;
  gap: 2px;
  text-transform: lowercase;
  span::first-letter {
    display: inline-block;
    text-transform: capitalize;
  }
}
.addresForm {
  margin-top: 6px;
}
.confirmHelp {
  display: flex;
  align-items: center;
  color: var(--color-primary);
  background-color: rgba(44, 236, 214, 0.15);
  padding: 6px 10px;
  margin-top: 6px;
  border-radius: var(--border-radius-small);
  h6 {
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-6);
    margin-left: 8px;
  }
}
.dividerText {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.05;
  padding: 5px 16px;
  user-select: none;
  font-weight: var(--font-weight-semibold);
  color: var(--color-text-alt);
}
.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}
