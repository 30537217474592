.user-profile {
  .stack-root {
    display: flex;
    flex-direction: column;
    & > :not(style) + :not(style) {
      margin: 24px 0px 0px;
    }
  }
  .card-img {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 48px;
    margin-bottom: 48px;
    .avatar-dashed {
      width: 144px;
      height: 144px;
      margin: auto;
      border-radius: 50%;
      padding: 8px;
      border: 1px dashed rgba(145, 158, 171, 0.32);
    }
    .avatar-wrap {
      z-index: 0;
      width: 100%;
      height: 100%;
      outline: none;
      display: flex;
      overflow: hidden;
      border-radius: 50%;
      position: relative;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
        cursor: auto;
        .avatar-upload {
          cursor: pointer;
        }
      }
      .placeholder {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 100%;
        transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        opacity: 0;
        color: rgb(255, 255, 255);
        background-color: rgb(22, 28, 36);
        svg {
          width: 24px;
          height: 24px;
          margin-bottom: 8px;
        }
      }
      &:hover {
        .placeholder {
          opacity: 0.7;
        }
      }
    }
    .btn-action {
      margin-top: 32px;
    }
    .sub-text {
      width: 60%;
      text-align: center;
      margin: 16px auto 32px;
      font-size: var(--font-size-6);
      color: rgb(99, 115, 129);
    }
    @media only screen and (max-width: 768px) {
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }
  .card-form {
    display: block;
    text-align: center;
    .form-wrap {
      padding: 24px;
    }
    .form-row {
      margin-bottom: 24px;
      label {
        display: block;
        margin-bottom: 8px;
        // font-weight: var(--font-weight-semibold);
      }
      &:last-child {
        margin-bottom: 0px;
      }
      &.wsp-row {
        .MuiTextField-root {
          & label {
            color: var(--color-status-alert);
          }
          & label.Mui-focused {
            color: var(--color-status-alert);
          }
          & .MuiOutlinedInput-root {
            & fieldset {
              border-color: var(--color-status-alert);
            }
            &.Mui-focused fieldset {
              border-color: var(--color-status-alert);
            }
          }
        }
      }
    }
    .form-submit {
      display: flex;
      justify-content: flex-end;
      padding: 16px 24px;
      background-color: var(--color-background-alt);
      border-top: 1px solid rgba(135, 153, 160, 0.25);
    }
  }
  .footer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 24px;
    span {
      display: block;
      margin-bottom: 8px;
    }
    a {
      color: var(--color-text-primary-darker);
      font-weight: 700;
      &:hover {
        color: var(--color-text-primary);
      }
    }
  }
}
