.root {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &.error {
    color: var(--color-status-error);
  }
  &.error_expired {
    color: var(--color-status-error-expired);
  }
  &.warning {
    color: var(--color-status-alert);
  }
  &.default {
    color: var(--color-text-alt-2);
  }
}
.text {
  margin-left: 4px;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-7);
}
