.root {
  flex-shrink: 0;
  min-height: 64px;
  display: flex;
  align-items: center;
  padding: 0px 24px;
  @media only screen and (min-width: 769px) {
    min-height: 92px;
  }
}
.profile {
  display: flex;
  align-items: center;
}
.avatar {
  position: relative;
}
.info {
  margin-left: 16px;
  h6 {
    font-weight: var(--font-weight-semibold);
    line-height: 1.57143;
  }
  p {
    color: var(--color-text-alt-2);
    font-weight: var(--font-weight-thin);
    line-height: 1.57143;
  }
}
.space {
  flex-grow: 1;
}
.actions {
}
