.root {
  margin: 16px 16px 24px;
}
.block {
  background: var(--color-background-base);
  border-radius: var(--border-radius-small);
  box-shadow: var(--shadow-elevation-1);
  padding: 10px 10px;
}
.wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.content {
  flex-grow: 1;
  .title {
    margin-top: 2px;
  }
  .subtitle {
    color: var(--color-text-alt-2);
    font-size: var(--font-size-7);
  }
}
.info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
}
.zipcode {
  h6 {
    color: var(--color-text-alt-2);
    font-size: var(--font-size-6);
    span {
      color: var(--color-text-base);
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-6);
    }
  }
}
.noTrip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  h5 {
    font-weight: var(--font-weight-semibold);
    color: var(--color-status-error);
  }
}
.tripLoading {
  border-radius: var(--border-radius-small);
}
.dialogBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  min-height: 240px;
}
.tripItem {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  .name {
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-5);
    margin-bottom: 10px;
  }
  .element {
    display: flex;
    align-items: flex-end;
    gap: 4px;
    color: var(--color-text-alt-2);
    svg {
      width: 19px;
      height: 19px;
      stroke-width: 1.75px;
    }
  }
  .label {
    color: var(--color-text-base);
  }
}
.noTripList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  h5 {
    font-weight: var(--font-weight-semibold);
    color: var(--color-status-error);
    text-align: center;
    margin-bottom: 16px;
  }
  a {
    max-width: 180px;
  }
}
.radioLoading {
  box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
  min-width: 8rem;
  min-height: 3rem;
  border-radius: var(--border-radius-medium);
  border: 2px solid var(--border-color);
}
