.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .header {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    background: var(--color-background-alt);
    border-bottom: 1px solid rgba(145, 158, 171, 0.24);
    z-index: 1100;
    .headerIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      margin-bottom: -4px;
      cursor: pointer;
    }
    .headerTitle {
      flex: 1 1 auto;
    }
    .headerPrice {
      display: flex;
      align-items: center;
      .total {
        color: var(--color-text-primary);
        font-size: var(--font-size-4);
        font-weight: var(--font-weight-bold);
        &.negative {
          color: var(--color-status-error);
        }
      }
      .totalOld {
        color: var(--color-text-alt);
        text-decoration: line-through;
        opacity: 1;
      }
      .amountDivider {
        margin: 0 8px;
      }
    }
    @media (min-width: 768px) {
      .headerIcon {
        display: none;
      }
    }
  }
  .container {
    flex-grow: 1;
    height: auto;
    overflow-x: auto;
  }
  .stepper {
    padding: 24px 0px 16px;
  }
  .stepLabel {
    display: flex;
    :global {
      .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
        margin-top: 8px;
      }
    }
  }
  .body {
    flex-grow: 1;
    height: auto;
    min-height: 288px;
    padding: 16px 24px;
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px 24px;
    // background: var(--color-background-alt);
    border-top: 1px solid rgba(145, 158, 171, 0.24);
    & > :not(style) + :not(style) {
      margin-left: 10px;
    }
    button {
      padding: 10px 20px;
    }
    .primary {
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    .header {
      border-bottom: none;
      box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px,
        rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;
    }
    .body {
      min-height: auto;
      padding: 16px;
    }
    .actions {
      background: var(--color-background-alt);
      border-top: 1px solid rgba(145, 158, 171, 0.24);
      box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px,
        rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;
      .primary {
        width: 100%;
      }
    }
  }
}

.sectionValidate {
  text-align: center;
  &.success {
    .icon {
      color: #52c41a;
    }
  }
  &.error {
    .icon {
      color: #faad14;
    }
  }
  .icon {
    margin: 16px 0;
    svg {
      font-size: 90px;
    }
  }
  p {
    color: var(--color-text-alt-2);
    font-weight: var(--font-weight-normal);
  }
}
