.root {
  text-align: right;
}
.btn {
  &:global(.MuiButton-root) {
    opacity: 0.45;
    &:hover {
      opacity: 1;
    }
  }
}
