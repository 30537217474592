.root {
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  z-index: 999;
  @media only screen and (min-width: 1024px) {
    left: 208px;
  }
  @media only screen and (max-width: 600px) {
    top: 57px;
  }
}
.paper {
  width: 100%;
  border-bottom: var(--border-radius-small);
  box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;
}
.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px;
  &.error {
    color: rgb(97, 26, 21);
    background-color: rgb(253, 236, 234);
    .icon {
      color: #f44336;
    }
  }
  &.info {
    color: rgb(13, 60, 97);
    background-color: rgb(232, 244, 253);
    .icon {
      color: #2196f3;
    }
  }
  &.success {
    background-color: rgb(237, 247, 237);
    color: rgb(30, 70, 32);
    .icon {
      color: #4caf50;
    }
  }
  &.warning {
    color: rgb(102, 60, 0);
    background-color: rgb(255, 244, 229);
    .icon {
      color: #ff9800;
    }
  }
  @media only screen and (max-width: 768px) {
    & {
      flex-direction: column;
    }
  }
}
.content {
  display: flex;
  align-items: center;
  .icon {
    display: inline-flex;
  }
  .text {
    color: inherit;
    font-size: var(--font-size-5);
    line-height: 24px;
    padding-left: 16px;
  }
  @media only screen and (max-width: 768px) {
    & {
      margin-bottom: 8px;
    }
  }
}
