.room {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 65px);
  @media only screen and (max-width: 768px) {
    height: calc(100vh - 65px);
  }
  @media only screen and (max-width: 600px) {
    height: calc(100vh - 57px);
  }
}
.body {
  flex-grow: 1;
  display: flex;
  overflow: hidden;
  .main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  .detail {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 320px;
    border-left: 1px solid rgba(145, 158, 171, 0.24);
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}
.chat {
  position: relative;
  flex-grow: 1;
  height: 100%;
  // overflow: hidden;
  overflow-x: auto;
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: rgba(99, 115, 129, 0.48);
      opacity: 0;
    }
  }
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    background-color: transparent;
    border-radius: 7px;
    opacity: 0;
    transition: background-color 1000ms linear;
    &::before {
      content: '';
      position: absolute;
      top: 2px;
      bottom: 2px;
      left: 2px;
      right: 2px;
      background-color: rgba(3, 39, 71, 0.48);
    }
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(99, 115, 129, 0.68);
  }
  .content {
    padding: 24px;
  }
}
.footer {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 16px;
  min-height: 56px;
}
.divider {
  margin: 0px;
  flex-shrink: 0;
  border-width: 0px 0px thin;
  border-style: solid;
  border-color: rgba(145, 158, 171, 0.24);
}
