.stack {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.noTrip {
  background-color: var(--color-background-alt);
  border-radius: var(--border-radius-small);
  text-align: center;
  padding: 24px;
  overflow: hidden;
  h5 {
    max-width: 640px;
    margin: 16px auto 24px;
    color: var(--color-text-alt-2);
    line-height: 20px;
  }
  img {
    width: 128px;
    height: 128px;
    opacity: 0.75;
    filter: grayscale(1);
  }
}
