.root {
  margin: 0 16px;
  padding: 24px;
  background: var(--color-background-alt-1);
  border: 1px solid var(--border-color-2);
  border-radius: var(--border-radius-medium);
}
.wrap {
  & > :not(style) + :not(style) {
    margin-top: 12px;
  }
  p {
    font-size: var(--font-size-5);
    line-height: 1.45;
  }
}
