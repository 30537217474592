.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
}
.desc {
  font-size: var(--font-size-5);
  padding: 24px 0;
}
.actionTitle {
  margin-bottom: 16px;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-5);
}
