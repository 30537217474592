.root {
  padding: 8px 16px 16px;
  // min-height: 240px;
  :global(.MuiTableContainer-root) {
    border-radius: var(--border-radius-medium);
    border: 1px solid rgb(225, 227, 234);
  }
  :global(.MuiTableCell-root) {
    padding: 10px 16px;
  }
}
.table {
  height: auto;
  overflow: hidden;
  .body {
    tr:last-child {
      td {
        border-bottom: 0px;
      }
    }
  }
}
.header {
  background-color: rgb(243, 244, 247);
}
.total {
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-semibold);
}
.totalPrice {
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-semibold);
  color: rgb(255, 72, 66);
}
.offerRejected {
  display: inherit;
  text-align: right;
  color: var(--color-status-error-expired);
  font-style: italic;
  font-size: var(--font-size-7);
  font-weight: var(--font-weight-semibold);
  margin-top: 8px;
}
.asterisk {
    display: flex;
    align-items: center;
    padding: 0px 10px;
    p {
        font-size: var(--font-size-7);
        color: var(--color-text-alt-2);
    }
}
