.table {
  height: auto;
  overflow: hidden;
}
.header {
  background-color: rgb(243, 244, 247);
  border-top: 1px solid rgb(225, 227, 234);
}
.badge {
  p:first-letter {
    text-transform: uppercase;
  }
}
.total {
  font-size: var(--font-size-6);
  font-weight: var(--font-weight-semibold);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
