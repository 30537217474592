:root {
  // Extra small devices (less than 768px)
  // No media query since this is the default
  --break-xs: 0px;
  // Medium devices (tablets, 768px and up)
  --break-sm: 768px;
  // Large devices (desktops, 1024 and up)
  --break-md: 1024px;
  --break-lg: 1200px;
  // Extra large devices (large desktops, 1440px and up)
  --break-xl: 1440px;
  --break-xxl: 1600px;

  /*
  * 2. Background vars
  */

  --color-background-body: #fff;
  --color-background-base: #fff;
  --color-background-primary: #00594f;
  --color-background-alt: #f2f6f7;
  --color-background-alt-1: #f9fafc;
  --color-background-alt-2: #dbe0e8;
  --color-background-alt-3: rgba(237, 246, 245, 0.7);

  /*
  * 3. Text vars
  */

  --color-text-base: #2b353b;
  --color-text-alt: #a7b7be;
  --color-text-alt-2: #637381;
  --color-text-alt-3: #506176;
  --color-text-helper: #919eab;
  --color-text-darker: #14121f;
  --color-text-primary: #00594f;
  --color-text-primary-darker: #002f27;
  --color-text-white: #f7f7f7;

  --font-family: 'Lato', Helvetica, Arial, sans-serif;
  // --font-family: 'Be Vietnam', sans-serif;

  --font-size-0: 45px;
  --font-size-1: 36px;
  --font-size-2: 29px;
  --font-size-3: 23px;
  --font-size-4: 18px;
  --font-size-5: 16px;
  --font-size-6: 14px;
  --font-size-7: 12px;
  --font-size-8: 10px;
  --font-size-base: 14px;

  --font-weight-bold: 900;
  --font-weight-thin: 300;
  --font-weight-normal: 400;
  --font-weight-semibold: 700;

  /*
  * 4. Colors vars
  */

  --color-main: #3cdbc0;
  --color-main-800: #14c2a5;
  --color-primary: #00594f;
  --color-primary-darker: #002f27;
  --color-primary-light: #26c0ae;
  --color-accent: #779bbc;
  --color-accent-darker: #5b7086;

  // --color-red: #f44336;
  --color-red: #f77;
  --color-blue-darker: #14121f;
  --color-blue-light: #00c4f5;
  --color-blue: #505dfd;
  --color-graphite: #2b353b;
  --color-dark-gray: #627884;
  --color-gray: #a7b7be;
  --color-dark: #849ca8;
  --color-light-yellow: #fcf9df;
  --color-yellow: #e7c144;
  --color-light-gray: #ecf1f4;
  --color-silver: #d9e3e8;
  --color-green: #47c27a;
  --color-orange: #f3722e;
  --color-purple: #3f51b5;
  --color-white: #fff;

  --color-twitter: #56a8e2;
  --color-facebook: #4460a2;

  --color-status-error: #ff4842;
  --color-status-error-expired: #853a3a;
  --color-status-success: #00ab55;
  --color-status-alert: #ffa000;
  --color-status-info: #2196f3;

  --color-warning: #bf7900;

  --color-express-fee: #efb537;
  --color-match-fee: #efb537;

  /*
  * 5. Shadows vars
  */

  --shadow-elevation-default: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;

  --shadow-elevation-1: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  --shadow-elevation-2: 0 0 0 1px rgba(63, 63, 68, 0.1), 0 2px 5px 0 rgba(63, 63, 68, 0.1);
  --shadow-elevation-3: 0 0 0 1px rgba(63, 63, 68, 0.1), 0px 3px 5px 0 rgba(0, 0, 0, 0.2);
  --shadow-elevation-4: 0 12px 32px rgba(0, 0, 0, 0.2), 0 0 8px rgba(0, 0, 0, 0.05);

  /*
  * 6. Border vars
  */

  --border-radius-large: 12px;
  --border-radius-medium: 8px;
  --border-radius-none: 0;
  --border-radius-rounded: 9000px;
  --border-radius-small: 4px;

  --border-color: rgba(135, 153, 160, 0.25);
  --border-color-2: rgba(145, 158, 171, 0.24);

  @media screen and (max-width: 768px) {
    --font-size-0: 36px;
    --font-size-1: 29px;
    --font-size-2: 23px;
    --font-size-3: 20px;
    // --font-size-4: 16px;
    // --font-size-5: 14px;
    // --font-size-6: 12px;
    // --font-size-7: 10px;
    // --font-size-base: 13px;
  }
}
