.root {
  margin: 0 16px;
  padding: 24px;
  background: var(--color-background-alt-1);
  border: 1px solid var(--border-color-2);
  border-radius: var(--border-radius-medium);
}
.wrap {
  & > :not(style) + :not(style) {
    margin-top: 12px;
  }
  p {
    font-size: var(--font-size-5);
    line-height: 1.45;
  }
  span.advice {
    display: inline-block;
    background-color: var(--color-red);
    font-weight: var(--font-weight-bold);
    color: white;
    border-radius: 8px;
  }
  ul {
    font-size: var(--font-size-5);
  }
  ul > li {
    line-height: 1.1;
    margin-top: 6px;
    padding-inline-start: 10px;
  }
  ul > li a {
    display: inline-flex;
    align-items: center;
    gap: 3px;
    color: var(--color-facebook);
    text-decoration: underline;
    font-weight: var(--font-weight-bold);
  }
}
