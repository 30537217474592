.title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem !important;
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }
}
.closeButton {
  position: absolute !important;
  top: 16px !important;
  right: 16px !important;
  color: var(--color-dark-gray) !important;
  svg {
    width: 22px;
    height: 22px;
  }
}
.content {
  // min-width: 256px;
  min-height: 240px;
}
