.searchTopbar {
  position: fixed;
  top: 57px;
  left: 0;
  z-index: 99;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid var(--border-color-2);
  .wrap {
    padding: 8px 16px;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .title {
    font-weight: var(--font-weight-semibold);
    margin-right: 16px;
  }
  @media (min-width: 600px) {
    top: 65px;
  }
  @media only screen and (min-width: 1024px) {
    width: calc(100% - 194px);
    padding-left: 194px;
    .flex {
      justify-content: flex-start;
    }
  }
}
