.title {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  line-height: 1.5;
  margin: 0px;
  padding: 32px 32px 24px;
  .icon {
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    // fill: currentcolor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 32px;
  }
  .success {
    color: var(--color-status-success);
  }
  .error {
    color: var(--color-status-error);
  }
  .info {
    color: var(--color-status-info);
  }
  .warning {
    color: var(--color-status-alert);
  }
  .text {
    line-height: 1.5;
    font-size: var(--font-size-3);
    font-weight: var(--font-weight-semibold);
    margin: 0px 0px 0px 16px;
    color: inherit;
  }
}
.title + .content {
  &:global(.MuiDialogContent-root) {
    padding-top: 0px;
  }
}
.content {
  &:global(.MuiDialogContent-root) {
    padding: 8px 32px;
  }
  .text {
    margin: 0px;
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-5);
    line-height: 1.5;
    color: var(--color-text-alt-2);
  }
}
.actions {
  &:global(.MuiDialogActions-root) {
    padding: 24px 32px 32px;
  }
}
